import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class DateFormatterService {

  constructor() {}

  slotDateToDto(slot: Date): string {
    return `${slot.getFullYear()}-${slot.getMonth() + 1}-${slot.getDate()}`
      + ` ${slot.getHours()}:${this.zeroPrefix(slot.getMinutes())}:00`;
  }

  toDayDto(day: Date): string {
    return `${day.getFullYear()}-${day.getMonth() + 1}-${day.getDate()}`;
  }

  toZeroPrefixedDay(day: Date): string {
    return `${this.toZeroPrefixedMonth(day)}-${this.zeroPrefix(day.getDate())}`;
  }

  toZeroPrefixedMonth(day: Date): string {
    return `${day.getFullYear()}-${this.zeroPrefix(day.getMonth() + 1)}`;
  }

  toDayHoursMinutesDto(datetime: Date): string {
    return `${datetime.getFullYear()}-${datetime.getMonth() + 1}-${datetime.getDate()}`
      + ` ${this.zeroPrefix(datetime.getHours())}:${this.zeroPrefix(datetime.getMinutes())}`;
  }

  hourDtoToSlot(hour: string, slotDay: Date): Date {
    const time: string[] = hour.split(':');
    const slot: Date = new Date(slotDay);
    slot.setHours(Number(time[0]));
    slot.setMinutes(Number(time[1]));
    return slot;
  }

  toDatetimeInput(datetime: Date): string {
    return `${datetime.getDate()}/${datetime.getMonth() + 1}/${datetime.getFullYear()}`
      + ` ${datetime.getHours()}:${datetime.getMinutes()}`;
  }

  toHourInput(datetime: Date): string {
    return `${this.zeroPrefix(datetime.getHours())}:${this.zeroPrefix(datetime.getMinutes())}`;
  }

  monthFirstDay(year: number, month: number, isZeroBased = true): Date {
    const firstDay: Date = new Date();
    firstDay.setFullYear(year, month - (isZeroBased ? 0 : 1), 1);
    firstDay.setHours(0, 0, 0, 0);

    return firstDay;
  }

  monthLastDay(year: number, month: number, isZeroBased = true): Date {
    const lastDay: Date = new Date();
    lastDay.setFullYear(year, month + (isZeroBased ? 1 : 0), 0);
    lastDay.setHours(0, 0, 0, 0);

    return lastDay;
  }

  yearStartAt(year: number): Date {
    const startAt: Date = new Date();
    startAt.setFullYear(year, 0, 1);
    startAt.setHours(0, 0, 0, 0);

    return startAt;
  }

  yearEndAt(year: number): Date {
    const endAt: Date = new Date();
    endAt.setFullYear(year, 11, 31);
    endAt.setHours(23, 59, 59);

    return endAt;
  }

  getMonday(day: Date): Date {
    const dayNbr = day.getDay();
    const diff = day.getDate() - dayNbr + (dayNbr === 0 ? -6 : 1);
    const monday = new Date(day);
    monday.setHours(0, 0, 0, 0);
    monday.setDate(diff);

    return monday;
  }

  getSunday(day: Date): Date {
    return this.getNextDay(day, 7);
  }

  getNumDaysInMonth(year: number, month: number, isZeroBased = true): number {
    return new Date(year, month + (isZeroBased ? 1 : 0), 0).getDate();
  }

  zeroPrefix(value: number): string {
    return `${value < 10 ? '0' : ''}${value}`;
  }

  isCurrentWeek(dateToCheck: Date): boolean {
    const thisWeek = this.getMonday(new Date());
    const weekToCheck = this.getMonday(dateToCheck);

    return thisWeek.getTime() === weekToCheck.getTime();
  }

  private getNextDay(dayRef: Date, day: number): Date {
    const nextDay = new Date(dayRef);
    nextDay.setHours(0, 0, 0, 0);
    nextDay.setDate(nextDay.getDate() + (day + (7 - nextDay.getDay())) % 7);

    return nextDay;
  }
}
