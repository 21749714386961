<div class="free-editable-field-select-single free-editable-field">
  <w-free-button
    *ngIf="!(isModifying$ | async)"
    buttonType="tertiary"
    [isDisabled]="isDisabled"
    [label]="fieldLabel"
    [sideArea]="false"
    [size]="size"
    (clickButton)="onClickField()"
  ></w-free-button>

  <w-free-dropdown
    *ngIf="isModifying$ | async"
    [directionX]="directionX"
    [fieldSize]="size"
    fieldType="select"
    [formControl]="selectCtrl"
    [itemLabelKey]="itemLabelKey"
    [itemValueKey]="itemValueKey"
    [items]="items"
    unselect="off"
    (closeMenu)="onCloseSelect()"
    wClickOutside (clickOutside)="onClickOutsideSelect()"
  ></w-free-dropdown>
</div>
