import { TitleCasePipe, UpperCasePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { Student } from 'src/app/core/models/student.model';

@Pipe({
  name: 'studentFullname'
})
export class StudentFullnamePipe implements PipeTransform {

  constructor(
    private titleCasePipe: TitleCasePipe,
    private upperCasePipe: UpperCasePipe,
  ) {
  }

  transform(student: Student, isShort: boolean = false): string {
    if (!student) { return ''; }

    const lastname: string = isShort
      ? this.titleCasePipe.transform(student.lastName.substr(0, 1)) + '.'
      : this.upperCasePipe.transform(student.lastName);

    return student.firstName
        .split('-')
        .map(str => this.titleCasePipe.transform(str))
        .join('-')
      + ' ' + lastname;
  }
}
