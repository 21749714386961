import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { throwIfAlreadyLoaded } from './module-import-guard';
import { APP_BASE_HREF } from '@angular/common';
import { NavigationBarComponent } from './layout/navigation-bar/navigation-bar.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LayoutComponent } from './layout/layout.component';
import { RouterModule } from '@angular/router';
import { UserWidgetComponent } from './layout/navigation-bar/user-widget/user-widget.component';
import { SharedModule } from '../shared/shared.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './auth/token.interceptor';
import { UnauthorizedInterceptor } from './auth/unauthorized.interceptor';
import {WINDOW} from './utils/window.service';
import { SnackbarComponent } from './layout/snackbar/snackbar.component';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
    declarations: [
        NavigationBarComponent,
        LayoutComponent,
        UserWidgetComponent,
        SnackbarComponent
    ],
  imports: [
    CommonModule,
    RouterModule,
    FontAwesomeModule,
    SharedModule,
    ReactiveFormsModule,
  ],
    exports: [],
    providers: [
      { provide: APP_BASE_HREF, useValue: '/' },

      { provide: WINDOW, useFactory: () => window },

      {
          provide:    HTTP_INTERCEPTORS,
          useClass:   TokenInterceptor,
          multi:      true,
      },

      {
          provide:    HTTP_INTERCEPTORS,
          useClass:   UnauthorizedInterceptor,
          multi:      true,
      },
    ],
  })
  export class CoreModule {

    /**
     * Forbide more than one instanciation of the core module.
     */
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        throwIfAlreadyLoaded(parentModule, 'CoreModule');
    }

}
