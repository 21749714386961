export enum DrivingLicenceTypeNameEnum {

  B = 'B',
  B1 = 'B1',
  BAAC = 'B AAC',
  BCS = 'B CS',
  BVA = 'BVA',
  BVAAAC = 'BVA AAC',
  BVACS = 'BVA CS',
  A = 'A',
  A1 = 'A1',
  A2 = 'A2',
  AM = 'AM',
  B96 = 'B96',
  BE = 'BE',
  C = 'C',
  C1 = 'C1',
  C1E = 'C1E',
  CE = 'CE',
  D = 'D',
  D1 = 'D1',
  D1E = 'D1E',
  DE = 'DE',
  PROCACES = 'PRO CACES',
  PROECSR = 'PRO ECSR',
  PROFCO = 'PRO FCO',
  PROFIMO = 'PRO FIMO',
  BATEAUC = 'Bateau C',
  BATEAUH = 'Bateau H',
  BATEAUF = 'Bateau F',
  Pap = 'PàP',
  TestPsy = 'Test psy.'

}
