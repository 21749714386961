import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";

@Component({
  selector: 'w-free-confirm-action',
  templateUrl: './free-confirm-action.component.html'
})
export class FreeConfirmActionComponent implements OnInit {

  @Input() actionText = 'Souhaitez-vous confirmer ?';
  @Input() cancelLabel = 'Annuler';
  @Input() cancelOnCLickOutside = true;
  @Input() confirmLabel = 'Confirmer';
  @Input() directionX: 'left' | 'right' = 'right';
  @Input() directionY: 'bottom' | 'top' = 'bottom';
  @Input() isDisabled = false;
  @Input() needConfirmAction = false;

  @Output() cancel = new EventEmitter<void>();
  @Output() confirm = new EventEmitter<void>();

  constructor() { }

  ngOnInit(): void {
  }

  onClickCancel(): void {
    this.cancel.emit();
  }

  onClickConfirm(): void {
    this.confirm.emit();
  }

  onClickOutside(): void {
    if (this.cancelOnCLickOutside) {
      this.cancel.emit();
    }
  }
}
