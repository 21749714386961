import { Injectable } from '@angular/core';
import { PaginationRange } from 'src/app/core/utils/pagination/pagination-range.model';
import { PaginationOrder } from 'src/app/core/utils/pagination/pagination-order.model';
import { HttpOptions } from './http-options.model';

@Injectable({
  providedIn: 'root'
})
export class HttpOptionsService {

  constructor() { }


  getOptions(range?: PaginationRange, orderBy?: PaginationOrder): HttpOptions {
    const options = new HttpOptions();
    if (range) {
      options.range = range;
    }
    if (orderBy) {
      options.orderBy = orderBy;
    }
    return options;
  }
}
