import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {ControlValueAccessor, UntypedFormBuilder, UntypedFormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Subscription} from 'rxjs';
import {FreeTextInputComponent} from '../free-text-input/free-text-input.component';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import {IconDefinition} from '@fortawesome/fontawesome-common-types';

@Component({
  selector: 'w-free-email-input',
  templateUrl: './free-email-input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: FreeEmailInputComponent
    }
  ]
})
export class FreeEmailInputComponent implements OnInit, OnDestroy, ControlValueAccessor {

  static emailInputCount = 0;

  @Input() helperText = '';
  @Input() htmlId = `freeEmailInput${FreeEmailInputComponent.emailInputCount++}`;
  @Input() inputName = 'freeEmailInputName';
  @Input() invalidText = 'Invalid field value';
  @Input() isAutocomplete = true;
  @Input() isInvalid = false;
  @Input() label = '';
  @Input() maxLength = 191;
  @Input() placeholder = 'bruce@wayne.com';
  @Input() size: 'small' | 'medium' | 'large' = 'medium';
  @Input() trailingIcon: IconDefinition = null;

  @Output() focusInput = new EventEmitter<void>();
  @Output() touched = new EventEmitter<void>();

  @ViewChild('textInput') textInput: FreeTextInputComponent;

  iconEmail = faEnvelope;

  inputCtrl: UntypedFormControl;

  private valueChangesSub: Subscription;

  constructor(
    private fb: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.initControl();
  }

  ngOnDestroy(): void {
    this.valueChangesSub.unsubscribe();
  }

  writeValue(value: string) {
    this.inputCtrl.setValue(value, { emitEvent: false });
  }

  onChange: (value: string) => void = () => {};
  registerOnChange(onChange: any) {
    this.onChange = onChange;

    this.observeInput();
  }

  onTouched = () => {};
  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  setDisabledState(disabled: boolean) {
    if (disabled) {
      this.inputCtrl.disable();
    } else {
      this.inputCtrl.enable();
    }
  }

  onInputFocus(): void {
    this.focusInput.emit();
  }

  onInputTouched(): void {
    this.onTouched();
  }

  get inputElt(): any {
    return this.textInput.inputElt;
  }

  private initControl(): void {
    this.inputCtrl = this.fb.control('');
  }

  private observeInput(): void {
    this.valueChangesSub = this.inputCtrl.valueChanges.subscribe(value => this.onChange(value));
  }
}
