import { Directive, AfterViewInit, ElementRef } from '@angular/core';
 
@Directive({
  selector: "[wAutofocus]"
})
export class AutofocusDirective implements AfterViewInit {
 
  /** CONSTRUCTOR */

  constructor(private elementRef : ElementRef) {
  }
 
  
  /** PUBLIC METHODS */

  /**
   * @see AfterViewInit
   */
  ngAfterViewInit() {
    this.elementRef.nativeElement.focus();
  }
 
}