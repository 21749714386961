import {Injectable, OnDestroy} from '@angular/core';
import {Observable} from 'rxjs';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {map, shareReplay} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class HttpImageCacheService implements OnDestroy {

  private cachedImages: { [url: string]: Observable<SafeUrl> } = {};

  private objectUrls: string[] = [];

  constructor(
    private http: HttpClient,
    private sanitizer: DomSanitizer
  ) {}

  ngOnDestroy(): void {
    this.objectUrls.forEach(url => URL.revokeObjectURL(url));
  }

  image$(url: string): Observable<SafeUrl> {
    if (!this.cachedImages[url]) {
      this.cachedImages[url] = this.cachedImage$(url);
    }
    return this.cachedImages[url];
  }

  private cachedImage$(url: string): Observable<SafeUrl> {
    return this.http.get(url, { responseType: 'blob'}).pipe(
      map(blob => {
        const objectUrl = URL.createObjectURL(blob);
        this.objectUrls.push(objectUrl);
        return this.sanitizer.bypassSecurityTrustUrl(objectUrl);
      }),
      shareReplay(1)
    );
  }
}
