import { Directive, Input, HostListener, Inject, InjectionToken } from '@angular/core';
import { MaskedInput } from '../models/masked-input.interface';

export const MASKED_INPUT_TOKEN = new InjectionToken<MaskedInput>('MaskedInput');

@Directive({
  selector: '[wInputMask]'
})
export class InputMaskDirective {

  @Input() wInputMask: string;

  constructor(
    @Inject(MASKED_INPUT_TOKEN)
    private el: MaskedInput
  ) {}


  @HostListener('ngModelChange', ['$event'])
  onModelChange(event: string): void {
    this.applyMask(event, false);
  }

  @HostListener('keydown.backspace', ['$event'])
  keydownBackspace(event: KeyboardEvent): void {
    event.preventDefault(); // Hack to prevent ngModelChange from firing
    this.applyDeletion();
    this.applyMask(this.el.value, true);
  }

  private applyMask(value: string, isBackspace: boolean): void {
    if (!this.wInputMask || !value) { return; }

    value = value.replace(/\D/g, '');
    const inputElement = this.el.inputElt;
    const cursorPositionStart = inputElement.selectionStart;
    const cursorPositionEnd = inputElement.selectionEnd;
    const isCursorAtEnd = cursorPositionEnd === inputElement.value.length;

    let result = '';
    for (let i = 0, j = 0; i < this.wInputMask.length && j < value.length;) {
      if (this.wInputMask[i] === 'X') {
        result += value[j++];
        i++;
        if (i < this.wInputMask.length && this.wInputMask[i] !== 'X' && !isBackspace) {
          result += this.wInputMask[i++];
        }
      } else {
        result += this.wInputMask[i++];
      }
    }

    if (this.el.value !== result) {
      this.el.value = result;

      if (!isCursorAtEnd) {
        inputElement.setSelectionRange(cursorPositionStart, cursorPositionEnd);
      }
    }
  }

  /**
   * Recreates default behavior of backspace key
   * @private
   */
  private applyDeletion(): void {
    const inputElement = this.el.inputElt;
    const selectionStart = inputElement.selectionStart;
    const selectionEnd = inputElement.selectionEnd;

    if (!selectionStart && selectionEnd === selectionStart) {
      return;
    }

    const value = this.el.value;
    let newValue: string;
    let newCursorPosition: number;

    if (selectionEnd > selectionStart) {
      newValue = value.substring(0, selectionStart) + value.substring(selectionEnd);
      newCursorPosition = selectionStart;
    } else {
      let cursorPosition = selectionStart;
      if (this.isMaskCharacter(cursorPosition - 1)) {
        cursorPosition--;
      }
      newValue = value.substring(0, cursorPosition - 1) + value.substring(cursorPosition);
      newCursorPosition = cursorPosition - 1;
    }

    this.el.value = newValue;
    inputElement.setSelectionRange(newCursorPosition, newCursorPosition);
  }

  private isMaskCharacter(position: number): boolean {
    return this.wInputMask[position] && this.wInputMask[position] !== 'X';
  }
}
