export class User {

  departmentCodes: string[] = [];
  drivingSchoolZipCode: string|null;
  email = '';
  firstName = '';
  isTrainerPlanningReadOnly: boolean;

  // TODO TMP: CUSTOM COLORS
  customColorPrimary: string | null = null;
  customColorSecondary: string | null = null;

  get hasDrivingSchoolZipCode(): boolean {
    return this.drivingSchoolZipCode !== null && this.drivingSchoolZipCode.trim() !== '';
  }

  get drivingSchoolDepartmentCode(): string|null {
    let value = null;
    if (this.hasDrivingSchoolZipCode) {
      if (this.drivingSchoolZipCode.startsWith('97') || this.drivingSchoolZipCode.startsWith('98')) {
        value = this.drivingSchoolZipCode.substring(0, 3);
      } else {
        value = '0' + this.drivingSchoolZipCode.substring(0, 2);
      }
    }
    return value;
  }

}
