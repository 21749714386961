import {Injectable} from '@angular/core';
import {ApiDrivingSchemaTypesCategory} from '../api-responses/api-driving-schema-types-category.model';
import {ApiDrivingSchemaType} from '../api-responses/api-driving-schema-type.model';
import {DrivingSchemaType} from '../../models/driving-schema-type.model';
import {DrivingSchemaTypesCategory} from '../../models/driving-schema-types-category.model';

@Injectable({
  providedIn: 'root'
})
export class DrivingSchemaTypeAssemblerService {

  apiSchemaCategoryToModel(apiCategory: ApiDrivingSchemaTypesCategory): DrivingSchemaTypesCategory {
    const schemaTypeCategories = new DrivingSchemaTypesCategory();
    schemaTypeCategories.category = apiCategory.category;
    schemaTypeCategories.schemaTypes = apiCategory.schemaTypes.map(apiSchemaType => this.apiSchemaTypeToModel(apiSchemaType));

    return schemaTypeCategories;
  }

  apiSchemaTypeToModel(apiSchemaType: ApiDrivingSchemaType): DrivingSchemaType {
    const schemaType = new DrivingSchemaType();
    schemaType.id = apiSchemaType.id;
    schemaType.filename = apiSchemaType.filename;
    schemaType.category = apiSchemaType.category;
    schemaType.situation = apiSchemaType.situation;
    schemaType.externalUrl = apiSchemaType.externalUrl;
    schemaType.internalUrl = apiSchemaType.internalUrl;

    return schemaType;
  }
}
