<div class="free-select-button">
  <ng-container *ngFor="let option of options">
    <div
      class="free-select-button-option"
      [class.free-select-button-option-selected]="isSelectedOption$(option) | async"
      (click)="onClickOption(option)"
    >
      {{ option[optionLabelKey] }}
    </div>
  </ng-container>
</div>
