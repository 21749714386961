export class Price {

  private readonly _amountInCents: number;

  private constructor(amountInCents = 0) {
    this._amountInCents = amountInCents;
  }


  static fromAmountInCents(amount: number): Price {
    return new Price(amount);
  }

  static fromAmountInEuro(amount: number): Price {
    return new Price(amount * 100);
  }

  static zero(): Price {
    return new Price();
  }

  /**
   * discountRate is provided in %
   */
  applyDiscount(discountRate: number): Price {
    return new Price(this._amountInCents * (1 - discountRate / 100));
  }

  /**
   * TaxRate is provided in %
   */
  excludeTax(taxRate: number): Price {
    return new Price(this._amountInCents / (1 + taxRate / 100));
  }

  /**
   * TaxRate is provided in %
   */
  includeTax(taxRate: number): Price {
    return new Price(this._amountInCents * (1 + taxRate / 100));
  }

  /**
   * Compare values in cents
   */
  equals(value: Price): boolean {
    return value.amountInCents === this._amountInCents;
  }

  add(value: Price): Price {
    return new Price(this._amountInCents + value.amountInCents);
  }

  subtract(value: Price): Price {
    return new Price(this._amountInCents - value.amountInCents);
  }

  multiply(multiplier: number): Price {
    return new Price(this._amountInCents * multiplier);
  }

  divide(divider: number): Price {
    return new Price(this._amountInCents / divider);
  }

  get amountInEuro(): number {
    return this._amountInCents / 100;
  }

  get amountInCents(): number {
    return this._amountInCents;
  }

  get isZero(): boolean {
    return this._amountInCents === 0;
  }
}
