import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {faChevronDown} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'w-free-pagination',
  templateUrl: './free-pagination.component.html'
})
export class FreePaginationComponent implements OnInit {

  @Input() isDisabled = false;
  @Input() label = 'Voir plus';

  @Output() showMore = new EventEmitter<void>();

  iconChevronDown = faChevronDown;

  constructor() {
  }

  ngOnInit(): void {
  }

  onClickShowMore(): void {
    this.showMore.emit();
  }
}
