import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {ControlValueAccessor, UntypedFormBuilder, UntypedFormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Subscription} from 'rxjs';
import {FreeTextInputComponent} from '../free-text-input/free-text-input.component';

@Component({
  selector: 'w-free-phone-input',
  templateUrl: './free-phone-input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: FreePhoneInputComponent
    }
  ]
})
export class FreePhoneInputComponent implements OnInit, OnDestroy, ControlValueAccessor {

  static phoneInputCount = 0;

  @Input() helperText = '';
  @Input() htmlId = `freePhoneInput${FreePhoneInputComponent.phoneInputCount++}`;
  @Input() inputName = 'freePhoneInputName';
  @Input() invalidText = 'Invalid field value';
  @Input() isAutocomplete = true;
  @Input() isInvalid = false;
  @Input() label = '';
  @Input() maxLength = 32;
  @Input() placeholder = '06 85 63 57 52';
  @Input() size: 'small' | 'medium' | 'large' = 'medium';

  @Output() focusInput = new EventEmitter<void>();
  @Output() touched = new EventEmitter<void>();

  @ViewChild('textInput') textInput: FreeTextInputComponent;

  inputCtrl: UntypedFormControl;

  private valueChangesSub: Subscription;

  constructor(
    private fb: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.initControl();
  }

  ngOnDestroy(): void {
    this.valueChangesSub.unsubscribe();
  }

  writeValue(value: string) {
    this.inputCtrl.setValue(value, { emitEvent: false });
  }

  onChange: (value: string) => void = () => {};
  registerOnChange(onChange: any) {
    this.onChange = onChange;

    this.observeInput();
  }

  onTouched = () => {};
  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  setDisabledState(disabled: boolean) {
    if (disabled) {
      this.inputCtrl.disable();
    } else {
      this.inputCtrl.enable();
    }
  }

  onInputFocus(): void {
    this.focusInput.emit();
  }

  onInputTouched(): void {
    this.onTouched();
  }

  get inputElt(): any {
    return this.textInput.inputElt;
  }

  private initControl(): void {
    this.inputCtrl = this.fb.control('');
  }

  private observeInput(): void {
    this.valueChangesSub = this.inputCtrl.valueChanges.subscribe(value => this.onChange(value));
  }
}
