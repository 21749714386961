import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { DrivingSkill } from 'src/app/core/models/driving-skill.model';
import {ConfigService} from '../../utils/config.service';
import {ApiDrivingSkill} from '../api-responses/api-driving-skill.model';
import {DrivingLessonType} from '../../models/driving-lesson-type.model';
import {ApiDrivingLessonType} from '../api-responses/api-driving-lesson-type.model';
import {DrivingLicenceTypeGroup} from '../../models/driving-licence-type-group.model';
import {MeetingPoint} from '../../models/meeting-point.model';
import {MeetingPointAssemblerService} from '../assemblers/meeting-point-assembler.service';
import {filter, finalize, map, shareReplay, take, tap} from 'rxjs/operators';
import {ApiMeetingPoint} from '../api-responses/api-meeting-point.model';
import {ApiDrivingLicenceTypeGroup} from '../api-responses/api-driving-licence-type-group.model';
import {DrivingSkillAssemblerService} from '../assemblers/driving-skill-assembler.service';
import {DrivingLessonTypeAssemblerService} from '../assemblers/driving-lesson-type-assembler.service';
import {ApiDrivingSchemaTypesCategory} from '../api-responses/api-driving-schema-types-category.model';
import {DrivingSchemaTypeAssemblerService} from '../assemblers/driving-schema-type-assembler.service';
import { ApiDrivingLicenceType } from '../api-responses/api-driving-licence-type.model';
import { DrivingLicenceTypeAssemblerService } from '../assemblers/driving-licence-type-assembler.service';
import { DrivingLicenceType } from '../../models/driving-licence-type.model';
import { HttpOptionsService } from '../../utils/http/http-options.service';

@Injectable({
  providedIn: 'root'
})
export class ApiRefService {

  private isLoadedDrivingLessonTypes = false;
  private isLoadedDrivingLicenceTypeGroups = false;
  private isLoadedMeetingPoints = false;

  private isLoadingDrivingLessonTypes = false;
  private isLoadingDrivingLicenceTypeGroups = false;
  private isLoadingMeetingPoints = false;

  private drivingLessonTypesSrc = new BehaviorSubject<DrivingLessonType[]>([]);
  private drivingLicenceTypeGroupsSrc = new BehaviorSubject<DrivingLicenceTypeGroup[]>([]);
  private meetingPointsSrc = new BehaviorSubject<MeetingPoint[]>([]);

  readonly drivingSchemaTypes$ = this.http.get<ApiDrivingSchemaTypesCategory[]>(`${this.config.apiUrl}/ref/driving-schema-types`).pipe(
    map(apiTypes => apiTypes.map(apiType => this.drivingSchemaTypeAssembler.apiSchemaCategoryToModel(apiType))),
    shareReplay(1)
  );

  readonly drivingSkills$ = new Proxy<{ [key: string]: Observable<DrivingSkill[]> }>({}, {
    get: (target, prop) => {
      const propName = prop.toString();
      if (!target.hasOwnProperty(propName)) {
        target[propName] = this.fetchDrivingSkills(propName);
      }

      return target[propName];
    }
  });

  readonly drivingLicenceTypes$ = this.http.get<ApiDrivingLicenceType[]>(
      `${this.config.apiUrl}/ref/driving-licence-types`
  ).pipe(
      map(apiTypes => apiTypes.map(apiType => this.licenceTypeAssembler.toModel(apiType))),
      shareReplay(1)
  );

  constructor(
    private config: ConfigService,
    private http: HttpClient,
    private httpOptions: HttpOptionsService,
    private drivingLessonTypeAssembler: DrivingLessonTypeAssemblerService,
    private licenceTypeAssembler: DrivingLicenceTypeAssemblerService,
    private drivingSkillAssembler: DrivingSkillAssemblerService,
    private meetingPointAssembler: MeetingPointAssemblerService,
    private drivingSchemaTypeAssembler: DrivingSchemaTypeAssemblerService
  ) {}

  getDrivingLessonTypeById$(drivingLessonTypeId: number): Observable<DrivingLessonType> {
    return this.getRefItemById$(this.drivingLessonTypes$, drivingLessonTypeId);
  }

  getDrivingLicenceTypeById$(drivingLicenceTypeId: number): Observable<DrivingLicenceType> {
    return this.getRefItemById$(this.drivingLicenceTypes$, drivingLicenceTypeId);
  }

  getDrivingSkillById$(drivingSkillId: number, drivingLicenceTypeGroup: string): Observable<DrivingSkill> {
    return this.drivingSkills$[drivingLicenceTypeGroup].pipe(
      filter(skills => skills.length > 0),
      take(1),
      map(skills => {
        let drivingSkill = skills.find(skill => skill.id === drivingSkillId);
        let iSkill = 0;
        const numSkills = skills.length;
        while (!drivingSkill && iSkill < numSkills) {
          drivingSkill = skills[iSkill].subSkills.find(skill => skill.id === drivingSkillId);
          iSkill++;
        }

        return {
          ...drivingSkill,
          subSkills: drivingSkill?.subSkills?.map(subSkill => ({ ...subSkill }))
        };
      }
    ));
  }

  get drivingLessonTypesNoExam$(): Observable<DrivingLessonType[]> {
    return this.drivingLessonTypes$.pipe(map(types => types.filter(type => !type.isDrivingTest)));
  }

  private getRefItemById$<T extends { id: number }>(list$: Observable<T[]>, id: number): Observable<T> {
    return list$.pipe(
      filter(list => list.length > 0),
      take(1),
      map(list => list.find(item => item.id === id)
      ));
  }

  private loadDrivingLessonTypes(): void {
    this.isLoadingDrivingLessonTypes = true;
    this.http.get<ApiDrivingLessonType[]>(`${this.config.apiUrl}/ref/driving-lesson-types`)
      .pipe(
        tap(() => this.isLoadedDrivingLessonTypes = true),
        finalize(() => this.isLoadingDrivingLessonTypes = false)
      )
      .subscribe(apiDrivingLessonTypes => {
        const drivingLessonTypes = apiDrivingLessonTypes.map(
          apiDrivingLessonType => this.drivingLessonTypeAssembler.toModel(apiDrivingLessonType)
        );
        this.drivingLessonTypesSrc.next(drivingLessonTypes);
      });
  }

  private fetchDrivingSkills(drivingLicenceTypeGroup: string = 'B'): Observable<DrivingSkill[]> {
    const httpOptions = this.httpOptions.getOptions();
    httpOptions.observeBody();
    httpOptions.setParam('drivingLicenceTypeGroup', drivingLicenceTypeGroup);

    return this.http.get<ApiDrivingSkill[]>(`${this.config.apiUrl}/ref/driving-skills`, httpOptions.http)
      .pipe(
        map(apiDrivingSkills => apiDrivingSkills.map(
          apiDrivingSkill => this.drivingSkillAssembler.toModel(apiDrivingSkill)
        )),
        shareReplay(1)
      );
  }

  private loadDrivingLicenceTypeGroups(): void {
    this.isLoadingDrivingLicenceTypeGroups = true;
    this.http.get<ApiDrivingLicenceTypeGroup[]>(`${this.config.apiUrl}/ref/driving-licence-type-groups`)
      .pipe(
        tap(() => this.isLoadedDrivingLicenceTypeGroups = true),
        finalize(() => this.isLoadingDrivingLicenceTypeGroups = false)
      )
      .subscribe(apiGroups => {
        const groups = apiGroups.map(apiGroup => new DrivingLicenceTypeGroup(apiGroup.id, apiGroup.name));
        this.drivingLicenceTypeGroupsSrc.next(groups);
      });
  }

  private loadMeetingPoints(): void {
    this.isLoadingMeetingPoints = true;
    this.http.get<ApiMeetingPoint[]>(`${this.config.apiUrl}/ref/meeting-points`)
      .pipe(
        tap(() => this.isLoadedMeetingPoints = true),
        finalize(() => this.isLoadingMeetingPoints = false)
      )
      .subscribe(apiMeetingPoints => {
        this.meetingPointsSrc.next(apiMeetingPoints.map(
          apiMeetingPoint => this.meetingPointAssembler.toModel(apiMeetingPoint)
        ));
      });
  }

  get drivingLessonTypes$(): Observable<DrivingLessonType[]> {
    if (!this.isLoadedDrivingLessonTypes && !this.isLoadingDrivingLessonTypes) {
      this.loadDrivingLessonTypes();
    }
    return this.drivingLessonTypesSrc.asObservable();
  }

  get drivingLicenceTypeGroups$(): Observable<DrivingLicenceTypeGroup[]> {
    if (!this.isLoadedDrivingLicenceTypeGroups && !this.isLoadingDrivingLicenceTypeGroups) {
      this.loadDrivingLicenceTypeGroups();
    }
    return this.drivingLicenceTypeGroupsSrc.asObservable();
  }

  get meetingPoints$(): Observable<MeetingPoint[]> {
    if (!this.isLoadedMeetingPoints && !this.isLoadingMeetingPoints) {
      this.loadMeetingPoints();
    }
    return this.meetingPointsSrc.asObservable();
  }
}
