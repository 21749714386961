import {Injectable} from '@angular/core';
import {ApiStudent} from '../api-responses/api-student.model';
import {Student} from '../../models/student.model';
import {PostalAddress} from '../../models/shared/postal-address.model';
import {ApiStudentSummary} from '../api-responses/api-student-summary.model';
import {DrivingLicenceTypeAssemblerService} from './driving-licence-type-assembler.service';
import {ApiStudentSchedulingCredit} from '../api-responses/api-student-scheduling-credit.model';
import {StudentSchedulingCredit} from '../../models/student-scheduling-credit';
import {Price} from '../../models/shared/price.model';
import { DateTimeAssemblerService } from './date-time-assembler.service';
import { Observable } from 'rxjs';
import { ApiRefService } from '../api-services/api-ref.service';
import { map } from 'rxjs/operators';
import { DrivingLicenceType } from '../../models/driving-licence-type.model';

@Injectable({
  providedIn: 'root'
})
export class StudentAssemblerService {

  constructor(
    private licenceTypeAssembler: DrivingLicenceTypeAssemblerService,
    private dateTimeAssembler: DateTimeAssemblerService,
    private apiRefRepo: ApiRefService,
  ) {}

  toModel(apiStudent: ApiStudent): Student {
    const student = new Student();
    student.id = apiStudent.id;
    student.firstName = apiStudent.firstName;
    student.lastName = apiStudent.lastName;
    student.address = new PostalAddress(
      apiStudent.address,
      apiStudent.zipCode,
      apiStudent.city,
      apiStudent.streetNumber,
      apiStudent.streetExtension
    );
    student.agency = apiStudent.agency;
    student.isBalanced = apiStudent.isBalanced;
    student.isDebtor = apiStudent.isDebtor;
    student.drivingLicenceType = this.licenceTypeAssembler.toModel(apiStudent.drivingLicenceType);

    student.comingDrivingLessonsDurationInMinutes = apiStudent.comingDrivingLessonsDurationInMinutes;
    student.doneDrivingLessonsDurationInMinutes = apiStudent.doneDrivingLessonsDurationInMinutes;
    student.numEstimatedTrainingHours = apiStudent.numEstimatedTrainingHours;
    student.isFastTrackTraining = apiStudent.isFastTrackTraining;

    student.extraLessonPrice = apiStudent.extraLessonPriceInCents !== null
      ? Price.fromAmountInCents(apiStudent.extraLessonPriceInCents)
      : null;
    student.extraLessonSharedPrice = apiStudent.extraLessonSharedPriceInCents !== null
      ? Price.fromAmountInCents(apiStudent.extraLessonSharedPriceInCents)
      : null;

    student.evaluationRequestedAt = this.dateTimeAssembler.nullableApiDateToModel(apiStudent.evaluationRequestedAt);

    return student;
  }

  apiSummaryToModel(apiStudent: ApiStudentSummary): Observable<Student> {
    const student = new Student();
    Object.assign(student, apiStudent);

    student.address = new PostalAddress(
      apiStudent.address,
      apiStudent.zipCode,
      apiStudent.city,
      apiStudent.streetNumber,
      apiStudent.streetExtension
    );

    student.extraLessonPrice = apiStudent.extraLessonPriceInCents !== null
      ? Price.fromAmountInCents(apiStudent.extraLessonPriceInCents)
      : null;
    student.extraLessonSharedPrice = apiStudent.extraLessonSharedPriceInCents !== null
      ? Price.fromAmountInCents(apiStudent.extraLessonSharedPriceInCents)
      : null;

    student.neph = apiStudent.neph;
    student.birthdate = this.dateTimeAssembler.nullableApiDateToModel(apiStudent.birthdate);

    const drivingLicenceType$ = this.apiRefRepo.getDrivingLicenceTypeById$(apiStudent.drivingLicenceTypeId);

    return drivingLicenceType$.pipe(
      map((drivingLicenceType: DrivingLicenceType) => {
        student.drivingLicenceType = drivingLicenceType;
        return student;
      })
    );
  }

  apiSchedulingCreditToModel(apiCredit: ApiStudentSchedulingCredit): StudentSchedulingCredit {
    const credit = new StudentSchedulingCredit();
    credit.drivingLessonTypeId = apiCredit.drivingLessonTypeId;
    credit.durationInMinutes = apiCredit.durationInMinutes;

    return credit;
  }
}
