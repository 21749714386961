<div
  [ngClass]="{ dropdown: true, 'dropdown-tainted': type === 'select' && selectedOption }"
  wClickOutside (clickOutside)="close()"
>
  <a href="#" class="dropdown-toggle" (click)="toggle()" wClickPreventDefault>
    <div class="dropdown-label">
      <div class="dropdown-title"><ng-content></ng-content></div>
      <div class="dropdown-subtitle" *ngIf="type === 'select' && selectedOption">{{ selectedOptionLabel }}</div>
    </div>
    <span class="icon" *ngIf="isDisplayedArrow"><fa-icon [icon]="iconArrow"></fa-icon></span>
  </a>

  <div class="dropdown-menu {{ menuSide }}" *ngIf="visible">
    <div *ngFor="let option of _options"
      [ngClass]="{ 'dropdown-item': true, selected: isSelected(option) }"
      (mouseover)="hoverItem(option)"
      (mouseout)="unhoverItem(option)">
      <a *ngIf="type === 'select'"
        href="#"
        (click)="isSelected(option) ? unselectItem(option) : selectItem(option)"
        wClickPreventDefault
      >
        {{ option.label }}
        <span class="icon" *ngIf="isSelected(option) && !isHovered(option)">
          <fa-icon [icon]="iconSelected"></fa-icon>
        </span>
        <span class="icon" *ngIf="isSelected(option) && isHovered(option)" >
          <fa-icon [icon]="iconUnselect"></fa-icon>
        </span>
      </a>
      <a *ngIf="type === 'link'" routerLink="{{ option.value }}" (click)="selectItem(option)">{{ option.label }}</a>
      <a *ngIf="type === 'button'" (click)="selectItem(option)">{{ option.label }}</a>
    </div>
  </div>
</div>
