import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormBuilder,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR, ValidationErrors,
  Validators,
  Validator, UntypedFormControl
} from '@angular/forms';
import {merge, Subscription} from 'rxjs';
import {FreeTextInputComponent} from "../free-text-input/free-text-input.component";

@Component({
  selector: 'w-free-date-input',
  templateUrl: './free-date-input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: FreeDateInputComponent
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: FreeDateInputComponent
    }
  ]
})
export class FreeDateInputComponent implements OnInit, OnDestroy, ControlValueAccessor, Validator {

  static dateInputCount = 0;

  private readonly dateSeparator = '/';
  private readonly timeSeparator = ':';

  @Input() helperText = '';
  @Input() htmlId = `freeDateInput${FreeDateInputComponent.dateInputCount++}`;
  @Input() invalidText = 'Invalid field value';
  @Input() isDatetime = false;
  @Input() isInvalid = false;
  @Input() label = '';
  @Input() size: 'small' | 'medium' | 'large' = 'medium';

  @ViewChild('dateInput') dateInput: FreeTextInputComponent;

  dateInputCtrl: UntypedFormControl;
  timeInputCtrl: UntypedFormControl;

  private dateValueChangesSub: Subscription;

  constructor(
    private fb: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.initControls();
  }

  ngOnDestroy(): void {
    this.dateValueChangesSub.unsubscribe();
  }

  writeValue(value: Date) {
    if (!!value) {
      this.dateInputCtrl.setValue(
        FreeDateInputComponent.pad(value.getDate())
        + this.dateSeparator
        + FreeDateInputComponent.pad(value.getMonth() + 1)
        + this.dateSeparator
        + value.getFullYear(),
        { emitEvent: false }
      );
      this.timeInputCtrl.setValue(
        FreeDateInputComponent.pad(value.getHours())
        + this.timeSeparator
        + FreeDateInputComponent.pad(value.getMinutes()),
        { emitEvent: false }
      )
    } else {
      this.dateInputCtrl.setValue('', { emitEvent: false });
      this.timeInputCtrl.setValue('', { emitEvent: false });
    }
  }

  onChange: (value: Date) => void = () => {};
  registerOnChange(onChange: any) {
    this.onChange = onChange;

    this.observeInputs();
  }

  onTouched = () => {};
  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  setDisabledState(disabled: boolean) {
    if (disabled) {
      this.dateInputCtrl.disable();
      this.timeInputCtrl.disable();
    } else {
      this.dateInputCtrl.enable();
      this.timeInputCtrl.enable();
    }
  }

  validate(_: AbstractControl): ValidationErrors|null {
    if (this.dateInputCtrl.valid && this.timeInputCtrl.valid) {
      const explodedDate = this.dateInputCtrl.value.split(this.dateSeparator);
      const dateValue = Number(explodedDate[0]);
      const monthValue = Number(explodedDate[1]);
      const yearValue = Number(explodedDate[2]);
      const numDays = new Date(yearValue, monthValue, 0).getDate();
      if (numDays < dateValue) {
        return { badDateInMonth: true };
      } else {
        return null;
      }
    } else {
      let errors = {};
      if (!this.dateInputCtrl.valid) {
        errors = {...errors, date: this.dateInputCtrl.errors };
      }
      if (!this.timeInputCtrl.valid) {
        errors = {...errors, date: this.timeInputCtrl.errors };
      }
      return errors;
    }
  }

  onInputTouched(): void {
    this.onTouched();
  }

  focus(): void {
    this.focusDate();
  }

  get hasLabel(): boolean {
    return this.label !== '';
  }

  get hasHelperText(): boolean {
    return this.helperText !== '';
  }

  private initControls(): void {
    this.dateInputCtrl = this.fb.control(
      '',
      Validators.pattern(`^(3[01]|[12][0-9]|0[1-9])${this.dateSeparator}(1[0-2]|0[1-9])${this.dateSeparator}[0-9]{4}$`)
    );

    this.timeInputCtrl = this.fb.control(
      '',
      Validators.pattern(`^(2[0-3]|[01][0-9])${this.timeSeparator}([0-5][0-9])$`)
    );
  }

  private observeInputs(): void {
    this.dateValueChangesSub = merge(this.dateInputCtrl.valueChanges, this.timeInputCtrl.valueChanges).subscribe(_ => {
      let newValue: Date = null;
      const dateValue = this.dateInputCtrl.value.trim();
      const timeValue = this.timeInputCtrl.value.trim();
      if (dateValue !== '' && (!this.isDatetime || timeValue !== '')) {
        if (this.validate(null) === null) {
          const explodedDate = dateValue.split(this.dateSeparator);
          const day = Number(explodedDate[0]);
          const month = Number(explodedDate[1]);
          const year = Number(explodedDate[2]);

          let hours = 0;
          let minutes = 0;
          if (this.isDatetime) {
            const explodedTime = timeValue.split(this.timeSeparator);
            hours = Number(explodedTime[0]);
            minutes = Number(explodedTime[1]);
          }

          newValue = new Date();
          newValue.setFullYear(year, month - 1, day);
          newValue.setHours(hours, minutes, 0, 0);
        }
      }

      this.onChange(newValue);
    });
  }

  private focusDate(): void {
    setTimeout(() => {
      this.dateInput.inputElt.focus();
      this.dateInput.inputElt.select();
    }, 0);
  }

  private static pad(value: number): string {
    const normalized = Math.floor(Math.abs(value));
    return (normalized < 10 ? '0' : '') + normalized;
  }
}
