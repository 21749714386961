import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {ControlValueAccessor, UntypedFormBuilder, UntypedFormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {BehaviorSubject, Subscription} from 'rxjs';
import {FreeNumberInputComponent} from '../free-number-input/free-number-input.component';
import {Distance} from '../../../../core/models/shared/distance.model';

@Component({
  selector: 'w-free-distance-input',
  templateUrl: './free-distance-input.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: FreeDistanceInputComponent
    }
  ]
})
export class FreeDistanceInputComponent implements OnInit, OnChanges, OnDestroy, ControlValueAccessor {

  static distanceInputCount = 0;

  @Input() helperText = '';
  @Input() htmlId = `freeDistanceInput${FreeDistanceInputComponent.distanceInputCount++}`;
  @Input() inputName = 'freeDistanceInputName';
  @Input() invalidText = 'Invalid field value';
  @Input() isAutocomplete = true;
  @Input() isInvalid = false;
  @Input() label = '';
  @Input() max: Distance = null;
  @Input() min: Distance = null;
  @Input() placeholder = '';
  @Input() size: 'small' | 'medium' | 'large' = 'medium';

  @Output() focusInput = new EventEmitter<void>();
  @Output() touched = new EventEmitter<void>();

  @ViewChild('numberInput') numberInput: FreeNumberInputComponent;

  inputCtrl: UntypedFormControl;

  private valueMaxSrc = new BehaviorSubject<number|null>(this.valueMax);
  readonly valueMax$ = this.valueMaxSrc.asObservable();
  private valueMinSrc = new BehaviorSubject<number|null>(this.valueMin);
  readonly valueMin$ = this.valueMinSrc.asObservable();

  private valueChangesSub: Subscription;

  constructor(
    private fb: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.initControl();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.handleState();
  }

  ngOnDestroy(): void {
    this.valueChangesSub.unsubscribe();
  }

  writeValue(value: Distance|null) {
    const numberValue = value !== null ? value.valueInKilometers : null;
    this.inputCtrl.setValue(numberValue, { emitEvent: false });
  }

  onChange: (value: Distance|null) => void = () => {};
  registerOnChange(onChange: any) {
    this.onChange = onChange;

    this.observeInput();
  }

  onTouched = () => {};
  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  setDisabledState(disabled: boolean) {
    if (disabled) {
      this.inputCtrl.disable();
    } else {
      this.inputCtrl.enable();
    }
  }

  onInputFocus(): void {
    this.focusInput.emit();
  }

  onInputTouched(): void {
    this.onTouched();
  }

  private get valueMax(): number|null {
    return this.max !== null ? this.max.valueInKilometers : null;
  }

  private get valueMin(): number|null {
    return this.min !== null ? this.min.valueInKilometers : null;
  }

  private handleState(): void {
    this.valueMaxSrc.next(this.valueMax);
    this.valueMinSrc.next(this.valueMin);
  }

  private initControl(): void {
    this.inputCtrl = this.fb.control(null);
  }

  private observeInput(): void {
    this.valueChangesSub = this.inputCtrl.valueChanges.subscribe(value => {
      this.onChange(value !== null ? Distance.fromValueInKilometers(value) : null);
    });
  }
}
