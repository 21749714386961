export class Distance {

  private readonly _valueInMeters: number;

  private constructor(valueInMeters = 0) {
    this._valueInMeters = valueInMeters;
  }

  static fromValueInMeters(value: number): Distance {
    return new Distance(value);
  }

  static fromValueInKilometers(value: number): Distance {
    return new Distance(value * 1000);
  }

  static zero(): Distance {
    return new Distance();
  }

  get valueInKilometers(): number {
    return this._valueInMeters / 1000;
  }

  get valueInMeters(): number {
    return this._valueInMeters;
  }

  get isZero(): boolean {
    return this._valueInMeters === 0;
  }

  equals(value: Distance): boolean {
    return value.valueInMeters === this._valueInMeters;
  }

  add(value: Distance): Distance {
    return new Distance(this._valueInMeters + value.valueInMeters);
  }

  subtract(value: Distance): Distance {
    return new Distance(this._valueInMeters - value.valueInMeters);
  }

  multiply(multiplier: number): Distance {
    return new Distance(this._valueInMeters * multiplier);
  }
}
