import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component, EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl} from '@angular/forms';
import {BehaviorSubject} from 'rxjs';
import {share} from "rxjs/operators";

@Component({
  selector: 'w-free-editable-field-select-single',
  templateUrl: './free-editable-field-select-single.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FreeEditableFieldSelectSingleComponent implements OnInit, OnChanges, AfterViewInit {

  @Input() directionX: 'left' | 'right' = 'right';
  @Input() fieldLabel = '';
  @Input() isDisabled = false;
  @Input() itemLabelKey = 'label';
  @Input() itemValueKey = 'value';
  @Input() items: Array<any> = [];
  @Input() size: 'small' | 'medium' | 'large' = 'medium';
  @Input() value: any;

  @Output() cancel = new EventEmitter<void>();
  @Output() submitValue = new EventEmitter<any>();

  private isModifyingSrc = new BehaviorSubject<boolean>(false);
  readonly isModifying$ = this.isModifyingSrc.asObservable().pipe(share());

  selectCtrl: UntypedFormControl;

  constructor(
    private fb: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.initControl();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes.isDisabled && !changes.isDisabled.isFirstChange()) {
      this.onIsDisabledChange();
    }
  }

  ngAfterViewInit(): void {
    this.onIsDisabledChange();
  }

  onCloseSelect(): void {
    if (this.selectCtrl.value !== this.value) {
      this.submitValue.emit(this.selectCtrl.value);
    } else {
      this.cancel.emit();
    }
    this.isModifyingSrc.next(false);
  }

  onClickField(): void {
    setTimeout(() => this.isModifyingSrc.next(true), 0);
  }

  onClickOutsideSelect(): void {
    this.cancel.emit();
    this.isModifyingSrc.next(false);
  }

  private initControl(): void {
    this.selectCtrl = this.fb.control(this.value);
  }

  private onIsDisabledChange(): void {
    if (this.isDisabled) {
      this.selectCtrl.disable();
    } else {
      this.selectCtrl.enable();
    }
  }
}
