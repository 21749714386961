import {FreePeriodTypeEnum} from "./free-period-type.enum";

export class FreePeriod {

  private readonly _type: FreePeriodTypeEnum;
  get type(): FreePeriodTypeEnum { return this._type; }

  private readonly _valueDate: Date;
  get valueDate(): Date { return this._valueDate; }

  private constructor(type: FreePeriodTypeEnum, valueDate: Date) {
    this._type = type;
    this._valueDate = new Date(valueDate);
  }

  static createDay(date: number, month: number, year: number): FreePeriod {
    const day = new Date();
    day.setFullYear(year, month - 1, date);
    day.setHours(0, 0, 0, 0);

    return new FreePeriod(FreePeriodTypeEnum.Day, day);
  }

  static createToday(): FreePeriod {
    const today = new Date();
    return FreePeriod.createDay(today.getDate(), today.getMonth() + 1, today.getFullYear());
  }

  static createThisMonth(): FreePeriod {
    const today = new Date();
    return FreePeriod.createMonth(today.getMonth() + 1, today.getFullYear());
  }

  static createThisYear(): FreePeriod {
    return FreePeriod.createYear((new Date()).getFullYear());
  }

  static createWeekFromDate(day: Date): FreePeriod {
    const monday = new Date(day);
    const dayNbr = day.getDay();
    const diff = day.getDate() - dayNbr + (dayNbr === 0 ? -6 : 1);
    monday.setHours(0, 0, 0, 0);
    monday.setDate(diff);

    return new FreePeriod(FreePeriodTypeEnum.Week, monday);
  }

  static createMonth(month: number, year: number): FreePeriod {
    const firstDayOfMonth = new Date();
    firstDayOfMonth.setFullYear(year, month - 1, 1);
    firstDayOfMonth.setHours(0, 0, 0, 0);

    return new FreePeriod(FreePeriodTypeEnum.Month, firstDayOfMonth);
  }

  static createQuarterFromDate(day: Date): FreePeriod {
    const firstDayOfQuarter = new Date(day);
    firstDayOfQuarter.setHours(0, 0, 0, 0);
    const quarterNumber = FreePeriod.getQuarterNumberFromDate(firstDayOfQuarter);
    firstDayOfQuarter.setFullYear(
      firstDayOfQuarter.getFullYear(),
      (quarterNumber - 1) * 3,
      1
    );

    return new FreePeriod(FreePeriodTypeEnum.Quarter, firstDayOfQuarter);
  }

  static createYear(year: number): FreePeriod {
    const firstDayOfYear = new Date();
    firstDayOfYear.setFullYear(year, 0, 1);
    firstDayOfYear.setHours(0, 0, 0, 0);

    return new FreePeriod(FreePeriodTypeEnum.Year, firstDayOfYear);
  }

  static createOfTypeFromDate(periodType: FreePeriodTypeEnum, day: Date): FreePeriod {
    if (periodType === FreePeriodTypeEnum.Day) {
      return FreePeriod.createDay(day.getDate(), day.getMonth() + 1, day.getFullYear());
    } else if (periodType === FreePeriodTypeEnum.Week) {
      return FreePeriod.createWeekFromDate(day);
    } else if (periodType === FreePeriodTypeEnum.Month) {
      return FreePeriod.createMonth(day.getMonth() + 1, day.getFullYear());
    } else if (periodType === FreePeriodTypeEnum.Quarter) {
      return FreePeriod.createQuarterFromDate(day);
    } else if (periodType === FreePeriodTypeEnum.Year) {
      return FreePeriod.createYear(day.getFullYear());
    }
  }

  get isDay(): boolean {
    return this._type === FreePeriodTypeEnum.Day;
  }

  get isWeek(): boolean {
    return this._type === FreePeriodTypeEnum.Week;
  }

  get isMonth(): boolean {
    return this._type === FreePeriodTypeEnum.Month;
  }

  get isQuarter(): boolean {
    return this._type === FreePeriodTypeEnum.Quarter;
  }

  get isYear(): boolean {
    return this._type === FreePeriodTypeEnum.Year;
  }

  equals(value: FreePeriod): boolean {
    return this._type === value._type && this._valueDate.getTime() === value._valueDate.getTime();
  }

  previous(value = 1): FreePeriod {
    return this.navigate(value * -1);
  }

  next(value = 1): FreePeriod {
    return this.navigate(value);
  }

  navigate(value: number): FreePeriod {
    let newPeriod: FreePeriod;
    if (this.isDay) {
      newPeriod = FreePeriod.createDay(
        this._valueDate.getDate() + value,
        this._valueDate.getMonth() + 1,
        this._valueDate.getFullYear()
      );
    } else if (this.isWeek) {
      const nextDay = new Date(this._valueDate);
      nextDay.setDate(this._valueDate.getDate() + (7 * value))
      newPeriod = FreePeriod.createWeekFromDate(nextDay);
    } else if (this.isMonth) {
      newPeriod = FreePeriod.createMonth(this._valueDate.getMonth() + value + 1, this._valueDate.getFullYear());
    } else if (this.isQuarter) {
      const nextDay = new Date(this._valueDate);
      nextDay.setMonth(this._valueDate.getMonth() + (3 * value))
      newPeriod = FreePeriod.createQuarterFromDate(nextDay);
    } else if (this.isYear) {
      newPeriod = FreePeriod.createYear(this._valueDate.getFullYear() + value);
    }

    return newPeriod;
  }

  containsDay(day: Date): boolean {
    if (this.isDay) {
      return day.getDate() === this._valueDate.getDate()
        && day.getMonth() === this._valueDate.getMonth()
        && day.getFullYear() === this._valueDate.getFullYear();
    } else if (this.isWeek) {
      const nextMonday = new Date(this._valueDate);
      nextMonday.setDate(this._valueDate.getDate() + 7);
      const dayTime =  day.getTime();
      return dayTime >= this._valueDate.getTime() && dayTime < nextMonday.getTime();
    } else if (this.isMonth) {
      return day.getMonth() === this._valueDate.getMonth()
        && day.getFullYear() === this._valueDate.getFullYear();
    } else if (this.isQuarter) {
      return FreePeriod.getQuarterNumberFromDate(day) === this.quarterNumber
        && day.getFullYear() === this._valueDate.getFullYear();
    } else if (this.isYear) {
      return day.getFullYear() === this._valueDate.getFullYear();
    }
  }

  private static getQuarterNumberFromDate(date: Date): number {
    return Math.floor(date.getMonth() / 3 + 1);
  }

  private get quarterNumber(): number {
    return FreePeriod.getQuarterNumberFromDate(this._valueDate);
  }
}
