import { Injectable } from '@angular/core';
import { Router, CanActivate, CanLoad } from '@angular/router';
import { Observable } from 'rxjs';
import { take, map } from 'rxjs/operators';
import { TokenStateService } from './token-state.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanLoad {

  constructor(
    private tokenState: TokenStateService,
    private router: Router,
  ) {}


  /**
   * Check if the user is logged in.
   * Navigate to the login page if not logged in.
   *
   * @see CanActivate
   */
  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return this.canUse();
  }

  /**
   * Check if the user is logged in.
   * Navigate to the login page if not logged in.
   *
   * @see CanLoad
   */
  canLoad(): Observable<boolean> | Promise<boolean> | boolean {
    return this.canUse();
  }


  /**
   * Check if the user is logged in.
   * Navigate to the login page if not logged in.
   *
   * @return Observable with true if can load, else false
   */
  private canUse(): Observable<boolean> | Promise<boolean> | boolean | false {
    // Check if the token is stored
    return this.tokenState.isLoggedIn$.pipe(
      take(1),
      map(isLoggedIn => {

        // Not logged in so redirect to login page
        if (!isLoggedIn) {
          this.navigateToLogin();
        }
        return isLoggedIn;
      })
    );
  }

  /**
   * Navigate to the login page.
   */
  private navigateToLogin(): void {
    this.router.navigate(['/mon-compte', 'connecter']);
  }

}
