import {Injectable} from '@angular/core';
import {DrivingLicenceType} from '../models/driving-licence-type.model';

@Injectable({
  providedIn: 'root'
})
export class ColorService {

  getDrivingLicenceTypeClass(type: DrivingLicenceType): string {
    let typeClass = 'driving-licence-b-manual';
    if (type.isB) {
      typeClass = 'driving-licence-b-manual';
    } else if (type.isBVA) {
      typeClass = 'driving-licence-b-auto';
    } else if (type.isA) {
      typeClass = 'driving-licence-a';
    } else if (type.isC) {
      typeClass = 'driving-licence-c';
    } else if (type.isD) {
      typeClass = 'driving-licence-d';
    } else if (type.isOther) {
      typeClass = 'driving-licence-other';
    } else if (type.isBAccompanied) {
      typeClass = 'driving-licence-b-accompanied';
    } else if (type.isBVAAccompanied) {
      typeClass = 'driving-licence-bva-accompanied';
    } else if (type.isBoat) {
      typeClass = 'driving-licence-boat';
    } else if (type.isPap) {
      typeClass = 'driving-licence-pap';
    } else if (type.isTest) {
      typeClass = 'driving-licence-test';
    }

    return typeClass;
  }
}
