<div class="free-date-navigation-input">
  <div class="free-date-navigation-input-controls">
    <ng-container *ngIf="displayShortcut">
      <div class="free-date-navigation-input-shortcut">
        <w-free-button
          buttonType="secondary"
          [label]="shortcutMode === 'today' ? 'Aujourd\'hui' : 'Cette semaine'"
          [isDisabled]="(isDisabled$ | async) || (isShortcutDisabled$ | async)"
          size="small"
          (clickButton)="onClickShortcut()"
        ></w-free-button>
      </div>
    </ng-container>
    <div class="free-date-navigation-input-previous">
      <w-free-button
        [buttonIcon]="iconPrevious"
        buttonType="tertiary"
        [isDisabled]="isDisabled$ | async"
        size="small"
        (clickButton)="onClickPrevious()"
      ></w-free-button>
    </div>
    <div class="free-date-navigation-input-next">
      <w-free-button
        [buttonIcon]="iconNext"
        buttonType="tertiary"
        [isDisabled]="isDisabled$ | async"
        size="small"
        (clickButton)="onClickNext()"
      ></w-free-button>
    </div>
    <div class="free-date-navigation-input-datepicker-trigger">
      <w-free-button
        [buttonIcon]="iconDatepicker"
        buttonType="secondary"
        [isDisabled]="isDisabled$ | async"
        size="small"
        (clickButton)="onClickDatepicker()"
      ></w-free-button>
      <div class="free-date-navigation-input-datepicker"><input #datepicker></div>
    </div>
  </div>
  <ng-container *ngIf="dateFormat === 'month'">
    <div class="free-date-navigation-input-month-name">
      {{ value$ | async | date:'MMMM' | titlecase }}
    </div>
  </ng-container>
  <ng-container *ngIf="dateFormat === 'day'">
    <div class="free-date-navigation-input-day-name">
      {{ value$ | async | date:'EE d MMM' | titlecase }}
    </div>
  </ng-container>
</div>
