import { PaginationRange } from 'src/app/core/utils/pagination/pagination-range.model';
import { PaginationOrder } from 'src/app/core/utils/pagination/pagination-order.model';
import {HttpParams} from '@angular/common/http';
import {HttpParamUriEncoder} from './http-param-uri-encoder';

export class HttpOptions {

  private observe = 'response';

  private _params:
  {
    desc?: string;
    end?: string;
    range?: string;
    sort?: string;
    start?: string;
  } = {};


  setParam(param: string, value: string|number): void {
    this._params[param] = value;
  }

  observeBody(): void {
    this.observe = 'body';
  }


  get http(): object {
    return {
      params: this.params,
      observe: this.observe
    };
  }


  get params(): HttpParams {
    let httpParams = new HttpParams({ encoder: new HttpParamUriEncoder() });
    Object.keys(this._params).forEach(param => {
      if (this._params[param]) {
        httpParams = httpParams.set(param, this._params[param]);
      }
    });
    return httpParams;
  }


  set orderBy(value: PaginationOrder) {
      this._params.sort = value.sortFields;
      if (value.descFields) {
      this._params.desc = value.descFields;
      }
  }

  set range(value: PaginationRange) {
    this._params.range = '' + value.startIndex + '-' + value.endIndex;
  }
}
