import {Pipe, PipeTransform} from '@angular/core';
import {ColorService} from '../../core/utils/color.service';
import {DrivingLicenceType} from '../../core/models/driving-licence-type.model';

@Pipe({
  name: 'drivingLicenceTypeClass'
})
export class DrivingLicenceTypeClassPipe implements PipeTransform {

  constructor(
    private colorService: ColorService
  ) {}

  transform(type: DrivingLicenceType): string {
      return this.colorService.getDrivingLicenceTypeClass(type);
  }
}
