<div class="free-checkbox" [ngClass]="{ 'free-checkbox-invalid': isInvalid }">
  <label class="free-checkbox-label">
    <input
      type="checkbox"
      class="free-checkbox-input"
      [formControl]="inputCtrl"
      (blur)="onInputBlur()"
    >
    <span class="free-checkbox-appearance"></span>
    <span class="free-checkbox-label-text">
      <ng-content></ng-content>
    </span>
  </label>

  <div class="free-form-invalid-text" *ngIf="isInvalid">
    {{ invalidText }}
  </div>
</div>
