import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {Snackbar} from './snackbar.model';
import {SnackbarType} from './snackbar-type.enum';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  static DURATION_DEFAULT = 5000;

  static HIDDEN_SNACKBAR = {
    content: '',
    type: '',
    onClick: null,
    multiline: false,
    duration: 0
  };

  snackbarSrc = new BehaviorSubject<Snackbar>(SnackbarService.HIDDEN_SNACKBAR);


  constructor() { }


  public displaySuccess(
    content: string,
    onClick: () => void = null,
    multiline = false,
    duration = SnackbarService.DURATION_DEFAULT
  ): void {
    this.display(SnackbarType.Succes, content, onClick, multiline, duration);
  }

  public displayError(
    content: string,
    onClick: () => void = null,
    multiline = false,
    duration = SnackbarService.DURATION_DEFAULT
  ): void {
    this.display(SnackbarType.Error, content, onClick, multiline, duration);
  }

  public displayWarning(
    content: string,
    onClick: () => void = null,
    multiline = false,
    duration = SnackbarService.DURATION_DEFAULT
  ): void {
    this.display(SnackbarType.Warning, content, onClick, multiline, duration);
  }

  public hide(): void {
    this.snackbarSrc.next(SnackbarService.HIDDEN_SNACKBAR);
  }


  get snackbar$(): Observable<Snackbar> {
    return this.snackbarSrc.asObservable();
  }


  private display(
    type: SnackbarType,
    content: string,
    onClick: () => void = null,
    multiline = false,
    duration = SnackbarService.DURATION_DEFAULT
  ): void {
    this.snackbarSrc.next({ content, type, onClick, multiline, duration });
  }
}
