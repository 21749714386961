import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component, ElementRef,
  Input, OnChanges, OnDestroy, SimpleChanges, ViewChild,
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {BehaviorSubject} from 'rxjs';
import {map, share, shareReplay} from 'rxjs/operators';
import {faCalendarDay, faChevronLeft, faChevronRight} from '@fortawesome/free-solid-svg-icons';
import AirDatepicker from 'air-datepicker';
import localeFr from 'air-datepicker/locale/fr';
import {DateFormatterService} from '../../../../core/utils/date-formatter.service';

@Component({
  selector: 'w-free-date-navigation-input',
  templateUrl: './free-date-navigation-input.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: FreeDateNavigationInputComponent
    }
  ]
})
export class FreeDateNavigationInputComponent implements AfterViewInit, OnChanges, OnDestroy, ControlValueAccessor {

  static dateNavigationInputCount = 0;

  @Input() dateFormat: 'day' | 'month' = 'month';
  @Input() displayShortcut = true;
  @Input() excludeSunday = false;
  @Input() htmlId = `freeDateNavigationInput${FreeDateNavigationInputComponent.dateNavigationInputCount++}`;
  @Input() incrementNumDays = 1;
  @Input() shortcutMode: 'today' | 'this-week' = 'today';

  @ViewChild('datepicker') datepickerInput: ElementRef<HTMLInputElement>;

  private datepicker: AirDatepicker<HTMLInputElement>;

  readonly iconPrevious = faChevronLeft;
  readonly iconNext = faChevronRight;
  readonly iconDatepicker = faCalendarDay;

  private valueSrc = new BehaviorSubject<Date>(new Date());
  readonly value$ = this.valueSrc.asObservable().pipe(shareReplay(1));
  readonly isShortcutDisabled$ = this.value$.pipe(map(value => {
    const normalizedValue = new Date(value);
    normalizedValue.setHours(0, 0, 0, 0);

    return this.shortcutValue.getTime() === normalizedValue.getTime();
  }));

  private isDisabledSrc = new BehaviorSubject<boolean>(false);
  readonly isDisabled$ = this.isDisabledSrc.asObservable().pipe(share());

  constructor(
    private dateFormatter: DateFormatterService
  ) {}

  ngAfterViewInit(): void {
    const closeBtn = {
      content: 'Fermer',
      onClick: dp => dp.hide()
    };

    this.datepicker = new AirDatepicker<HTMLInputElement>(this.datepickerInput.nativeElement, {
      locale: localeFr,
      autoClose: true,
      toggleSelected: false,
      position: ({ $datepicker, $target, $pointer }) => {
        const coords = $target.getBoundingClientRect();
        const top = coords.y + coords.height + window.scrollY + 8;
        const left = coords.x;
        $datepicker.style.left = `${left}px`;
        $datepicker.style.top = `${top}px`;
        $pointer.style.display = 'none';
      },
      buttons: [closeBtn],
      onSelect: ({ date }) => {
        this.setValue(date as Date, true);
      }
    });
  }

  ngOnChanges(_: SimpleChanges): void {
    this.valueSrc.next(this.valueSrc.value); // Hack to repeat observable emit + isShortcutDisabled$
  }

  ngOnDestroy(): void {
    this.datepicker.destroy();
  }

  writeValue(value: Date|null) {
    const actualValue = value ?? new Date();
    if (this.excludeSunday && value.getDay() === 0) {
      actualValue.setDate(actualValue.getDate() + 1);
    }
    this.valueSrc.next(actualValue);
  }

  onChange: (value: Date) => void = () => {};
  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  onTouched = () => {};
  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  setDisabledState(disabled: boolean) {
    this.isDisabledSrc.next(disabled);
  }

  onClickPrevious(): void {
    this.incrementValue('-');
  }

  onClickNext(): void {
    this.incrementValue('+');
  }

  onClickShortcut(): void {
    this.setValue(this.shortcutValue, true);
  }

  onClickDatepicker(): void {
    if (this.datepicker.visible) {
      this.datepicker.hide();
    } else {
      this.datepicker.selectDate(this.valueSrc.value, { silent: true });
      this.datepicker.setViewDate(this.valueSrc.value);
      this.datepicker.show();
    }
  }

  private get shortcutValue(): Date {
    let value = new Date();
    if (this.shortcutMode === 'this-week') {
      value = this.dateFormatter.getMonday(value);
    }
    value.setHours(0, 0, 0, 0);

    return value;
  }

  private incrementValue(sign: '-' | '+'): void {
    const incrementCoefficient = sign === '-' ? -1 : 1;
    const incrementedValue = new Date(this.valueSrc.value);
    incrementedValue.setDate(incrementedValue.getDate() + (this.incrementNumDays * incrementCoefficient));

    this.setValue(incrementedValue);
  }

  private setValue(value: Date, strictSelection = false): void {
    if (this.datepicker.visible) {
      this.datepicker.hide();
    }

    const actualValue = new Date(value);
    if (this.excludeSunday) {

      // Strict selection = click in datepicker => if sunday, go monday
      if (strictSelection) {
        actualValue.setDate(actualValue.getDate() + (actualValue.getDay() === 0 ? 1 : 0));

        // If increment contains sunday, go +1 or -1
      } else if (this.incrementNumDays < 6) {
        const isForward = value > this.valueSrc.value;
        if (isForward && value.getDay() <= this.valueSrc.value.getDay()) {
          actualValue.setDate(actualValue.getDate() + 1);
        } else if (!isForward && (value.getDay() >= this.valueSrc.value.getDay() || value.getDay() === 0)) {
          actualValue.setDate(actualValue.getDate() - 1);
        }
      }
    }

    this.valueSrc.next(actualValue);
    this.onChange(this.valueSrc.value);
  }
}
