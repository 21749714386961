import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {ControlValueAccessor, UntypedFormBuilder, UntypedFormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {BehaviorSubject, merge, Subscription} from 'rxjs';
import {FreeNumberInputComponent} from '../free-number-input/free-number-input.component';
import {Duration} from '../../../../core/models/shared/duration.model';

@Component({
  selector: 'w-free-duration-input',
  templateUrl: './free-duration-input.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: FreeDurationInputComponent
    }
  ]
})
export class FreeDurationInputComponent implements OnInit, OnChanges, OnDestroy, ControlValueAccessor {

  static durationInputCount = 0;

  @Input() fillMinutes = true;
  @Input() helperText = '';
  @Input() htmlId = `freeDurationInput${FreeDurationInputComponent.durationInputCount++}`;
  @Input() inputName = 'freeDurationInputName';
  @Input() invalidText = 'Invalid field value';
  @Input() isAutocomplete = true;
  @Input() isInvalid = false;
  @Input() label = '';
  @Input() max: Duration|null = null;
  @Input() min: Duration|null = null;
  @Input() size: 'small' | 'medium' | 'large' = 'medium';

  @Output() focusInput = new EventEmitter<void>();
  @Output() touched = new EventEmitter<void>();

  @ViewChild('hoursNumberInput') hoursNumberInput: FreeNumberInputComponent;
  @ViewChild('minutesNumberInput') minutesNumberInput: FreeNumberInputComponent;

  hoursInputCtrl: UntypedFormControl;
  minutesInputCtrl: UntypedFormControl;

  private hasLabelSrc = new BehaviorSubject<boolean>(this.hasLabel);
  readonly hasLabel$ = this.hasLabelSrc.asObservable();

  private hasHelperTextSrc = new BehaviorSubject<boolean>(this.hasHelperText);
  readonly hasHelperText$ = this.hasHelperTextSrc.asObservable();

  private valueMaxHoursSrc = new BehaviorSubject<number|null>(this.valueMaxHours);
  readonly valueMaxHours$ = this.valueMaxHoursSrc.asObservable();
  private valueMinHoursSrc = new BehaviorSubject<number|null>(this.valueMinHours);
  readonly valueMinHours$ = this.valueMinHoursSrc.asObservable();

  private valueMaxMinutesSrc = new BehaviorSubject<number|null>(this.valueMaxMinutes);
  readonly valueMaxMinutes$ = this.valueMaxMinutesSrc.asObservable();
  private valueMinMinutesSrc = new BehaviorSubject<number|null>(this.valueMinMinutes);
  readonly valueMinMinutes$ = this.valueMinMinutesSrc.asObservable();

  private valueChangesSub: Subscription;

  constructor(
    private fb: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.initControls();
  }

  ngOnChanges(_: SimpleChanges): void {
    this.handleState();
  }

  ngOnDestroy(): void {
    this.valueChangesSub.unsubscribe();
  }

  private get hasLabel(): boolean {
    return this.label !== null && this.label !== '';
  }

  private get hasHelperText(): boolean {
    return this.helperText !== null && this.helperText !== '';
  }

  private get valueMaxHours(): number|null {
    return this.max !== null ? this.max.hours : null;
  }

  private get valueMinHours(): number|null {
    return this.min !== null ? this.min.hours : null;
  }

  private get valueMaxMinutes(): number|null {
    return this.max !== null ?
      this.max.valueInSeconds > 60 ? 60 : this.max.valueInSeconds
      : null;
  }

  private get valueMinMinutes(): number|null {
    return this.min !== null ?
      this.min.valueInSeconds > 60 ? 0 : this.min.valueInSeconds
      : null;
  }

  writeValue(value: Duration | null) {
    const hoursValue = value !== null ? value.hours : null;
    this.hoursInputCtrl.setValue(hoursValue, { emitEvent: false });

    const minutesValue = value !== null ? value.minutes : null;
    this.minutesInputCtrl.setValue(minutesValue, { emitEvent: false });
  }

  onChange: (value: Duration | null) => void = () => {};
  registerOnChange(onChange: any) {
    this.onChange = onChange;

    this.observeInputs();
  }

  onTouched = () => {};
  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  setDisabledState(disabled: boolean) {
    if (disabled) {
      this.hoursInputCtrl.disable();
      this.minutesInputCtrl.disable();
    } else {
      this.hoursInputCtrl.enable();
      this.minutesInputCtrl.enable();
    }
  }

  onInputFocus(): void {
    this.focusInput.emit();
  }

  onInputTouched(): void {
    this.onTouched();
  }

  private handleState(): void {
    this.hasLabelSrc.next(this.hasLabel);
    this.hasHelperTextSrc.next(this.hasHelperText);
    this.valueMaxHoursSrc.next(this.valueMaxHours);
    this.valueMinHoursSrc.next(this.valueMinHours);
    this.valueMaxMinutesSrc.next(this.valueMaxMinutes);
    this.valueMinMinutesSrc.next(this.valueMinMinutes);
  }

  private initControls(): void {
    this.hoursInputCtrl = this.fb.control(null);
    this.minutesInputCtrl = this.fb.control(null);
  }

  private observeInputs(): void {
    this.valueChangesSub = merge(
      this.hoursInputCtrl.valueChanges,
      this.minutesInputCtrl.valueChanges
    ).subscribe(_ => {
      const hours = this.hoursInputCtrl.value;
      const minutes = this.fillMinutes ? this.minutesInputCtrl.value : 0;

      this.onChange(hours !== null && minutes !== null ? Duration.fromHoursAndMinutes(hours, minutes) : null);
    });
  }
}
