<div
  class="free-text-input free-text-input-{{ size }}"
  [ngClass]="{ 'free-text-input-invalid': isInvalid }"
>
  <label class="free-form-label" for="{{ htmlId }}" *ngIf="hasLabel">
    {{ label }}
  </label>

  <div
    class="free-text-input-box free-text-input-box-{{ altitude }} free-text-input-box-{{ radius }}"
    [ngClass]="{
      'free-text-input-box-multi': isMultiline,
      'free-text-input-box-leading-icon': hasLeadingIcon,
      'free-text-input-box-trailing-icon': hasTrailingIcon,
      'free-text-input-box-trailing-text': hasTrailingText
    }"
  >
    <span class="free-text-input-leading-icon free-icon" *ngIf="hasLeadingIcon">
      <fa-icon [icon]="leadingIcon"></fa-icon>
    </span>
    <input
      #textInputSingle
      id="{{ htmlId }}"
      *ngIf="!isMultiline"
      [attr.maxlength]="maxLength"
      [type]="inputType"
      [placeholder]="placeholder"
      [name]="inputName"
      [formControl]="inputCtrl"
      [autocomplete]="isAutocomplete ? 'on' : 'off'"
      (blur)="onInputBlur()"
      (focus)="onInputFocus()"
    >
    <textarea
      #textInputMulti
      id="{{ htmlId }}"
      *ngIf="isMultiline"
      [attr.maxlength]="maxLength"
      [placeholder]="placeholder"
      [name]="inputName"
      [formControl]="inputCtrl"
      (blur)="onInputBlur()"
      (focus)="onInputFocus()"
    ></textarea>
    <span class="free-text-input-trailing-icon free-icon" *ngIf="hasTrailingIcon">
      <fa-icon [icon]="trailingIcon"></fa-icon>
    </span>
    <span class="free-text-input-trailing-text" *ngIf="hasTrailingText">{{ trailingText }}</span>
  </div>

  <div class="free-form-helper-text" *ngIf="hasHelperText">
    {{ helperText }}
  </div>

  <div class="free-form-invalid-text" *ngIf="isInvalid && invalidText !== ''">
    {{ invalidText }}
  </div>
</div>
