<div class="dropdown-container select-driving-licence-type-groups-dropdown">
  <div
    class="dropdown-toggle"
    [ngClass]="{ 'dropdown-toggle-disabled': isDisabled }"
    (click)="!isDisabled ? toggle() : null"
    wClickOutside (clickOutside)="close()"
  >
    <div class="selected-groups">
      {{ displayGroups }}
      <span class="icon"><fa-icon [icon]="iconArrow"></fa-icon></span>
    </div>
    <div class="dropdown-menu" *ngIf="isVisible">
      <div class="dropdown-group" (click)="onClickAll(); $event.stopPropagation()">Tous les permis</div>
      <div class="dropdown-group"
        *ngFor="let group of groups"
        (mouseover)="onHoverGroup(group)"
        (mouseout)="onUnhoverGroup()"
        (click)="isSelected(group) ? unselectItem(group) : selectItem(group); $event.stopPropagation()"
      >{{ group.name }}
        <span class="icon" *ngIf="isSelected(group) && !isHovered(group)">
          <fa-icon [icon]="iconSelected"></fa-icon>
        </span>
        <span class="icon" *ngIf="isSelected(group) && isHovered(group)" >
          <fa-icon [icon]="iconUnselect"></fa-icon>
        </span>
      </div>
    </div>
  </div>
</div>
