<div class="confirm-modal modal">
  <div class="modal-content panel">
    <div class="body">
      <ng-content></ng-content>

      <div class="modal-actions">
        <button type="button" class="cancel" [disabled]="isDisabeld" (click)="onClickCancel()">{{ cancelButtonText }}</button>
        <button type="button" class="primary" [disabled]="isDisabeld" (click)="onClickConfirm()">{{ confirmButtonText }}</button>
      </div>
    </div>
  </div>

  <div class="modal-background"></div>
</div>
