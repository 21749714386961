<nav class="day-navigator">
  <ng-container *ngIf="isDisplayedShortcut">
    <a routerLink="{{ urlPrefix }}/aujourdhui" routerLinkActive="active" class="day-navigator-shortcut btn">
      Aujourd'hui
    </a>
  </ng-container>

  <a routerLink="{{ urlPrefix }}/{{ previous | date:'yyyy-MM-dd'}}">
    <span class="icon primary"><fa-icon [icon]="iconPrevious"></fa-icon></span>
  </a>
  <a routerLink="{{ urlPrefix }}/{{ next | date:'yyyy-MM-dd'}}">
    <span class="icon primary"><fa-icon [icon]="iconNext"></fa-icon></span>
  </a>

  <div class="day-wrapper">
    <strong
      class="day"
      (click)="onClickDay()"
    >
      {{ day | date:'EE d MMMM yy' | titlecase }}
    </strong>

    <div class="week-navigator-picker">
      <input #datepicker>
    </div>
  </div>
</nav>
