<div class="free-distance-input">
  <w-free-number-input
    #numberInput
    [formControl]="inputCtrl"
    [helperText]="helperText"
    [htmlId]="htmlId"
    [inputName]="inputName"
    [invalidText]="invalidText"
    [isAutocomplete]="isAutocomplete"
    [isInvalid]="isInvalid"
    [label]="label"
    [max]="valueMax$ | async"
    [min]="valueMin$ | async"
    [placeholder]="placeholder"
    [size]="size"
    [step]="1"
    trailingText="km"
    (touched)="onInputTouched()"
    (focusInput)="onInputFocus()"
  ></w-free-number-input>
</div>
