import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { TokenClaims } from '../models/token-claims.model';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class TokenStateService {

  static STORAGE_TOKEN_KEY = 'KLAXO_TRAINER_AUTH';

  private tokenSrc = new BehaviorSubject<string | null>(this.storedToken$);
  readonly tokenClaims$ = this.tokenSrc.asObservable().pipe(
    map(token => this.getClaims(token)),
    shareReplay(1)
  );
  readonly isLoggedIn$ = this.tokenClaims$.pipe(
    map(claims => this.isClaimsValid(claims))
  );
  readonly currentDrivingSchoolId$ = this.tokenClaims$.pipe(
    map(claims => claims.dsId),
    shareReplay(1)
  );

  constructor(
    private router: Router,
  ) {
    this.tokenSrc.next(this.getStoredToken());
  }

  getStoredToken(): string {
    return localStorage.getItem(TokenStateService.STORAGE_TOKEN_KEY);
  }

  get token(): string | null {
    return this.getToken();
  }

  getToken(): string | null {
    return this.tokenSrc.getValue();
  }

  hasToken(): boolean {
    return !!this.tokenSrc.getValue();
  }

  get storedToken$(): string | null {
    return localStorage.getItem(TokenStateService.STORAGE_TOKEN_KEY);
  }

  updateToken(token: string | null): void {
    this.tokenSrc.next(token);
    if (null === token) {
      this.deleteToken();
    } else {
      this.saveToken();
    }
  }

  logout(): void {
    this.updateToken(null);
    this.router.navigate(['/mon-compte/connecter']);
  }

  private saveToken(): void {
    localStorage.setItem(TokenStateService.STORAGE_TOKEN_KEY, this.tokenSrc.value);
  }

  private deleteToken(): void {
    localStorage.removeItem(TokenStateService.STORAGE_TOKEN_KEY);
  }

  private getClaims(storedToken: string | null): TokenClaims {
    if (null === storedToken) {
      return {} as TokenClaims;
    }

    const token = storedToken.split('.')[1];

    try {
      return JSON.parse(atob(token));
    } catch (DOMException) {
      return {} as TokenClaims;
    }
  }

  private isClaimsValid(claims: TokenClaims): boolean {
    return !!claims && !!claims.exp;
  }
}
