<div class="free-price-input">
  <w-free-number-input
    #numberInput
    [formControl]="inputCtrl"
    [helperText]="helperText"
    [htmlId]="htmlId"
    [inputName]="inputName"
    [invalidText]="invalidText"
    [isAutocomplete]="isAutocomplete"
    [isInvalid]="isInvalid"
    [label]="label"
    [max]="max"
    [min]="min"
    [placeholder]="placeholder"
    [size]="size"
    [step]="step"
    [trailingIcon]="iconCurrency"
    (touched)="onInputTouched()"
    (focusInput)="onInputFocus()"
  ></w-free-number-input>
</div>
