import { Component, OnInit, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { AuthService } from 'src/app/core/auth/auth.service';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { FormBuilder, FormControl } from '@angular/forms';
import { ApiListDrivingSchoolsService } from '../../../http/api-services/api-list-driving-schools.service';
import { filter, switchMap } from 'rxjs/operators';

@Component({
  selector: 'w-user-widget',
  templateUrl: './user-widget.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserWidgetComponent implements OnInit, OnDestroy {

  isLoggedIn$ = this.auth.isLoggedIn$;

  private linksSrc = new BehaviorSubject<{ value: string, label: string, type: string }[]>([]);
  readonly links$ = this.linksSrc.asObservable();

  private linksDrivingSchoolsSrc = new BehaviorSubject<{ value: string, label: string, type: string }[]>([]);
  readonly linksDrivingSchools$ = this.linksDrivingSchoolsSrc.asObservable();

  disabledLinks = { EMPTY: false };

  selectCtrl: FormControl<string>;

  private listSub: Subscription;
  private linksSub: Subscription;

  constructor(
    private auth: AuthService,
    private router: Router,
    private fb: FormBuilder,
    private apiDrivingSchoolsRepo: ApiListDrivingSchoolsService
  ) {
  }

  ngOnInit(): void {
    this.initControl();
    this.observeDrivingSchoolList();
    this.observeActions();
  }

  ngOnDestroy(): void {
    this.listSub?.unsubscribe();
    this.linksSub?.unsubscribe();
  }

  onSelectValue(): void {
    const selected = this.linksSrc.value.find(n => n.value === this.selectCtrl.value);
    switch (selected?.type) {
      case 'SIGNOUT':
        this.signOut();
        break;
      case 'URL':
        this.router.navigate([selected.value]);
        break;
      case 'SWITCH_DS':
        this.auth.changeCurrentDrivingSchool(Number(selected.value));
        break;
    }
    this.selectCtrl.setValue('');
  }

  signOut(): void {
    this.auth.logout();
  }

  private initControl(): void {
    this.selectCtrl = this.fb.control('');
  }

  private observeActions(): void {
    const actions = [
      { label: 'Me Déconnecter', value: 'signout', type: 'SIGNOUT' }
    ];

    this.linksSub = this.linksDrivingSchools$.subscribe(drivingSchools => {
      this.linksSrc.next(actions.concat(drivingSchools));
    });
  }

  private observeDrivingSchoolList(): void {
    this.listSub = this.auth.isLoggedIn$
      .pipe(
        filter(isLoggedIn => isLoggedIn),
        switchMap(() => {
          return combineLatest([
            this.auth.currentDrivingSchoolId$,
            this.apiDrivingSchoolsRepo.listMyDrivingSchools$()
          ]);
        })
      ).subscribe(([currentDrivingSchoolId, drivingSchools]): void => {
        currentDrivingSchoolId = Number(currentDrivingSchoolId);
        const drivingSchoolsMenu = [];
        if (drivingSchools.length > 1) {
          drivingSchoolsMenu.push({ label: 'Changer de compte', value: 'EMPTY', type: 'EMPTY' });
          drivingSchools.forEach(drivingSchool => {
            let prefix = '';
            let actionType = '';

            if (drivingSchool.id === currentDrivingSchoolId) {
              prefix = '✔ ';
            } else {
              actionType = 'SWITCH_DS';
            }
            drivingSchoolsMenu.push({
              label: prefix + drivingSchool.name,
              value: drivingSchool.id.toString(),
              type: actionType
            });
          });
        }
        this.linksDrivingSchoolsSrc.next(drivingSchoolsMenu);
      });
  }
}
