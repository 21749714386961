export class PostalAddress {

  address?: string;
  city?: string;
  streetNumber?: number;
  streetExtension?: string = null;
  zipCode?: string;

  constructor(
    address: string = null,
    zipCode: string = null,
    city: string = null,
    streetNumber: number = null,
    streetExtension: string = null
  ) {
    this.address = address;
    this.city = city;
    this.streetNumber = streetNumber;
    if (streetExtension !== '') {
      this.streetExtension = streetExtension;
    }
    this.zipCode = zipCode;
  }

  get hasStreetNumber(): boolean {
    return !!this.streetNumber;
  }

  get hasStreetExtension(): boolean {
    return !!this.streetExtension;
  }

  get hasAddress(): boolean {
    return !!this.address && this.address.trim().length > 0;
  }

  get fullAddress(): string {
    let inline = '';
    if (this.hasStreetNumber) {
      inline += this.streetNumber + ' ';
      if (this.hasStreetExtension) {
        inline += this.streetExtension + ' ';
      }
    }
    if (this.hasAddress) {
      inline += this.address;
    }
    return inline;
  }

  get hasCityInfo(): boolean {
    return this.fullCity.trim().length > 0;
  }

  get displayInline(): string {
    let inline = '';
    if (this.hasStreetNumber) {
      inline += this.streetNumber;
      if (this.hasStreetExtension) {
        inline += ' ' + this.streetExtension;
      }
      inline += ' ';
    }
    if (this.hasAddress) {
      inline += this.address;
    }
    const fullCity = this.fullCity;
    if (!!fullCity) {
      inline += ', ' + fullCity;
    }
    return inline;
  }

  get fullCity(): string {
    const full = [];
    if (!!this.zipCode) {
      full.push(this.zipCode);
    }
    if (!!this.city) {
      full.push(this.city);
    }

    return full.join(' ');
  }
}
