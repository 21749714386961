import { Pipe, PipeTransform } from '@angular/core';
import { PostalAddress } from '../../core/models/shared/postal-address.model';

@Pipe({
  name: 'inlineAddress'
})
export class InlineAddressPipe implements PipeTransform {

  transform(address: PostalAddress): string {
    return address.displayInline;
  }
}
