import { DrivingLessonTypeEnum } from './driving-lesson-type.enum';

export class DrivingLessonType {

  static readonly DIGITAL_BOOK_SUPPORTED = [
    DrivingLessonTypeEnum.TRACK,
    DrivingLessonTypeEnum.WITHOUT_TRAFFIC,
    DrivingLessonTypeEnum.CODE,
    DrivingLessonTypeEnum.THEORY,
    DrivingLessonTypeEnum.WITH_TRAFFIC
  ];


  id: number;
  isCode: boolean;
  isDrivingTest: boolean;
  isEvaluation: boolean;
  isListening: boolean;
  isTraining: boolean;
  isTrainingLesson: boolean;
  isTrainingLessonShared: boolean;
  isMockExam: boolean;
  name: string;
  shortName: string;

  get isGroupDigitalBookSupported(): boolean {
    return DrivingLessonType.DIGITAL_BOOK_SUPPORTED.some(n => n === this.id);
  }

  get isTraffic(): boolean {
    return this.id === DrivingLessonTypeEnum.WITH_TRAFFIC;
  }
}
