import { Directive, HostListener } from "@angular/core";

@Directive({
    selector: "[wClickPreventDefault]"
})
export class ClickPreventDefaultDirective {

    /** LISTENERS */

    @HostListener("click", ["$event"])
    public onClick(event: any) {
        event.preventDefault();
    }
}