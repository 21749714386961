<div class="free-dropdown free-dropdown-{{ size }}" [ngClass]="{ 'free-dropdown-invalid': isInvalid }">
  <label class="free-form-label" for="{{ htmlId }}" *ngIf="hasLabel">{{ label }}</label>

  <div
    class="free-dropdown-list-box"
    [ngClass]="{
      'free-dropdown-multiselect': isMultiselect,
      'free-dropdown-selected': hasSelection,
      'free-dropdown-list-box-expanded': isCollapsed$ | async
    }"
    wClickOutside (clickOutside)="onClickOutsideList()"
  >
    <button
      class="{{ listBoxFieldCssClass }}"
      [disabled]="isDisabled$ | async"
      id="{{ htmlId }}"
      type="button"
      (click)="onClickField()"
    >
      <span
        class="free-dropdown-list-box-label free-btn-label"
        [ngClass]="{ 'free-dropdown-list-box-placeholder': this.isLabelPlaceholder }"
        *ngIf="!disableListBoxLabel"
      >
        {{ listBoxLabel }}
      </span>
      <span
        class="free-dropdown-list-box-label free-btn-label free-dropdown-list-box-placeholder"
        *ngIf="disableListBoxLabel && !isButtonIcon"
      >
        {{ placeholder }}
      </span>
      <span class="free-icon" *ngIf="isButtonIcon">
        <fa-icon [icon]="buttonIcon"></fa-icon>
      </span>

      <span class="free-dropdown-list-box-menu-icon free-icon" *ngIf="!disableChevron">
        <fa-icon [icon]="iconFlatten" *ngIf="isCollapsed$ | async"></fa-icon>
        <fa-icon [icon]="iconCollapse" *ngIf="isFlattened"></fa-icon>
      </span>
    </button>

    <div class="free-dropdown-list free-dropdown-list-{{ directionX }}" *ngIf="isCollapsed$ | async">
      <ng-container *ngFor="let item of items; let i = index">
        <div
          class="free-dropdown-list-item"
          [ngClass]="{ 'free-dropdown-list-item-selected': isItemSelected(item) }"
          (click)="onClickItem(item); $event.stopPropagation()"
          *ngIf="isItemClickable(item)"
          wClickPreventDefault
        >
          <div class="free-dropdown-list-item-single">
            <span *ngIf="!isMultiselect" class="free-dropdown-list-item-single-label">{{ item[itemLabelKey] }}</span>
          </div>

          <w-free-checkbox *ngIf="isMultiselect" [formControl]="getCheckboxCtrl(i)">
            {{ item[itemLabelKey] }}
          </w-free-checkbox>
        </div>

        <div
          class="free-dropdown-list-item free-dropdown-list-item-disabled"
          *ngIf="!isItemClickable(item)"
        >
          <div class="free-dropdown-list-item-single">
            <span class="free-dropdown-list-item-single-label">{{ item[itemLabelKey] }}</span>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="free-form-helper-text" *ngIf="hasHelperText">
    {{ helperText }}
  </div>

  <div class="free-form-invalid-text" *ngIf="isInvalid && invalidText !== ''">
    {{ invalidText }}
  </div>
</div>
