import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import { HttpResponseService } from '../../utils/http/http-response.service';
import { HttpOptionsService } from '../../utils/http/http-options.service';
import { Student } from 'src/app/core/models/student.model';
import { PaginationRange } from '../../utils/pagination/pagination-range.model';
import { PaginationResult } from '../../utils/pagination/pagination-result.model';
import {ConfigService} from '../../utils/config.service';
import {ApiStudent} from '../api-responses/api-student.model';
import {StudentAssemblerService} from '../assemblers/student-assembler.service';
import {ApiStudentSummary} from '../api-responses/api-student-summary.model';
import {SearchStudentFilters} from '../../../shared/component/search-student-input/search-student-filters.model';
import {ApiStudentSchedulingCredit} from '../api-responses/api-student-scheduling-credit.model';
import {ApiStudentDrivingSkillProgress} from '../api-responses/api-student-driving-skill-progress.model';
import { ApiStudentOnlineEvaluationResponse } from '../api-responses/api-student-online-evaluation-response.model';

@Injectable({
  providedIn: 'root'
})
export class ApiStudentService {

  constructor(
    private config: ConfigService,
    private http: HttpClient,
    private httpOptions: HttpOptionsService,
    private httpResponse: HttpResponseService,
    private studentAssembler: StudentAssemblerService
  ) {}

  listStudents$(
    query = '',
    range = new PaginationRange()
  ): Observable<PaginationResult<ApiStudentSummary>> {
    const httpOptions = this.httpOptions.getOptions(range);
    httpOptions.setParam('q', query);

    return this.http.get(
      `${this.config.apiUrl}/driving-trainer/students-summaries`,
      httpOptions.http
    ).pipe(map((response: any) => {
      const students = new PaginationResult<ApiStudentSummary>();
      students.items = response.body as ApiStudentSummary[];
      students.total = this.httpResponse.getPaginationTotalFromHeaders(response.headers);
      return students;
    }));
  }

  getStudent$(studentId: number): Observable<ApiStudentSummary> {
    return this.http.get<ApiStudentSummary>(
      `${this.config.apiUrl}/driving-trainer/students/${studentId}`
    );
  }

  searchStudents$(
    filters: SearchStudentFilters,
    range = new PaginationRange()
  ): Observable<PaginationResult<Student>> {
    const httpOptions = this.httpOptions.getOptions(range);
    httpOptions.setParam('q', filters.query);
    if (!!filters.groups && filters.groups.length > 0) {
      httpOptions.setParam('groups', filters.groups.map(group => group.id).join(','));
    }

    return this.http.get(`${this.config.apiUrl}/driving-trainer/students`, httpOptions.http).pipe(
      map((response: any) => {
        const students = new PaginationResult<Student>();
        students.items = response.body.map((raw: ApiStudent) => this.studentAssembler.toModel(raw));
        students.total = this.httpResponse.getPaginationTotalFromHeaders(response.headers);

        return students;
      })
    );
  }

  getStudentSchedulingCredits$(studentId: number): Observable<ApiStudentSchedulingCredit[]> {
    return this.http.get<ApiStudentSchedulingCredit[]>(
      `${this.config.apiUrl}/driving-trainer/students/${studentId}/scheduling-credits`
    );
  }

  getStudentDrivingSkills$(student: Student): Observable<ApiStudentDrivingSkillProgress[]> {
    return this.http.get<ApiStudentDrivingSkillProgress[]>(`${this.config.apiUrl}/driving-trainer/students/${student.id}/driving-skills`);
  }

  getStudentEvaluationResponse$(studentId: number): Observable<ApiStudentOnlineEvaluationResponse> {
    return this.http.get<ApiStudentOnlineEvaluationResponse>(`${this.config.apiUrl}/driving-trainer/students/${studentId}/evaluation/response`);
  }
}
