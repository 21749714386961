<nav id="navigationBar">

  <!-- TODO TMP: CUSTOM COLORS -->
  <div *ngIf="styles !== null" [innerHTML]="getStyles()"></div>

  <a class="navbar-brand" routerLink="/">
    <img
      src="assets/images/klaxo_logo_horizontal_blanc_transparent_60.png"
      alt="Logo Klaxo"
      class="navbar-brand-h"
    >
    <img
      src="assets/images/klaxo_logo_carre_transparent_60.png"
      alt="Logo Klaxo"
      class="navbar-brand-square"
    >
  </a>

  <div class="nav-spacer"></div>

  <div [ngClass]="{ menu: true, collapsed: isDisplayedMenu }" wClickOutside (clickOutside)="closeMenu()">
    <button type="button" class="link nav-link menu-toggle" (click)="toggleMenu($event)">
      <span class="icon menu-open" *ngIf="!isDisplayedMenu"><fa-icon [icon]="iconMenu"></fa-icon></span>
    </button>

    <div class="menu-content">
      <button type="button" class="nav-link icon-group menu-close" *ngIf="isDisplayedMenu" (click)="onClickCloseMenu()">
        <span class="icon-group-prepend"><fa-icon [icon]="iconClose"></fa-icon></span>
        Fermer
      </button>

      <a routerLink="/mon-compte/connecter" class="nav-link nav-login" *ngIf="!isLoggedIn">Se connecter</a>

      <ng-container *ngIf="isLoggedIn">
        <a routerLink="/planning" routerLinkActive="active" class="nav-link">Planning</a>
        <a routerLink="/eleves" routerLinkActive="active" class="nav-link">Elèves</a>
      </ng-container>
    </div>
  </div>

  <div class="nav-user-widget"><w-user-widget></w-user-widget></div>
</nav>
