import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, iif, Observable, of, Subscription } from 'rxjs';
import { ConfigService } from '../../utils/config.service';
import { HttpClient } from '@angular/common/http';
import { map, switchMap, tap } from 'rxjs/operators';
import { ApiListDrivingSchools } from '../api-responses/api-list-driving-schools.model';

@Injectable({
  providedIn: 'root'
})
export class ApiListDrivingSchoolsService implements OnDestroy {

  private drivingSchoolsSrc = new BehaviorSubject<ApiListDrivingSchools[]>([]);
  private pDrivingSchools$: Observable<ApiListDrivingSchools[]> = this.drivingSchoolsSrc.asObservable();

  private listDrivingSchoolsSub: Subscription;
  private loginSub: Subscription;
  private logoutSub: Subscription;

  constructor(
    private config: ConfigService,
    private http: HttpClient,
  ) {}

  ngOnDestroy(): void {
    this.listDrivingSchoolsSub?.unsubscribe();
    this.loginSub.unsubscribe();
    this.logoutSub.unsubscribe();
  }

  listMyDrivingSchools$(): Observable<ApiListDrivingSchools[]> {
    return this.http.get<ApiListDrivingSchools[]>(
      this.config.apiUrl + '/driving-trainer/driving-schools',
    ).pipe(
      tap(drivingSchools => this.drivingSchoolsSrc.next(drivingSchools)),
    );
  }

  getFirstDrivingSchoolId$(): Observable<number> {
    return this.drivingSchools$.pipe(
      map(drivingSchools => drivingSchools[0]?.id)
    );
  }

  get drivingSchools$(): Observable<ApiListDrivingSchools[]> {
    return this.pDrivingSchools$.pipe(
      switchMap(drivingSchools => iif(
        () => drivingSchools.length === 0,
        this.listMyDrivingSchools$(),
        of(drivingSchools))
      )
    );
  }

  clearDrivingSchools(): void {
    this.drivingSchoolsSrc.next([]);
  }
}
