import {ChangeDetectionStrategy, Component} from '@angular/core';

@Component({
  selector: 'w-free-box',
  templateUrl: './free-box.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FreeBoxComponent {

  constructor() {}

}
