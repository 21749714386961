import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {BehaviorSubject, Subject, Subscription} from 'rxjs';
import {DrivingLicenceTypeSelectItem} from './driving-licence-type-select-item.model';
import {faChevronDown, faChevronUp} from '@fortawesome/free-solid-svg-icons';
import {debounceTime, filter, map, share, shareReplay, take, tap} from 'rxjs/operators';
import { ApiRefService } from '../../../../core/http/api-services/api-ref.service';
import { DrivingLicenceType } from '../../../../core/models/driving-licence-type.model';
import { DrivingLicenceTypeGroup } from '../../../../core/models/driving-licence-type-group.model';

@Component({
  selector: 'w-train-driving-licence-type-select',
  templateUrl: './train-driving-licence-type-select.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: TrainDrivingLicenceTypeSelectComponent
    }
  ]
})
export class TrainDrivingLicenceTypeSelectComponent implements OnInit, OnDestroy, ControlValueAccessor {

  static drivingLicenceTypeSelectCount = 0;

  @Input() altitude: 'flat' | 'high' = 'flat';
  @Input() directionX: 'left' | 'right' = 'right';
  @Input() fieldType: 'button' | 'select' = 'button';
  @Input() helperText = '';
  @Input() htmlId = `trainDrivingLicenceTypeSelect${TrainDrivingLicenceTypeSelectComponent.drivingLicenceTypeSelectCount++}`;
  @Input() invalidText = 'Invalid field value';
  @Input() isInvalid = false;
  @Input() label = '';
  @Input() placeholder = 'Driving licence type';
  @Input() size: 'small' | 'medium' | 'large' = 'medium';

  @Output() closeMenu = new EventEmitter<void>();

  iconCollapse = faChevronDown;
  iconFlatten = faChevronUp;

  boxLabelPlural = {};

  private isCollapsedSrc = new BehaviorSubject<boolean>(false);
  readonly isCollapsed$ = this.isCollapsedSrc.asObservable().pipe(share());

  private itemsSrc = new BehaviorSubject<DrivingLicenceTypeSelectItem[]>([]);
  readonly items$ = this.itemsSrc.asObservable().pipe(shareReplay(1));
  readonly itemsGroups$ = this.items$.pipe(map(items => {
    const groups: { [key: string]: DrivingLicenceTypeSelectItem[] } = {};
    items.forEach(item => {
      const groupName = this.isGroupOther(item.value.group) ? 'OTHER' : item.value.group.name;
      if (!groups[groupName]) {
        groups[groupName] = [];
      }
      groups[groupName].push(item);
    });
    return groups;
  }));
  readonly numItemsSelected$ = this.items$.pipe(map(items => items.filter(item => item.isSelected).length));

  private itemsLoadedSrc = new BehaviorSubject<boolean>(false);
  readonly itemsLoaded$ = this.itemsLoadedSrc.asObservable();

  private isDisabledSrc = new BehaviorSubject<boolean>(false);
  readonly isDisabled$ = this.isDisabledSrc.asObservable();

  private clickFieldSrc = new Subject<void>();
  private clickFieldSub: Subscription;

  private loadSub: Subscription;

  constructor(
    private refRepo: ApiRefService
  ) {}

  ngOnInit(): void {
    this.loadDrivingLicenceTypes();
    this.debounceClickField();

    this.boxLabelPlural = {
      '=0': this.placeholder,
      '=1': '1 permis sélectionné',
      other: '# permis sélectionnés'
    };
  }

  ngOnDestroy(): void {
    this.clickFieldSub.unsubscribe();
    this.loadSub.unsubscribe();
  }

  writeValue(value: DrivingLicenceType[]) {
    this.itemsLoaded$.pipe(filter(isLoaded => isLoaded), take(1)).subscribe(_ => {
      const initializedItems = this.itemsSrc.value.map(drivingLicenceType => {
        drivingLicenceType.isSelected = value.some(selected => selected.id === drivingLicenceType.value.id);
        return drivingLicenceType;
      });
      this.itemsSrc.next(initializedItems);
    });
  }

  onChange: (value: DrivingLicenceType[]) => void = () => {};
  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  onTouched = () => {};
  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  setDisabledState(disabled: boolean) {
    if (disabled) {
      this.isDisabledSrc.next(true);
    } else {
      this.isDisabledSrc.next(false);
    }
  }

  onClickField(): void {
    this.clickFieldSrc.next();
    this.onTouched();
  }

  onClickItem(clickedItem: DrivingLicenceTypeSelectItem): void {
    const items = this.itemsSrc.value.map(drivingLicenceType => {
      drivingLicenceType.isSelected = clickedItem.value.id === drivingLicenceType.value.id
        ? !drivingLicenceType.isSelected
        : drivingLicenceType.isSelected;
      return drivingLicenceType;
    });
    this.itemsSrc.next(items);

    this.onChange(items.filter(item => item.isSelected).map(item => item.value));
  }

  onClickOutsideBox(): void {
    if (this.isCollapsedSrc.value) {
      this.isCollapsedSrc.next(false);
      this.closeMenu.emit();
    }
  }

  private loadDrivingLicenceTypes(): void {
    this.loadSub = this.refRepo.drivingLicenceTypes$.pipe(
      tap(_ => this.itemsLoadedSrc.next(true))
    ).subscribe(types => {
      this.itemsSrc.next(types.map(type => {
        return { isSelected: false, value: type };
      }));
    });
  }

  private toggleList(): void {
    this.isCollapsedSrc.next(!this.isCollapsedSrc.value);
    if (!this.isCollapsedSrc.value) {
      this.closeMenu.emit();
    }
  }

  private isGroupOther(group: DrivingLicenceTypeGroup): boolean {
    return ['AM', 'BE', 'B96', 'B1'].some(otherName => otherName === group.name);
  }

  private debounceClickField(): void {
    this.clickFieldSub = this.clickFieldSrc.asObservable()
      .pipe(debounceTime(10))
      .subscribe(() => this.toggleList());
  }
}
