import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { PaginationResult } from 'src/app/core/utils/pagination/pagination-result.model';

@Injectable({
  providedIn: 'root'
})
export class HttpResponseService {

  constructor() { }


  /**
   * Get the pagination result object from the http response.
   * @return The paination result object
   */
  getPaginationResult<T>(response: any, ctor: NoParamConstructor<T>, bodyData?: []): PaginationResult<T> {
    const result = new PaginationResult<T>();
    const paginatedData = bodyData ? bodyData : response.body;
    for (const data of paginatedData) {
        const item = new ctor();
        Object.assign(item, data as T);
        result.items.push(item);
    }
    result.total = this.getPaginationTotalFromHeaders(response.headers);
    return result;
  }

  /**
   * Extract the total parameter from the http headers.
   * @return number|null The extracted total, null if unknown total
   */
  getPaginationTotalFromHeaders(headers: HttpHeaders): number {
    const contentRange = headers.get('Content-Range');
    const crRegexp = /.*\/(\d*)+/;
    const crArray = contentRange.match(crRegexp);
    return crArray && !!crArray[1] ? Number(crArray[1]) : null;
  }

}

interface NoParamConstructor<T> {
  new (): T;
}
