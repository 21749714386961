import {ApiDrivingLicenceType} from '../api-responses/api-driving-licence-type.model';
import {DrivingLicenceType} from '../../models/driving-licence-type.model';
import {DrivingLicenceTypeGroup} from '../../models/driving-licence-type-group.model';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DrivingLicenceTypeAssemblerService {

  toModel(apiType: ApiDrivingLicenceType): DrivingLicenceType {
    const group = new DrivingLicenceTypeGroup(apiType.group.id, apiType.group.name);

    return new DrivingLicenceType(apiType.id, apiType.name, group);
  }
}
