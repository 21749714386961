import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {faChevronDown, faChevronUp} from '@fortawesome/free-solid-svg-icons';
import {ControlValueAccessor, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {BehaviorSubject, Subject, Subscription} from 'rxjs';
import {debounceTime, share} from 'rxjs/operators';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';

@Component({
  selector: 'w-free-dropdown',
  templateUrl: './free-dropdown.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: FreeDropdownComponent
    }
  ]
})
export class FreeDropdownComponent implements OnInit, OnDestroy, OnChanges, ControlValueAccessor {

  static dropdownCount = 0;

  @Input() altitude: 'flat' | 'high' = 'flat';
  @Input() buttonIcon: IconDefinition = null;
  @Input() buttonType: 'primary' | 'secondary' | 'tertiary' | 'cta' = null;
  @Input() directionX: 'left' | 'right' = 'right';
  @Input() disableChevron = false;
  @Input() disableListBoxLabel = false;
  @Input() fieldSize: 'small' | 'medium' | 'large' = 'medium';
  @Input() fieldType: 'button' | 'select' = 'button';
  @Input() helperText = '';
  @Input() htmlId = `freeDropdown${FreeDropdownComponent.dropdownCount++}`;
  @Input() invalidText = 'Invalid field value';
  @Input() isInvalid = false;
  @Input() itemLabelKey = 'label';
  @Input() itemValueKey = 'value';
  @Input() items: Array<any> = [];
  @Input() itemsSetMapping: { [valueKey: string]: boolean } = {};
  @Input() label = '';
  @Input() placeholder = 'Select option';
  @Input() size: 'small' | 'medium' | 'large' = 'medium';
  @Input() type: 'single' | 'multi' = 'single';
  @Input() unselect: 'on' | 'off' = 'on';

  @Output() closeMenu = new EventEmitter<void>();
  @Output() touched = new EventEmitter<void>();

  iconCollapse = faChevronDown;
  iconFlatten = faChevronUp;

  private isCollapsedSrc = new BehaviorSubject<boolean>(false);
  readonly isCollapsed$ = this.isCollapsedSrc.asObservable().pipe(share());

  private isDisabledSrc = new BehaviorSubject<boolean>(false);
  readonly isDisabled$ = this.isDisabledSrc.asObservable();

  dropdownForm: UntypedFormControl|UntypedFormArray;

  private _value: any = null;
  protected get value(): any { return this._value; }
  protected set value(value: any) { this._value = value; }

  private selectItemSrc = new Subject<any>();
  private selectItemSub: Subscription;

  private clickFieldSrc = new Subject<void>();
  private clickField$ = this.clickFieldSrc.asObservable();
  private clickFieldSub: Subscription;

  constructor(
    private fb: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.initForm();

    this.debounceClickField();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes.items && !changes.items.isFirstChange()) {
      if (this.isMultiselect) {
        this.checkboxesForm.controls = this.items.map(item => {
          return this.fb.control(this.value.includes(item[this.itemValueKey]));
        });
      }
    }
  }

  ngOnDestroy(): void {
    this.selectItemSub.unsubscribe();
    this.clickFieldSub.unsubscribe();
  }

  writeValue(value: any) {
    this.value = value;

    if (this.isMultiselect) {
      this.checkboxesForm.controls.forEach((checkbox, index) => {
        checkbox.setValue(
          this.value.includes(this.items[index][this.itemValueKey]),
          { emitEvent: false }
        );
      });
    } else {
      const form = this.dropdownForm as UntypedFormControl;
      form.setValue(value, { emitEvent: false });
    }
  }

  onChange: (value: any) => void = () => {};
  registerOnChange(onChange: any) {
    this.onChange = onChange;

    this.observeItemSelect();
  }

  onTouched = () => {};
  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  setDisabledState(disabled: boolean) {
    if (disabled) {
      this.isCollapsedSrc.next(false);
    }
    this.isDisabledSrc.next(disabled);
  }

  onClickField(): void {
    this.clickFieldSrc.next();

    this.onTouched();
    this.touched.emit();
  }

  onClickItem(item: any): void {
    this.selectItem(item);
  }

  onClickOutsideList(): void {
    if (this.isCollapsedSrc.value) {
      this.toggleList();
    }
  }

  getCheckboxCtrl(index: number): UntypedFormControl {
    return this.checkboxesForm.controls[index] as UntypedFormControl;
  }

  isItemSelected(item: any): boolean {
    if (this.isMultiselect) {
      return this.value.some(value => item[this.itemValueKey] === value);
    } else {
      return this.value === item[this.itemValueKey];
    }
  }

  isItemClickable(item: any): boolean {
    return this.itemsSetMapping[item[this.itemValueKey]] ?? true;
  }

  get isMultiselect(): boolean {
    return this.type === 'multi';
  }

  get hasLabel(): boolean {
    return this.label !== '';
  }

  get isButtonIcon(): boolean {
    return this.buttonIcon !== null;
  }

  get hasHelperText(): boolean {
    return this.helperText !== '';
  }

  get hasSelection(): boolean {
    if (this.isMultiselect) {
      return this.value.length > 0;
    } else {
      return this.value !== null;
    }
  }

  get isFlattened(): boolean {
    return !this.isCollapsedSrc.value;
  }

  get isLabelPlaceholder(): boolean {
    return !this.hasSelection || this.disableListBoxLabel;
  }

  get listBoxLabel(): string {
    return this.isLabelPlaceholder
      ? this.placeholder
      : this.listBoxLabelSelected;
  }

  get listBoxLabelSelected(): string {
    if (this.isMultiselect) {
      return this.getSelected().map(item => item[this.itemLabelKey]).join(', ');
    } else {
      return this.getSelected()[this.itemLabelKey];
    }
  }

  get listBoxFieldCssClass(): string {
    const cssClass = [
      'free-dropdown-list-box-field',
      `free-dropdown-list-box-field-${this.fieldType}`,
      `free-dropdown-list-box-field-${this.altitude}`,
      `free-btn-${this.fieldSize}`,
    ];
    if (this.isButtonIcon) {
      cssClass.push('free-btn-icon');
      cssClass.push('free-btn-flat');
    }
    if (this.buttonType !== null) {
      cssClass.push(`free-btn-${this.buttonType}`);
    }
    return cssClass.join(' ');
  }

  get checkboxesForm(): UntypedFormArray {
    return this.dropdownForm as UntypedFormArray;
  }

  private get canUnselect(): boolean {
    return this.unselect === 'on';
  }

  private initForm(): void {
    if (this.isMultiselect) {
      this.dropdownForm = this.fb.array(
        this.items.map(() => this.fb.control(false))
      );
    } else {
      this.dropdownForm = this.fb.control(null);
    }
  }

  private getSelected(): any {
    if (this.isMultiselect) {
      return this.value.map(currentValue => {
        return this.items.find(item => item[this.itemValueKey] === currentValue);
      }).filter(item => !!item);
    } else {
      return this.items.find(item => this.value === item[this.itemValueKey]);
    }
  }

  private closeList(): void {
    this.isCollapsedSrc.next(false);
    this.closeMenu.emit();
  }

  private toggleList(): void {
    this.isCollapsedSrc.next(!this.isCollapsedSrc.value);
    if (this.isFlattened) {
      this.closeMenu.emit();
    }
  }

  private selectItem(item: any): void {
    this.selectItemSrc.next(item);
  }

  private observeItemSelect(): void {
    this.selectItemSub = this.selectItemSrc.asObservable().subscribe(selected => {
      const value = selected[this.itemValueKey];
      if (this.isMultiselect) {

        // Update actual value
        const valueIndex = this._value.findIndex(currentValue => currentValue === value);
        if (valueIndex === -1) {
          this._value.push(value);
        } else {
          this._value.splice(valueIndex, 1);
        }

        // Update input value
        const checkboxIndex = this.items.findIndex(item => item[this.itemValueKey] === value);
        const checkbox = this.checkboxesForm.controls[checkboxIndex];
        checkbox.setValue(!checkbox.value);

        this.onChange(this.value);
      } else {
        const newValue = this.value === value && this.canUnselect ? null : value;
        if (this.value !== newValue) {
          this.value = newValue;
          this.dropdownForm.setValue(newValue);
          this.onChange(this.value);
        }
        this.closeList();
      }
    });
  }

  private debounceClickField(): void {
    this.clickFieldSub = this.clickField$
      .pipe(debounceTime(10))
      .subscribe(() => {
        this.toggleList();

        this.onTouched();
      });
  }
}
