import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';

@Component({
  selector: 'w-free-checkbox-group',
  templateUrl: './free-checkbox-group.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: FreeCheckboxGroupComponent
    }
  ]
})
export class FreeCheckboxGroupComponent implements OnInit, OnDestroy, OnChanges, ControlValueAccessor {

  static checkboxGroupCount = 0;

  @Input() direction: 'column' | 'row' = 'column';
  @Input() helperText = '';
  @Input() isInvalid = false;
  @Input() inputName = `freeCheckboxGroupInput${FreeCheckboxGroupComponent.checkboxGroupCount++}`;
  @Input() invalidText = 'Invalid field value';
  @Input() itemLabelKey = 'label';
  @Input() itemValueKey = 'value';
  @Input() items: Array<any> = [];
  @Input() itemsSetMapping: { [valueKey: string]: boolean } = {};
  @Input() label = '';

  checkboxesForm: UntypedFormArray;

  private _value: any = null;
  protected get value(): any { return this._value }
  protected set value(value: any) { this._value = value }

  private selectItemSrc = new Subject<any>();

  private selectItemSub: Subscription;

  constructor(
    private fb: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.initControl();
  }

  ngOnDestroy(): void {
    this.selectItemSub.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!!changes.items && !changes.items.isFirstChange()) {
      this.checkboxesForm.controls = this.items.map(item => {
        return this.fb.control(this.value.includes(item[this.itemValueKey]));
      });
    }
  }

  writeValue(value: any) {
    this.value = value;

    this.checkboxesForm.controls.forEach((checkbox, index) => {
      checkbox.setValue(
        this.value.includes(this.items[index][this.itemValueKey]),
        { emitEvent: false }
      );
    });
  }

  onChange: (value: any) => void = () => {};
  registerOnChange(onChange: any) {
    this.onChange = onChange;

    this.observeItemSelect();
  }

  onTouched = () => {};
  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  setDisabledState(disabled: boolean) {
    if (disabled) {
      this.checkboxesForm.controls.forEach(control => control.disable());
    } else {
      this.checkboxesForm.controls.forEach(control => control.enable());
    }
  }

  onInputBlur(): void {
    this.onTouched();
  }

  getCheckboxCtrl(index: number): UntypedFormControl {
    return this.checkboxesForm.controls[index] as UntypedFormControl;
  }

  get hasLabel(): boolean {
    return this.label !== '';
  }

  get hasHelperText(): boolean {
    return this.helperText !== '';
  }

  private initControl(): void {
    this.checkboxesForm = this.fb.array(
      this.items.map(() => this.fb.control(null))
    );
  }

  private observeItemSelect(): void {
    this.selectItemSub = this.selectItemSrc.asObservable().subscribe(selected => {
      const value = selected[this.itemValueKey];
      const valueIndex = this._value.findIndex(currentValue => currentValue === value);
      if (valueIndex === -1) {
        this._value.push(value);
      } else {
        this._value.splice(valueIndex, 1);
      }

      const checkboxIndex = this.items.findIndex(item => item[this.itemValueKey] === value);
      const checkbox = this.checkboxesForm.controls[checkboxIndex];
      checkbox.setValue(!checkbox.value);
      this.onChange(this.value);
    });
  }

  onClickItem(item: any) {
    this.selectItem(item);
  }

  isItemClickable(item: any): boolean {
    return this.itemsSetMapping[item[this.itemValueKey]] ?? true;
  }

  private selectItem(item: any): void {
    this.selectItemSrc.next(item);
  }
}
