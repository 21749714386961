import { Component, OnInit } from '@angular/core';
import {SnackbarService} from './snackbar.service';
import {Subscription, timer} from 'rxjs';
import {Snackbar} from './snackbar.model';

@Component({
  selector: 'w-snackbar',
  templateUrl: './snackbar.component.html',
})
export class SnackbarComponent implements OnInit {

  snackbar = SnackbarService.HIDDEN_SNACKBAR;


  private snackbarSub: Subscription;
  private durationSub: Subscription;


  constructor(private snackbarService: SnackbarService) { }


  ngOnInit(): void {
    this.observeSnackbar();
  }


  onClickContent(): void {
    if (this.snackbar.onClick !== null) {
      this.snackbar.onClick();
    }
  }


  get isDisplayed(): boolean {
    return this.snackbar.content !== '';
  }

  get isClickable(): boolean {
    return this.snackbar.onClick !== null;
  }


  private observeSnackbar(): void {
    this.snackbarSub = this.snackbarService.snackbar$.subscribe(message => {
      this.show(message);

      this.durationSub = timer(this.snackbar.duration).subscribe(() => this.hide());
    });
  }

  private hide(): void {
    this.snackbar = SnackbarService.HIDDEN_SNACKBAR;
  }

  private show(message: Snackbar): void {
    this.snackbar = message;
  }
}
