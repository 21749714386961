import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ControlValueAccessor, UntypedFormBuilder, UntypedFormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Subscription} from 'rxjs';

@Component({
  selector: 'w-free-checkbox',
  templateUrl: './free-checkbox.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: FreeCheckboxComponent
    }
  ]
})
export class FreeCheckboxComponent implements OnInit, OnDestroy, ControlValueAccessor {

  @Input() invalidText = 'Invalid field value';
  @Input() isInvalid = false;

  inputCtrl: UntypedFormControl;

  private valueChangesSub: Subscription;

  constructor(
    private fb: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.initControl();
  }

  ngOnDestroy(): void {
    this.valueChangesSub.unsubscribe();
  }

  writeValue(isChecked: boolean) {
    this.inputCtrl.setValue(isChecked, { emitEvent: false });
  }

  onChange: (isChecked: boolean) => void = () => {};
  registerOnChange(onChange: any) {
    this.onChange = onChange;

    this.observeInput();
  }

  onTouched = () => {};
  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  setDisabledState(disabled: boolean) {
    if (disabled) {
      this.inputCtrl.disable({ emitEvent: false });
    } else {
      this.inputCtrl.enable({ emitEvent: false });
    }
  }

  onInputBlur(): void {
    this.onTouched();
  }

  private initControl(): void {
    this.inputCtrl = this.fb.control(false);
  }

  private observeInput(): void {
    this.valueChangesSub = this.inputCtrl.valueChanges.subscribe(value => this.onChange(value));
  }
}
