<div class="search-student-input typeahead">
  <div class="input-group">
    <input
      type="text"
      placeholder="{{ placeholder }}"
      autocomplete="off"
      name="query"
      size="1"
      [formControl]="studentName"
      (input)="onInput()"
    >
    <button
      type="button"
      class="input-group-append"
      title="Retirer"
      *ngIf="isRemovable"
      (click)="onClickRemove(); $event.stopPropagation()"
    >
      <span class="icon"><fa-icon [icon]="iconRemove"></fa-icon></span>
    </button>
  </div>
  <ul class="typeahead-suggestions" *ngIf="hasStudents$ | async" wClickOutside (clickOutside)="onClickOutside()">
    <li
      *ngFor="let student of students$ | async"
      (click)="onClickStudent(student); $event.stopPropagation()"
    >
      <span class="search-student-suggestion-name">{{ student | studentFullname }}</span>
      <div class="search-student-suggestion-agency icon-group" *ngIf="student.hasAgency">
        <span class="icon-group-prepend"><fa-icon [icon]="iconAgency"></fa-icon></span>
        <span>{{ student.agency }}</span>
      </div>
      <div class="search-student-suggestion-progression">
        Progression : {{ student.doneDrivingLessonsDurationInMinutes | duration }} / {{ student.numEstimatedTrainingHours ?? '??' }}h
      </div>
    </li>
  </ul>
</div>
