import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ControlValueAccessor, UntypedFormBuilder, UntypedFormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Subscription} from 'rxjs';

@Component({
  selector: 'w-free-radio-group',
  templateUrl: './free-radio-group.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: FreeRadioGroupComponent
    }
  ]
})
export class FreeRadioGroupComponent implements OnInit, OnDestroy, ControlValueAccessor {

  static radioGroupCount = 0;

  @Input() direction: 'column' | 'row' = 'column';
  @Input() helperText = '';
  @Input() isInvalid = false;
  @Input() inputName = `freeRadioGroupInput${FreeRadioGroupComponent.radioGroupCount++}`;
  @Input() invalidText = 'Invalid field value';
  @Input() itemLabelKey = 'label';
  @Input() itemValueKey = 'value';
  @Input() items: Array<any> = [];
  @Input() label = '';

  inputCtrl: UntypedFormControl;

  private valueChangesSub: Subscription;

  constructor(
    private fb: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.initControl();
  }

  ngOnDestroy(): void {
    this.valueChangesSub.unsubscribe();
  }

  writeValue(value: any) {
    this.inputCtrl.setValue(value, { emitEvent: false });
  }

  onChange: (value: any) => void = () => {};
  registerOnChange(onChange: any) {
    this.onChange = onChange;

    this.observeInput();
  }

  onTouched = () => {};
  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  setDisabledState(disabled: boolean) {
    if (disabled) {
      this.inputCtrl.disable();
    } else {
      this.inputCtrl.enable();
    }
  }

  onInputBlur(): void {
    this.onTouched();
  }

  get hasLabel(): boolean {
    return this.label !== '';
  }

  get hasHelperText(): boolean {
    return this.helperText !== '';
  }

  private initControl(): void {
    this.inputCtrl = this.fb.control(null);
  }

  private observeInput(): void {
    this.valueChangesSub = this.inputCtrl.valueChanges.subscribe(value => this.onChange(value));
  }
}
