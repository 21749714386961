<div class="user-widget marketplace">
  <a routerLink="/mon-compte/connecter" class="nav-link" *ngIf="!(isLoggedIn$ | async)">Se connecter</a>

  <w-free-dropdown
    *ngIf="isLoggedIn$ | async"
    directionX="left"
    [disableListBoxLabel]="true"
    fieldSize="small"
    [formControl]="selectCtrl"
    [items]="links$ | async"
    [itemsSetMapping]="disabledLinks"
    placeholder="Mon compte"
    (closeMenu)="onSelectValue()"
  />
</div>
