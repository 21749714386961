import {Injectable} from '@angular/core';
import {ApiDrivingLessonType} from '../api-responses/api-driving-lesson-type.model';
import {DrivingLessonType} from '../../models/driving-lesson-type.model';

@Injectable({
  providedIn: 'root'
})
export class DrivingLessonTypeAssemblerService {

  toModel(apiDrivingLessonType: ApiDrivingLessonType): DrivingLessonType {
    const drivingLessonType = new DrivingLessonType();
    drivingLessonType.id = apiDrivingLessonType.id;
    drivingLessonType.name = apiDrivingLessonType.name;
    drivingLessonType.shortName = apiDrivingLessonType.shortName;
    drivingLessonType.isCode = apiDrivingLessonType.isCode;
    drivingLessonType.isDrivingTest = apiDrivingLessonType.isDrivingTest;
    drivingLessonType.isEvaluation = apiDrivingLessonType.isEvaluation;
    drivingLessonType.isListening = apiDrivingLessonType.isListening;
    drivingLessonType.isTraining = apiDrivingLessonType.isTraining;
    drivingLessonType.isTrainingLesson = apiDrivingLessonType.isTrainingLesson;
    drivingLessonType.isTrainingLessonShared = apiDrivingLessonType.isTrainingLessonShared;
    drivingLessonType.isMockExam = apiDrivingLessonType.isMockExam;

    return drivingLessonType;
  }
}
