import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'w-layout',
  templateUrl: './layout.component.html'
})
export class LayoutComponent implements OnInit {


  constructor() { }


  ngOnInit() {
  }
}
