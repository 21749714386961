import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {BehaviorSubject, Subject, Subscription} from 'rxjs';
import {ApiStudentService} from '../../../core/http/api-services/api-student.service';
import {Student} from '../../../core/models/student.model';
import {debounceTime, finalize, map} from 'rxjs/operators';
import {UntypedFormControl} from '@angular/forms';
import {faStoreAlt, faTimes} from '@fortawesome/free-solid-svg-icons';
import {StudentFullnamePipe} from '../../pipes/student-fullname.pipe';
import {SearchStudentFilters} from './search-student-filters.model';
import {DrivingLicenceTypeGroup} from '../../../core/models/driving-licence-type-group.model';
import {PaginationRange} from '../../../core/utils/pagination/pagination-range.model';

@Component({
  selector: 'w-search-student-input',
  templateUrl: './search-student-input.component.html',
  providers: [StudentFullnamePipe]
})
export class SearchStudentInputComponent implements OnInit, OnDestroy, OnChanges {

  static NUM_STUDENTS_MAX = 10;

  @Input() isDisabled = false;
  @Input() isRemovable = true;
  @Input() licenceTypeGroups: DrivingLicenceTypeGroup[] = [];
  @Input() placeholder = 'Elève';
  @Input() set student(student: Student) {
    if (!!student) {
      this.selectedStudent = new Student();
      Object.assign(this.selectedStudent, student);
    }
  }

  @Output() selectStudent = new EventEmitter<Student>();
  @Output() unselectStudent = new EventEmitter<void>();

  iconAgency = faStoreAlt;
  iconRemove = faTimes;

  selectedStudent: Student;

  studentName: UntypedFormControl;
  isLoading = false;

  studentsSrc = new BehaviorSubject<Student[]>([]);
  students$ = this.studentsSrc.asObservable();
  hasStudents$ = this.students$.pipe(map(students => students.length > 0));

  searchFilters: SearchStudentFilters;

  private searchUpdated = new Subject<void>();

  private inputSub: Subscription;
  private searchSub: Subscription;

  constructor(
    private studentRepo: ApiStudentService,
    private studentFullnamePipe: StudentFullnamePipe
  ) {}

  ngOnInit(): void {
    this.searchFilters = {
      query: '',
      groups: this.licenceTypeGroups
    };

    this.initControl();

    this.debounceSearch();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes.student && !changes.student.isFirstChange()) {
      this.studentName.setValue(this.studentFullnamePipe.transform(this.selectedStudent));
      this.cleanSuggestions();
    }

    if (!!changes.licenceTypeGroups && !changes.licenceTypeGroups.isFirstChange()) {
      this.searchFilters.groups = changes.licenceTypeGroups.currentValue;
    }
  }

  ngOnDestroy(): void {
    this.inputSub.unsubscribe();
    this.searchSub?.unsubscribe();
  }

  /** Do nothing if not selectable */
  onClickStudent(student: Student): void {
    this.selectedStudent = student;
    this.studentName.setValue(this.studentFullnamePipe.transform(student));
    this.cleanSuggestions();

    this.selectStudent.emit(student);
  }

  onInput(): void {
    if (!!this.selectedStudent) {
      this.removeSelection();
    }
    this.searchUpdated.next();
  }

  onClickOutside(): void {
    this.cleanSuggestions();
    if (!!this.selectedStudent) {
      this.removeSelection();
    }
  }

  onClickRemove(): void {
    this.studentName.reset();
    this.cleanSuggestions();
    if (!!this.selectedStudent) {
      this.removeSelection();
    }
  }

  reset(): void {
    this.selectedStudent = null;
    this.studentName.reset();
    this.cleanSuggestions();
  }

  private initControl(): void {
    this.studentName = new UntypedFormControl({
      value: this.studentFullnamePipe.transform(this.selectedStudent),
      disabled: this.isDisabled
    });
  }

  private debounceSearch(): void {
    this.inputSub = this.searchUpdated.asObservable().pipe(
      debounceTime(300)
    ).subscribe(() => {
      const query = this.studentName.value.trim().toLowerCase();
      if (query && query.length > 0) {
        this.searchFilters.query = query;
        this.searchStudents();
      } else {
        this.cleanSuggestions();
      }
    });
  }

  private searchStudents(): void {
    this.isLoading = true;
    this.searchSub = this.studentRepo.searchStudents$(
      this.searchFilters,
      new PaginationRange(0, SearchStudentInputComponent.NUM_STUDENTS_MAX)
    ).pipe(
      finalize(() => this.isLoading = false)
    ).subscribe(result => this.studentsSrc.next(result.items));
  }

  private removeSelection(): void {
    this.selectedStudent = null;
    this.unselectStudent.emit();
  }

  private cleanSuggestions(): void {
    this.studentsSrc.next([]);
  }
}
