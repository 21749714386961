export class Duration {

  private readonly _valueInSeconds: number;

  private constructor(valueInSeconds = 0) {
    this._valueInSeconds = valueInSeconds;
  }

  static fromValueInSeconds(value: number): Duration {
    return new Duration(value);
  }

  static fromValueInHours(value: number): Duration {
    return new Duration(value * 60 * 60);
  }

  static fromValueInMinutes(value: number): Duration {
    return new Duration(value * 60);
  }

  static fromHoursAndMinutes(hours: number, minutes: number): Duration {
    return new Duration(hours * 3600 + minutes * 60);
  }

  static fromTwoDates(startAt: Date, endAt: Date): Duration {
    return new Duration((endAt.getTime() - startAt.getTime()) / 1000);
  }

  static zero(): Duration {
    return new Duration();
  }

  get valueInSeconds(): number {
    return this._valueInSeconds;
  }

  get valueInMinutes(): number {
    return this._valueInSeconds / 60;
  }

  get valueInHours(): number {
    return this._valueInSeconds / 3600;
  }

  get isZero(): boolean {
    return this._valueInSeconds === 0;
  }

  get hours(): number {
    return Math.floor(this._valueInSeconds / 3600);
  }

  get minutes(): number {
    return Math.floor(this.valueInMinutes % 60);
  }

  equals(value: Duration): boolean {
    return value.valueInSeconds === this._valueInSeconds;
  }

  add(value: Duration): Duration {
    return new Duration(this._valueInSeconds + value.valueInSeconds);
  }

  subtract(value: Duration): Duration {
    return new Duration(this._valueInSeconds - value.valueInSeconds);
  }

  multiply(multiplier: number): Duration {
    return new Duration(this._valueInSeconds * multiplier);
  }

  modulo(value: Duration): Duration {
    return new Duration(this._valueInSeconds % value.valueInSeconds);
  }

  greaterThan(value: Duration): boolean {
    return this.valueInSeconds > value.valueInSeconds;
  }

  lesserThan(value: Duration): boolean {
    return this.valueInSeconds < value.valueInSeconds;
  }
}
