import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {faChevronLeft, faChevronRight} from '@fortawesome/free-solid-svg-icons';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import AirDatepicker from 'air-datepicker';
import localeFr from 'air-datepicker/locale/fr';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'w-day-navigator',
  templateUrl: './day-navigator.component.html',
  providers: [
    DatePipe
  ]
})
export class DayNavigatorComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() urlPrefix = '';
  @Input() dayParamName = 'day';
  @Input() isDisplayedShortcut = true;

  @Output() displayDay = new EventEmitter<Date>();

  @ViewChild('datepicker') datepickerInput: ElementRef<HTMLInputElement>;

  iconNext = faChevronRight;
  iconPrevious  = faChevronLeft;

  day = new Date();
  next = new Date();
  previous = new Date();

  private datepicker: AirDatepicker<HTMLInputElement>;

  private routeSub: Subscription;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.observeDay();
  }

  ngAfterViewInit(): void {
    const closeBtn = {
      content: 'Fermer',
      onClick: dp => dp.hide()
    };
    this.datepicker = new AirDatepicker<HTMLInputElement>(this.datepickerInput.nativeElement, {
      locale: localeFr,
      autoClose: true,
      toggleSelected: false,
      position: ({ $datepicker, $target, $pointer }) => {
        const coords = $target.getBoundingClientRect();
        const dpWidth = $datepicker.clientWidth;
        const top = coords.y + coords.height + window.scrollY;
        const left = coords.x - (dpWidth - coords.width) / 2;
        $datepicker.style.left = `${left}px`;
        $datepicker.style.top = `${top}px`;
        $pointer.style.display = 'none';
      },
      buttons: [closeBtn],
      onSelect: ({ date }) => {
        this.router.navigate([this.urlPrefix, this.datePipe.transform(date as Date, 'yyyy-MM-dd')]);
      }
    });
  }

  ngOnDestroy(): void {
    this.routeSub.unsubscribe();
    this.datepicker.destroy();
  }

  onClickDay(): void {
    if (this.datepicker.visible) {
      this.datepicker.hide();
    } else {
      this.datepicker.selectDate(this.day, { silent: true });
      this.datepicker.setViewDate(this.day);
      this.datepicker.show();
    }
  }

  private observeDay(): void {
    this.routeSub = this.route.paramMap.subscribe(params => {
      if (this.datepicker?.visible) {
        this.datepicker.hide();
      }

      // Build the day to display
      const dayParamValue = params.get(this.dayParamName);
      if (dayParamValue !== 'aujourdhui') {
        this.day = new Date(dayParamValue + 'T00:00:00');
        this.day.setHours(0, 0, 0, 0);

        // If the day is today, redirect
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        if (today.getTime() === this.day.getTime()) {
          this.router.navigate([this.urlPrefix, 'aujourdhui']);
        } else {
          this.displayDay.emit(this.day);
        }
      } else {
        this.day = new Date();
        this.displayDay.emit(this.day);
      }

      // Update the navigation links
      this.next = new Date(this.day);
      this.next.setDate(this.next.getDate() + 1);
      this.previous = new Date(this.day);
      this.previous.setDate(this.previous.getDate() - 1);
    });
  }
}
