import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {ControlValueAccessor, UntypedFormBuilder, UntypedFormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Subscription} from 'rxjs';
import {faPercent} from "@fortawesome/free-solid-svg-icons";
import {FreeNumberInputComponent} from "../free-number-input/free-number-input.component";

@Component({
  selector: 'w-free-rate-input',
  templateUrl: './free-rate-input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: FreeRateInputComponent
    }
  ]
})
export class FreeRateInputComponent implements OnInit, OnDestroy, ControlValueAccessor {

  static rateInputCount = 0;

  @Input() helperText = '';
  @Input() htmlId = `freeRateInput${FreeRateInputComponent.rateInputCount++}`;
  @Input() inputName = 'freeRateInputName';
  @Input() invalidText = 'Invalid field value';
  @Input() isAutocomplete = true;
  @Input() isInvalid = false;
  @Input() label = '';
  @Input() max: number = null;
  @Input() min: number = null;
  @Input() placeholder = '';
  @Input() size: 'small' | 'medium' | 'large' = 'medium';
  @Input() step = 1;

  @Output() focusInput = new EventEmitter<void>();
  @Output() touched = new EventEmitter<void>();

  @ViewChild('numberInput') numberInput: FreeNumberInputComponent;

  iconRate = faPercent;

  inputCtrl: UntypedFormControl;

  private valueChangesSub: Subscription;

  constructor(
    private fb: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.initControl();
  }

  ngOnDestroy(): void {
    this.valueChangesSub.unsubscribe();
  }

  writeValue(value: number | null) {
    this.inputCtrl.setValue(value, { emitEvent: false });
  }

  onChange: (value: number | null) => void = () => {};
  registerOnChange(onChange: any) {
    this.onChange = onChange;

    this.observeInput();
  }

  onTouched = () => {};
  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  setDisabledState(disabled: boolean) {
    if (disabled) {
      this.inputCtrl.disable();
    } else {
      this.inputCtrl.enable();
    }
  }

  onInputFocus(): void {
    this.focusInput.emit();
  }

  onInputTouched(): void {
    this.onTouched();
  }

  get inputElt(): any {
    return this.numberInput.inputElt;
  }

  private initControl(): void {
    this.inputCtrl = this.fb.control(null);
  }

  private observeInput(): void {
    this.valueChangesSub = this.inputCtrl.valueChanges.subscribe(value => this.onChange(value));
  }
}
