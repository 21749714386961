import {PostalAddress} from './shared/postal-address.model';
import {DrivingLicenceType} from './driving-licence-type.model';
import {DrivingLesson} from './driving-lesson.model';
import {Price} from './shared/price.model';

export class Student {

  address = new PostalAddress();
  agency: string;
  birthdate: Date|null;
  comingDrivingLessonsDurationInMinutes: number;
  doneDrivingLessonsDurationInMinutes: number;
  drivingLicenceType: DrivingLicenceType;
  email: string;
  extraLessonPrice: Price|null;
  extraLessonSharedPrice: Price|null;
  firstName: string;
  id: number;
  isBalanced: boolean|null = false;
  isDebtor: boolean;
  isFastTrackTraining: boolean;
  lastName: string;
  neph: string|null;
  numEstimatedTrainingHours: number;
  phone2: string;
  phone: string;
  evaluationRequestedAt: Date;

  cancelDrivingLesson(drivingLesson: DrivingLesson): void {
    if (drivingLesson.drivingLessonType.isTraining) {
      this.subtractTrainingDuration(drivingLesson);
    }
  }

  failDrivingLesson(drivingLesson: DrivingLesson): void {
    if (drivingLesson.drivingLessonType.isTraining) {
      this.subtractTrainingDuration(drivingLesson);
    }
  }

  restoreDrivingLesson(drivingLesson: DrivingLesson): void {
    if (drivingLesson.drivingLessonType.isTraining) {
      this.addTrainingDuration(drivingLesson);
    }
  }

  subtractTrainingDuration(drivingLesson: DrivingLesson): void {
    if (drivingLesson.isPast) {
      this.doneDrivingLessonsDurationInMinutes -= drivingLesson.durationInMinutes;
    } else {
      this.comingDrivingLessonsDurationInMinutes -= drivingLesson.durationInMinutes;
    }
  }

  addTrainingDuration(drivingLesson: DrivingLesson): void {
    if (drivingLesson.isPast) {
      this.doneDrivingLessonsDurationInMinutes += drivingLesson.durationInMinutes;
    } else {
      this.comingDrivingLessonsDurationInMinutes += drivingLesson.durationInMinutes;
    }
  }

  get hasAgency(): boolean {
    return !!this.agency && this.agency.trim().length > 0;
  }

  get hasExtraLessonPrice(): boolean {
    return this.extraLessonPrice !== null;
  }

  get hasExtraLessonSharePrice(): boolean {
    return this.extraLessonSharedPrice !== null;
  }

  get isBalancedOrDebtor(): boolean {
    return this.isBalanced || this.isDebtor;
  }

  get hasEstimation(): boolean {
    return !!this.numEstimatedTrainingHours;
  }
}
