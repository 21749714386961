export class DrivingSchemaType {

  category: string;
  externalUrl: string;
  filename: string;
  id: number;
  internalUrl: string;
  situation: string;

}
