import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { ConfigService } from '../utils/config.service';
import { TokenStateService } from './token-state.service';
import { Router } from '@angular/router';

@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {

  constructor(
    private tokenState: TokenStateService,
    private config: ConfigService,
    private router: Router
  ) {
  }


  /**
   * Check if the api token is valid. If not, logout.
   * @see HttpInterceptor
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authUrlExp = new RegExp(this.config.apiUrl + '/ds-admin/tokens', 'g');
    return next.handle(request).pipe(tap(
      () => {
      },
      error => {
        // Logout if unauthorized
        if (
          !request.url.match(authUrlExp)
          && error instanceof HttpErrorResponse
          && error.status === 401
        ) {
          if (this.tokenState.hasToken()) {
            this.tokenState.logout();
          } else {
            this.router.navigate(['/mon-compte', 'connecter']);
          }
        }
      }));
  }
}
