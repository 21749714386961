import { WorkedSkillStatusEnum } from '../../student/models/worked-skill-status.enum';

export class DrivingSkill {

  id: number;
  name: string;
  skillNumber: number;
  subSkills: DrivingSkill[] = [];
  status: WorkedSkillStatusEnum;

}
