import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable, BehaviorSubject, Subscription} from 'rxjs';
import {map, filter} from 'rxjs/operators';
import { AuthService } from '../../auth/auth.service';
import { User } from 'src/app/core/models/user.model';
import {ConfigService} from '../../utils/config.service';
import {ApiDrivingTrainer} from '../api-responses/api-driving-trainer.model';

@Injectable({
  providedIn: 'root'
})
export class ApiUserService implements OnDestroy {

  private currentSrc = new BehaviorSubject<User>(new User());

  private loginSub: Subscription;
  private logoutSub: Subscription;

  constructor(
    private auth: AuthService,
    private config: ConfigService,
    private http: HttpClient
  ) {
    this.observeLoginStatus();
  }

  ngOnDestroy(): void {
    this.loginSub.unsubscribe();
    this.logoutSub.unsubscribe();
  }

  me$(): Observable<User> {
    return this.http.get<ApiDrivingTrainer>(this.config.apiUrl + '/driving-trainer').pipe(
      map(apiTrainer => {
        const user = new User();
        Object.assign(user, apiTrainer);

        // TODO TMP: CUSTOM COLORS
        if (!!apiTrainer.customConfig) {
          user.customColorPrimary = apiTrainer.customConfig.primary;
          user.customColorSecondary = apiTrainer.customConfig.secondary;
        }

        return user;
      })
    );
  }

  get current$(): Observable<User> {
    return this.currentSrc.asObservable();
  }

  private observeLoginStatus(): void {

    // Load current when logged in
    this.loginSub = this.auth.isLoggedIn$.pipe(
      filter(isLoggedIn => isLoggedIn),
    ).subscribe(() => this.reloadCurrent());

    // Reset current on logout
    this.logoutSub = this.auth.isLoggedIn$.pipe(
      filter(isLoggedIn => !isLoggedIn),
    ).subscribe(() => this.currentSrc.next(new User()));
  }

  private reloadCurrent(): void {
    this.me$().subscribe(user => this.currentSrc.next(user));
  }
}
