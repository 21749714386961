import {Injectable} from '@angular/core';
import {ApiMeetingPoint} from '../api-responses/api-meeting-point.model';
import {MeetingPoint} from '../../models/meeting-point.model';

@Injectable({
  providedIn: 'root'
})
export class MeetingPointAssemblerService {

  toModel(apiMeetingPoint: ApiMeetingPoint): MeetingPoint {
    return new MeetingPoint(apiMeetingPoint.id, apiMeetingPoint.code, apiMeetingPoint.name);
  }
}
