import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  Output,
  EventEmitter,
  AfterViewInit,
  ViewChild,
  ElementRef
} from '@angular/core';
import {faChevronLeft, faChevronRight} from '@fortawesome/free-solid-svg-icons';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {DateFormatterService} from '../../../core/utils/date-formatter.service';
import AirDatepicker from 'air-datepicker';
import localeFr from 'air-datepicker/locale/fr';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'w-week-navigator',
  templateUrl: './week-navigator.component.html',
  providers: [
    DatePipe
  ]
})
export class WeekNavigatorComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() urlPrefix = '';
  @Input() mondayParamName = 'monday';
  @Input() isDisplayedShortcut = true;

  @Output() displayWeek = new EventEmitter<Date>();

  @ViewChild('datepicker') datepickerInput: ElementRef<HTMLInputElement>;

  iconNext = faChevronRight;
  iconPrevious  = faChevronLeft;

  monday = new Date();
  next = new Date();
  previous = new Date();

  private datepicker: AirDatepicker<HTMLInputElement>;

  private routeSub: Subscription;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formatter: DateFormatterService,
    private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.observeMonday();
  }

  ngAfterViewInit(): void {
    const closeBtn = {
      content: 'Fermer',
      onClick: dp => dp.hide()
    };
    this.datepicker = new AirDatepicker<HTMLInputElement>(this.datepickerInput.nativeElement, {
      locale: localeFr,
      autoClose: true,
      toggleSelected: false,
      position: ({ $datepicker, $target, $pointer }) => {
        const coords = $target.getBoundingClientRect();
        const dpWidth = $datepicker.clientWidth;
        const top = coords.y + coords.height + window.scrollY;
        const left = coords.x - (dpWidth - coords.width) / 2;
        $datepicker.style.left = `${left}px`;
        $datepicker.style.top = `${top}px`;
        $pointer.style.display = 'none';
      },
      buttons: [closeBtn],
      onSelect: ({ date }) => {
        const monday = this.formatter.getMonday(date as Date);
        this.router.navigate([this.urlPrefix, this.datePipe.transform(monday, 'yyyy-MM-dd')]);
      }
    });
  }

  ngOnDestroy(): void {
    this.routeSub.unsubscribe();
    this.datepicker.destroy();
  }

  onClickDay(): void {
    if (this.datepicker.visible) {
      this.datepicker.hide();
    } else {
      this.datepicker.selectDate(this.monday, { silent: true });
      this.datepicker.setViewDate(this.monday);
      this.datepicker.show();
    }
  }

  private observeMonday(): void {
    this.routeSub = this.route.paramMap.subscribe(params => {
      if (this.datepicker?.visible) {
        this.datepicker.hide();
      }

      // Build the week to display
      const mondayParamValue = params.get(this.mondayParamName);
      if (mondayParamValue !== 'cette-semaine') {
        this.monday = this.formatter.getMonday(new Date(mondayParamValue + 'T00:00:00'));

        // If the start day is this week, redirect
        const thisWeek: Date = this.formatter.getMonday(new Date());
        if (thisWeek.getTime() === this.monday.getTime()) {
          this.router.navigate([this.urlPrefix, 'cette-semaine']);
        } else {
          this.displayWeek.emit(this.monday);
        }
      } else {
        this.monday = this.formatter.getMonday(new Date());
        this.displayWeek.emit(this.monday);
      }

      // Update the navigation links
      this.next = new Date(this.monday);
      this.next.setDate(this.next.getDate() + 7);
      this.previous = new Date(this.monday);
      this.previous.setDate(this.previous.getDate() - 7);
    });
  }
}
