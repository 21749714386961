<div class="free-duration-input free-text-input">
  <label class="free-form-label" for="{{ htmlId }}" *ngIf="hasLabel$ | async">
    {{ label }}
  </label>

  <div class="free-duration-inputs">
    <div class="free-duration-input-hours">
      <w-free-number-input
        #hoursNumberInput
        [formControl]="hoursInputCtrl"
        [htmlId]="htmlId + 'Hours'"
        [inputName]="inputName + 'Hours'"
        [isAutocomplete]="isAutocomplete"
        invalidText=""
        [isInvalid]="isInvalid"
        [max]="valueMaxHours$ | async"
        [min]="valueMinHours$ | async"
        [size]="size"
        step="1"
        trailingText="h"
        (touched)="onInputTouched()"
        (focusInput)="onInputFocus()"
      ></w-free-number-input>
    </div>

    <ng-container *ngIf="fillMinutes">
      <div class="free-duration-input-minutes">
        <w-free-number-input
          #minutesNumberInput
          [formControl]="minutesInputCtrl"
          [htmlId]="htmlId + 'Minutes'"
          [inputName]="inputName + 'Minutes'"
          [isAutocomplete]="isAutocomplete"
          invalidText=""
          [isInvalid]="isInvalid"
          [max]="valueMaxMinutes$ | async"
          [min]="valueMinMinutes$ | async"
          [size]="size"
          step="1"
          trailingText="min"
          (touched)="onInputTouched()"
          (focusInput)="onInputFocus()"
        ></w-free-number-input>
      </div>
    </ng-container>
  </div>

  <div class="free-form-helper-text" *ngIf="hasHelperText$ | async">
    {{ helperText }}
  </div>

  <div class="free-form-invalid-text" *ngIf="isInvalid">
    {{ invalidText }}
  </div>
</div>
