import {DrivingLicenceTypeNameEnum} from './driving-licence-type-name.enum';
import {DrivingLicenceTypeGroup} from './driving-licence-type-group.model';

export class DrivingLicenceType {

  static TYPE_A = [
    DrivingLicenceTypeNameEnum.A,
    DrivingLicenceTypeNameEnum.A1,
    DrivingLicenceTypeNameEnum.A2
  ];

  static TYPE_B_ACCOMPANIED = [
    DrivingLicenceTypeNameEnum.BAAC,
    DrivingLicenceTypeNameEnum.BCS
  ];

  static TYPE_BVA_ACCOMPANIED = [
    DrivingLicenceTypeNameEnum.BVAAAC,
    DrivingLicenceTypeNameEnum.BVACS
  ];

  static TYPE_B = [
    DrivingLicenceTypeNameEnum.B,
    DrivingLicenceTypeNameEnum.B1,
    DrivingLicenceTypeNameEnum.BVA,
    ...DrivingLicenceType.TYPE_B_ACCOMPANIED,
    ...DrivingLicenceType.TYPE_BVA_ACCOMPANIED
  ];

  static TYPE_C = [
    DrivingLicenceTypeNameEnum.C,
    DrivingLicenceTypeNameEnum.C1,
    DrivingLicenceTypeNameEnum.C1E,
    DrivingLicenceTypeNameEnum.CE
  ];

  static TYPE_D = [
    DrivingLicenceTypeNameEnum.D,
    DrivingLicenceTypeNameEnum.D1,
    DrivingLicenceTypeNameEnum.D1E,
    DrivingLicenceTypeNameEnum.DE
  ];

  static TYPE_OTHER = [
    DrivingLicenceTypeNameEnum.BE,
    DrivingLicenceTypeNameEnum.AM,
    DrivingLicenceTypeNameEnum.B96,
    DrivingLicenceTypeNameEnum.PROCACES,
    DrivingLicenceTypeNameEnum.PROECSR,
    DrivingLicenceTypeNameEnum.PROFCO,
    DrivingLicenceTypeNameEnum.PROFIMO
  ];

  static TYPE_BOAT = [
    DrivingLicenceTypeNameEnum.BATEAUC,
    DrivingLicenceTypeNameEnum.BATEAUH,
    DrivingLicenceTypeNameEnum.BATEAUF
  ];

  static DIGITAL_BOOK_SUPPORTED_TYPES = [
    ...DrivingLicenceType.TYPE_A,
    ...DrivingLicenceType.TYPE_B,
  ];

  group: DrivingLicenceTypeGroup;
  id: number;
  name: string;

  constructor(id: number, name: string, group: DrivingLicenceTypeGroup) {
    this.id = id;
    this.name = name;
    this.group = group;
  }

  get isB(): boolean {
    return this.name === DrivingLicenceTypeNameEnum.B;
  }

  get isTypeB(): boolean {
    return DrivingLicenceType.TYPE_B.some(n => n === this.name);
  }

  get isB1(): boolean {
    return this.name === DrivingLicenceTypeNameEnum.B1;
  }

  get isBVA(): boolean {
    return this.name === DrivingLicenceTypeNameEnum.BVA;
  }

  get isBAccompanied(): boolean {
    return DrivingLicenceType.TYPE_B_ACCOMPANIED.some(n => n === this.name);
  }

  get isBVAAccompanied(): boolean {
    return DrivingLicenceType.TYPE_BVA_ACCOMPANIED.some(n => n === this.name);
  }

  get isA(): boolean {
    return DrivingLicenceType.TYPE_A.some(n => n === this.name);
  }

  get isC(): boolean {
    return DrivingLicenceType.TYPE_C.some(n => n === this.name);
  }

  get isD(): boolean {
    return DrivingLicenceType.TYPE_D.some(n => n === this.name);
  }

  get isOther(): boolean {
    return DrivingLicenceType.TYPE_OTHER.some(n => n === this.name);
  }

  get isBoat(): boolean {
    return DrivingLicenceType.TYPE_BOAT.some(n => n === this.name);
  }

  get isPap(): boolean {
    return DrivingLicenceTypeNameEnum.Pap === this.name;
  }

  get isTest(): boolean {
    return DrivingLicenceTypeNameEnum.TestPsy === this.name;
  }

  get isDigitalBookSupported(): boolean {
    return DrivingLicenceType.DIGITAL_BOOK_SUPPORTED_TYPES.some(n => n === this.name);
  }
}
