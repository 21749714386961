import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';

@Component({
  selector: 'w-free-button',
  templateUrl: './free-button.component.html'
})
export class FreeButtonComponent implements OnInit {

  @Input() buttonType: 'primary' | 'secondary' | 'tertiary' | 'cta' = 'primary';
  @Input() display: 'inline' | 'block' = 'inline';
  @Input() htmlButtonType: 'button' | 'submit' = 'button';
  @Input() htmlTag: 'button' | 'link' = 'button';
  @Input() isDisabled = false;
  @Input() isFlat = false;
  @Input() buttonIcon: IconDefinition = null;
  @Input() isHref = false;
  @Input() label = '';
  @Input() leadingIcon: IconDefinition = null;
  @Input() linkUrl: string = null;
  @Input() queryParamsHandling: 'merge' | 'preserve' | '' = '';
  @Input() routerLinkActive: string = null;
  @Input() sideArea = true;
  @Input() size: 'small' | 'medium' | 'large' = 'medium';
  @Input() subLabel = '';
  @Input() trailingIcon: IconDefinition = null;
  @Input() volume: 'silent' | 'quiet' | 'screaming' = 'quiet';

  @Output() clickButton = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {
  }

  onClickButton(): void {
    this.clickButton.emit();
  }

  get isButton(): boolean {
    return this.htmlTag === 'button';
  }

  get isLink(): boolean {
    return this.htmlTag === 'link';
  }

  get isButtonIcon(): boolean {
    return this.buttonIcon !== null;
  }

  get hasLabel(): boolean {
    return this.label !== '';
  }

  get hasSubLabel(): boolean {
    return this.subLabel !== '';
  }

  get hasLeadingIcon(): boolean {
    return this.leadingIcon !== null;
  }

  get hasTrailingIcon(): boolean {
    return this.trailingIcon !== null;
  }

  get cssClass(): string {
    const classes = [
      `free-btn free-btn-${this.buttonType}`,
      `free-btn-${this.size}`,
      `free-btn-${this.volume}`,
      `free-btn-${this.sideArea ? 'sides' : 'no-sides'}`,
      `free-btn-${this.display}`
    ];

    if (this.hasSubLabel) {
      classes.push('free-btn-multiline');
    }

    return classes.join(' ');
  }
}
