<div class="free-date-input">
  <label class="free-form-label" for="{{ htmlId }}" *ngIf="hasLabel">
    {{ label }}
  </label>

  <div class="free-date-inputs">
    <div class="free-date-input-date">
      <w-free-text-input
        #dateInput
        htmlId="{{ htmlId }}"
        [formControl]="dateInputCtrl"
        invalidText=""
        [isAutocomplete]="false"
        [isInvalid]="isInvalid"
        [maxLength]="10"
        placeholder="__/__/____"
        [size]="size"
        (touched)="onInputTouched()"
        wInputMask="XX/XX/XXXX"
      ></w-free-text-input>
    </div>

    <div class="free-date-input-time" *ngIf="isDatetime">
      <w-free-text-input
        [formControl]="timeInputCtrl"
        invalidText=""
        [isAutocomplete]="false"
        [isInvalid]="isInvalid"
        [maxLength]="5"
        placeholder="__:__"
        [size]="size"
        (touched)="onInputTouched()"
        wInputMask="XX:XX"
      ></w-free-text-input>
    </div>
  </div>

  <div class="free-form-helper-text" *ngIf="hasHelperText">
    {{ helperText }}
  </div>

  <div class="free-form-invalid-text" *ngIf="isInvalid && invalidText !== ''">
    {{ invalidText }}
  </div>
</div>
