<div class="free-checkbox-group free-checkbox-group-{{ direction }}">
  <label class="free-form-label" *ngIf="hasLabel">
    {{ label }}
  </label>

  <div class="free-checkbox-group-list">
    <div
      class="free-checkbox"
      [ngClass]="{
        'free-checkbox-disabled': !isItemClickable(item),
        'free-checkbox-invalid': isInvalid
      }"
      *ngFor="let item of items; let i = index"
      (click)="onClickItem(item)"
      wClickPreventDefault>
      <label class="free-checkbox-label">
        <input
          type="checkbox"
          class="free-checkbox-input"
          [name]="inputName"
          [value]="item[itemValueKey]"
          [formControl]="getCheckboxCtrl(i)"
          (blur)="onInputBlur()"
          *ngIf="isItemClickable(item)"
        >
        <span class="free-checkbox-appearance" *ngIf="isItemClickable(item)"></span>
        <span class="free-checkbox-label-text">{{ item[itemLabelKey] }}</span>
      </label>
    </div>
  </div>

  <div class="free-form-helper-text" *ngIf="hasHelperText">
    {{ helperText }}
  </div>

  <div class="free-form-invalid-text" *ngIf="isInvalid && invalidText !== ''">
    {{ invalidText }}
  </div>
</div>
