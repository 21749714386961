import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {faAngleDown, faAngleRight} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'w-collapse-expand-button',
  templateUrl: './collapse-expand-button.component.html'
})
export class CollapseExpandButtonComponent implements OnInit {

  iconExpand = faAngleDown;
  iconCollapse = faAngleRight;


  @Input() isCollapsed = true;


  @Output() collapse = new EventEmitter<void>();

  @Output() expand = new EventEmitter<void>();


  constructor() { }


  ngOnInit(): void {
  }


  onClickToggle(): void {
    this.isCollapsed = !this.isCollapsed;
    if (this.isCollapsed) {
      this.collapse.emit();
    } else {
      this.expand.emit();
    }
  }
}
