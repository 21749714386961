import { NgModule } from '@angular/core';
import { CommonModule, TitleCasePipe, UpperCasePipe } from '@angular/common';
import { DropdownComponent } from './component/dropdown/dropdown.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ClickPreventDefaultDirective } from './directives/click-prevent-default.directive';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AutofocusDirective } from './directives/autofocus.directive';
import {WeekNavigatorComponent} from './component/week-navigator/week-navigator.component';
import {SearchStudentInputComponent} from './component/search-student-input/search-student-input.component';
import {CollapseExpandButtonComponent} from './component/collapse-expand-button/collapse-expand-button.component';
import { StudentFullnamePipe } from './pipes/student-fullname.pipe';
import {DayNavigatorComponent} from './component/day-navigator/day-navigator.component';
import {DurationPipe} from './pipes/duration.pipe';
import {
  SelectDrivingLicenceTypeGroupsDropdownComponent
} from './component/select-driving-licence-type-groups-dropdown/select-driving-licence-type-groups-dropdown.component';
import {FreeCheckboxComponent} from './component/freedom/free-checkbox/free-checkbox.component';
import {FreeCheckboxGroupComponent} from './component/freedom/free-checkbox-group/free-checkbox-group.component';
import {FreeRadioGroupComponent} from './component/freedom/free-radio-group/free-radio-group.component';
import {FreeDropdownComponent} from './component/freedom/free-dropdown/free-dropdown.component';
import {FreeTextInputComponent} from './component/freedom/free-text-input/free-text-input.component';
import {FreeDateInputComponent} from './component/freedom/free-date-input/free-date-input.component';
import {FreeNumberInputComponent} from './component/freedom/free-number-input/free-number-input.component';
import {FreePriceInputComponent} from './component/freedom/free-price-input/free-price-input.component';
import {FreeRateInputComponent} from './component/freedom/free-rate-input/free-rate-input.component';
import {FreeButtonComponent} from './component/freedom/free-button/free-button.component';
import {FreePaginationComponent} from './component/freedom/free-pagination/free-pagination.component';
import {FreeConfirmActionComponent} from './component/freedom/free-confirm-action/free-confirm-action.component';
import {FreeEmailInputComponent} from './component/freedom/free-email-input/free-email-input.component';
import {FreePasswordInputComponent} from './component/freedom/free-password-input/free-password-input.component';
import {FreePhoneInputComponent} from './component/freedom/free-phone-input/free-phone-input.component';
import {
  FreeEditableFieldSelectSingleComponent
} from './component/freedom/free-editable-field/free-editable-field-select-single/free-editable-field-select-single.component';
import {FullNamePipe} from './pipes/full-name.pipe';
import {InlineAddressPipe} from './pipes/inline-address.pipe';
import {FreeDistanceInputComponent} from './component/freedom/free-distance-input/free-distance-input.component';
import {FreeDurationInputComponent} from './component/freedom/free-duration-input/free-duration-input.component';
import {FreeBoxComponent} from './component/freedom/free-box/free-box.component';
import {HttpImageSecurePipe} from './pipes/http-image-secure.pipe';
import {FreePeriodInputComponent} from './component/freedom/free-period-input/free-period-input.component';
import {
  FreeTypeaheadSelectInputComponent
} from './component/freedom/free-typeahead-select-input/free-typeahead-select-input.component';
import {FreeSearchInputComponent} from './component/freedom/free-search-input/free-search-input.component';
import {
  FreePaginationNumberedComponent
} from './component/freedom/free-pagination-numbered/free-pagination-numbered.component';
import {ConfirmModalComponent} from './component/confirm-modal/confirm-modal.component';
import {DrivingLicenceTypeClassPipe} from './pipes/driving-licence-type-class.pipe';
import {
  TrainDrivingLicenceTypeBadgeComponent
} from './component/freedom-train/train-driving-licence-type-badge/train-driving-licence-type-badge.component';
import {
  TrainDrivingLicenceTypeSelectComponent
} from './component/freedom-train/train-driving-licence-type-select/train-driving-licence-type-select.component';
import { InputMaskDirective } from './directives/input-mask.directive';
import {
  FreeDateNavigationInputComponent
} from './component/freedom/free-date-navigation-input/free-date-navigation-input.component';
import {FreeSelectButtonComponent} from './component/freedom/free-select-button/free-select-button.component';

const DIRECTIVES = [
  AutofocusDirective,
  ClickOutsideDirective,
  ClickPreventDefaultDirective,
  InputMaskDirective
];

const COMPONENTS = [
  DropdownComponent,
  WeekNavigatorComponent,
  DayNavigatorComponent,
  SearchStudentInputComponent,
  CollapseExpandButtonComponent,
  SelectDrivingLicenceTypeGroupsDropdownComponent,
  ConfirmModalComponent,

  FreeCheckboxComponent,
  FreeCheckboxGroupComponent,
  FreeRadioGroupComponent,
  FreeDropdownComponent,
  FreeTextInputComponent,
  FreeDateInputComponent,
  FreeNumberInputComponent,
  FreePriceInputComponent,
  FreeRateInputComponent,
  FreeButtonComponent,
  FreePaginationComponent,
  FreeConfirmActionComponent,
  FreeEmailInputComponent,
  FreePasswordInputComponent,
  FreePhoneInputComponent,
  FreeEditableFieldSelectSingleComponent,
  FreeDistanceInputComponent,
  FreeDurationInputComponent,
  FreeBoxComponent,
  FreePeriodInputComponent,
  FreeTypeaheadSelectInputComponent,
  FreePaginationNumberedComponent,
  FreeSearchInputComponent,
  FreeDateNavigationInputComponent,
  FreeSelectButtonComponent,

  TrainDrivingLicenceTypeBadgeComponent,
  TrainDrivingLicenceTypeSelectComponent
];

const PIPES = [
  StudentFullnamePipe,
  DurationPipe,
  FullNamePipe,
  InlineAddressPipe,
  HttpImageSecurePipe,
  DrivingLicenceTypeClassPipe
];

@NgModule({
  imports: [
    CommonModule,
    FontAwesomeModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    ...COMPONENTS,
    ...DIRECTIVES,
    ...PIPES
  ],
  declarations: [
    ...COMPONENTS,
    ...DIRECTIVES,
    ...PIPES
  ],
  providers: [
    TitleCasePipe,
    UpperCasePipe,
    DurationPipe
  ]
})
export class SharedModule { }
