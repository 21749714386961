import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateTimeAssemblerService {

  /**
   * Add local timezone offset to fix jetlag from the device. API is timezone agnostic.
   */
  apiDateTimeToModel(apiDateTime: string): Date {
    const tzo = - (new Date(apiDateTime)).getTimezoneOffset();
    const dif = tzo >= 0 ? '+' : '-';
    const targetApiDateTime = apiDateTime + dif + this.pad(tzo / 60) + ':' + this.pad(tzo % 60);

    return new Date(targetApiDateTime);
  }

  apiDateToModel(apiDate: string): Date {
    return this.apiDateTimeToModel(apiDate + 'T00:00:00');
  }

  nullableApiDateToModel(apiDate: string | null): Date | null {
    return !!apiDate ? this.apiDateTimeToModel(apiDate + 'T00:00:00') : null;
  }

  modelToApiDateTime(datetime: Date): string {
    return `${datetime.getFullYear()}-${datetime.getMonth() + 1}-${datetime.getDate()}`
      + ` ${this.pad(datetime.getHours())}:${this.pad(datetime.getMinutes())}:00`;
  }

  modelToApiDate(datetime: Date): string {
    return `${datetime.getFullYear()}-${datetime.getMonth() + 1}-${datetime.getDate()}`;
  }

  nullableModelToApiDate(datetime: Date | null): string | null {
    return !!datetime ? this.modelToApiDate(datetime) : null;
  }


  private pad(value: number): string {
    const norm = Math.floor(Math.abs(value));
    return (norm < 10 ? '0' : '') + norm;
  }
}
