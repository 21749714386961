import {Pipe, PipeTransform} from '@angular/core';
import {TitleCasePipe, UpperCasePipe} from '@angular/common';

@Pipe({
  name: 'fullName'
})
export class FullNamePipe implements PipeTransform {

  constructor(
    private titleCasePipe: TitleCasePipe,
    private upperCasePipe: UpperCasePipe,
  ) {}

  transform(named: { firstName: string, lastName: string }, isShort = false): string {
    const lastname = isShort
      ? this.titleCasePipe.transform(named.lastName.substr(0, 1)) + '.'
      : this.upperCasePipe.transform(named.lastName);

    return named.firstName
      .split('-')
      .map(str => this.titleCasePipe.transform(str))
      .join('-')
      + ' ' + lastname;
  }
}
