import { Directive, ElementRef, EventEmitter, Output, HostListener } from '@angular/core';

@Directive({
    selector: "[wClickOutside]"
})
export class ClickOutsideDirective {

  @Output() clickOutside: EventEmitter<void> = new EventEmitter<void>();

  constructor(private elementRef: ElementRef) {}

  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement) {
    if (!this.elementRef.nativeElement.contains(targetElement)) {
      this.clickOutside.emit();
    }
  }
}
