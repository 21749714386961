import { Pipe, PipeTransform} from '@angular/core';

@Pipe({ name: 'duration' })
export class DurationPipe implements PipeTransform {

  transform(input: number): string {
    const numHours = Math.floor(input / 60);
    const numMinutes = Math.floor(input % 60);

    return numHours > 0 ? (`${numHours}h` + (numMinutes > 0 ? numMinutes : '')) : `${numMinutes}min`;
  }
}
