<ng-template #buttonContent>
  <span class="free-btn-leading-icon free-icon" *ngIf="hasLeadingIcon">
    <fa-icon [icon]="leadingIcon"></fa-icon>
  </span>
  <span class="free-icon" *ngIf="isButtonIcon">
    <fa-icon [icon]="buttonIcon"></fa-icon>
  </span>
  <span class="free-btn-label" *ngIf="hasLabel">
    {{ label }}
  </span>
  <span class="free-btn-sub-label" *ngIf="hasSubLabel">{{ subLabel }}</span>
  <span class="free-btn-trailing-icon free-icon" *ngIf="hasTrailingIcon">
    <fa-icon [icon]="trailingIcon"></fa-icon>
  </span>
</ng-template>

<ng-container *ngIf="isButton">
  <button
    [type]="htmlButtonType"
    [class]="cssClass"
    [ngClass]="{
      'free-btn-icon': isButtonIcon,
      'free-btn-disabled': isDisabled,
      'free-btn-flat': isFlat
    }"
    [disabled]="isDisabled"
    (click)="onClickButton()"
  >
    <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
  </button>
</ng-container>

<ng-container *ngIf="isLink">
  <a
    [routerLink]="!isDisabled ? linkUrl : null"
    [queryParamsHandling]="queryParamsHandling"
    [routerLinkActive]="[routerLinkActive]"
    [class]="cssClass"
    [ngClass]="{
      'free-btn-icon': isButtonIcon,
      'free-btn-disabled': isDisabled,
      'free-btn-flat': isFlat
    }"
    *ngIf="!isHref"
  >
    <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
  </a>
  <a
    [href]="!isDisabled ? linkUrl : null"
    target="_blank"
    [class]="cssClass"
    [ngClass]="{
      'free-btn-icon': isButtonIcon,
      'free-btn-disabled': isDisabled,
      'free-btn-flat': isFlat
    }"
    *ngIf="isHref"
  >
    <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
  </a>
</ng-container>
