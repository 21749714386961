import { ChangeDetectorRef, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Subject, Subscription} from 'rxjs';
import { distinctUntilChanged, switchMap } from 'rxjs/operators';
import {HttpImageCacheService} from '../../core/utils/image/http-image-cache.service';

@Pipe({
  name: 'httpImageSecure',
  pure: false
})
export class HttpImageSecurePipe implements PipeTransform, OnDestroy {

  private valueSrc = new Subject<string>();

  // Empty pixel while loading
  // Can be replaced by loading image: https://www.thisdot.co/blog/getting-authenticated-images-in-angular
  private latestTransformedValue: SafeUrl = 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=';

  private transformSub: Subscription;

  constructor(
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private changeDetector: ChangeDetectorRef,
    private imageCache: HttpImageCacheService
  ) {
    this.init();
  }

  ngOnDestroy(): void {
    this.transformSub?.unsubscribe();
  }

  transform(value: string): SafeUrl {
    this.valueSrc.next(value);
    return this.latestTransformedValue;
  }

  private init(): void {
    this.transformSub = this.valueSrc.asObservable().pipe(
      distinctUntilChanged(),
      switchMap((imageUrl: string) => this.imageCache.image$(imageUrl))
    ).subscribe(transformedValue => {
      this.latestTransformedValue = transformedValue;
      this.changeDetector.markForCheck();
    });
  }
}
