import {Inject, Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {APP_BASE_HREF} from '@angular/common';
import {WINDOW} from './window.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  private readonly _apiUrl: string;
  private readonly _baseHref: string;


  constructor(
    @Inject(APP_BASE_HREF) private appBaseHref: string,
    @Inject(WINDOW) private window: Window
  ) {
    this._apiUrl = environment.apiUrl;
    this._baseHref = appBaseHref;
  }


  get apiUrl(): string {
    return this._apiUrl;
  }

  get baseUrl(): string {
    return this.window.location.origin + this._baseHref.substring(1);
  }

  get baseHref(): string {
    return this._baseHref;
  }
}
