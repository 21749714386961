import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Component({
  selector: 'w-free-select-button',
  templateUrl: './free-select-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: FreeSelectButtonComponent
    }
  ]
})
export class FreeSelectButtonComponent<T> implements ControlValueAccessor {

  static selectButtonCount = 0;

  @Input() htmlId = `selectDropdownInput${FreeSelectButtonComponent.selectButtonCount++}`;
  @Input() options: Array<T> = [];
  @Input() optionLabelKey = 'label';
  @Input() optionValueKey = 'value';

  private valueSrc = new BehaviorSubject<T|null>(null);
  readonly value$ = this.valueSrc.asObservable();

  private isDisabledSrc = new BehaviorSubject<boolean>(false);
  readonly isDisabled$ = this.isDisabledSrc.asObservable();

  constructor() {}

  writeValue(value: T|null) {
    this.valueSrc.next(value);
  }

  onChange: (value: T) => void = () => {};
  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  onTouched = () => {};
  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  setDisabledState(disabled: boolean) {
    this.isDisabledSrc.next(disabled);
  }

  onClickOption(option: T): void {
    if (this.valueSrc.value[this.optionValueKey] !== option[this.optionValueKey]) {
      this.valueSrc.next(option);
      this.onChange(option);
    }
  }

  isSelectedOption$(option: T): Observable<boolean> {
    return this.value$.pipe(
      map(value => value !== null && value[this.optionValueKey] === option[this.optionValueKey])
    );
  }
}
