import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { HttpClient, HttpContext, HttpHeaders } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import {ConfigService} from '../utils/config.service';
import {ApiToken} from '../http/api-responses/api-token.model';
import { TokenStateService } from './token-state.service';
import {ApiListDrivingSchoolsService} from '../http/api-services/api-list-driving-schools.service';
import { NO_TOKEN_INTERCEPTOR } from './token.interceptor';

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnDestroy {

  readonly isLoggedIn$ = this.tokenStateService.isLoggedIn$;
  readonly currentDrivingSchoolId$ = this.tokenStateService.currentDrivingSchoolId$;

  private changeDrivingSchoolSub: Subscription;

  constructor(
    private config: ConfigService,
    private http: HttpClient,
    private tokenStateService: TokenStateService,
    private listDrivingSchoolService: ApiListDrivingSchoolsService
  ) {}

  ngOnDestroy(): void {
    this.changeDrivingSchoolSub?.unsubscribe();
  }

  switchToDrivingSchool$(drivingSchoolId: number): Observable<void> {
    let headers: HttpHeaders = new HttpHeaders();
    const token = this.tokenStateService.token;
    headers = headers.set('Authorization', `Bearer ${ token }`);

    return this.http.post<ApiToken>(
      this.config.apiUrl + `/driving-trainer/driving-schools/${drivingSchoolId}/tokens`,
      {},
      { headers }
    ).pipe(map((response) => {
      this.tokenStateService.updateToken(response.token);
      this.reloadApp();
    }));
  }

  changePassword$(password: string, token: string): Observable<void> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('Authorization', `Bearer ${token}`);
    return this.http.put<void>(
      this.config.apiUrl + '/driving-trainer/password',
      { password },
      { headers }
    );
  }

  signIn$(email: string, password: string): Observable<void> {
    return this.http.post<ApiToken>(this.config.apiUrl + '/driving-trainer/tokens', { email, password }).pipe(
      map(response => this.tokenStateService.updateToken(response.token))
    );
  }

  logout(): void {
    this.listDrivingSchoolService.clearDrivingSchools();
    this.tokenStateService.logout();
  }

  resetPassword$(email: string): Observable<void> {
    return this.http.post<void>(
      this.config.apiUrl + '/driving-trainer/reset-password',
      { email, changePasswordUrl: `${this.config.baseUrl}/mon-compte/changer-mot-de-passe` }
    );
  }

  changeCurrentDrivingSchool(id: number): void {
    this.changeDrivingSchoolSub = this.switchToDrivingSchool$(id).subscribe({
      next: () => this.reloadApp()
    });
  }

  private reloadApp(): void {
    window.location.assign('/planning');
  }

  confirmAccount$(token: string, trainerId: number): Observable<void> {
    let headers: HttpHeaders = new HttpHeaders();
    if (!token) {
      token = this.tokenStateService.token;
    }
    headers = headers.set('Authorization', `Bearer ${token}`);

    const context = new HttpContext();
    context.set(NO_TOKEN_INTERCEPTOR, true);

    return this.http.post<void>(
      this.config.apiUrl + '/driving-trainer/confirmation',
      { trainerId },
      { headers, context },
    );
  }
}
