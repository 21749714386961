<div class="free-email-input">
  <w-free-text-input
    #textInput
    [formControl]="inputCtrl"
    [helperText]="helperText"
    [htmlId]="htmlId"
    [inputName]="inputName"
    inputType="email"
    [invalidText]="invalidText"
    [isAutocomplete]="isAutocomplete"
    [isInvalid]="isInvalid"
    [label]="label"
    [maxLength]="maxLength"
    [placeholder]="placeholder"
    [size]="size"
    [leadingIcon]="iconEmail"
    [trailingIcon]="trailingIcon"
    (touched)="onInputTouched()"
    (focusInput)="onInputFocus()"
  ></w-free-text-input>
</div>
