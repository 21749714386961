<div class="free-period-input">
  <label class="free-form-label" for="{{ htmlId }}" *ngIf="label !== ''">{{ label }}</label>

  <div class="free-period-inputs">
    <div class="free-period-input-previous" *ngIf="isWide">
      <w-free-button
        [buttonIcon]="iconPrevious"
        buttonType="tertiary"
        [isDisabled]="!(hasPrevious$ | async)"
        (clickButton)="onClickPrevious()"
      ></w-free-button>
    </div>
    <div class="free-period-input-day" [ngClass]="{ 'free-period-input-highlighted': (navigationType$ | async) === 'day' }">
      <w-free-dropdown
        [disableChevron]="true"
        fieldType="select"
        [formControl]="dayCtrl"
        htmlId="{{ htmlId }}Day"
        invalidText=""
        [isInvalid]="isInvalid"
        [items]="days$ | async"
        label=""
        placeholder="Jour"
        [size]="size"
        unselect="off"
        (touched)="onInputTouched()"
      ></w-free-dropdown>
      <div class="free-period-input-shortcut">
        <w-free-button
          buttonType="tertiary"
          label="Aujourd'hui"
          [sideArea]="false"
          size="small"
          (clickButton)="onClickToday()"
        ></w-free-button>
      </div>
    </div>
    <div class="free-period-input-month" [ngClass]="{ 'free-period-input-highlighted': (navigationType$ | async) === 'month' }">
      <w-free-dropdown
        [disableChevron]="true"
        fieldType="select"
        [formControl]="monthCtrl"
        htmlId="{{ htmlId }}Month"
        invalidText=""
        [isInvalid]="isInvalid"
        [items]="months$ | async"
        label=""
        placeholder="Mois"
        [size]="size"
        unselect="off"
        (touched)="onInputTouched()"
      ></w-free-dropdown>
      <div class="free-period-input-shortcut">
        <w-free-button
          buttonType="tertiary"
          label="Ce mois-ci"
          [sideArea]="false"
          size="small"
          (clickButton)="onClickThisMonth()"
        ></w-free-button>
      </div>
    </div>
    <div class="free-period-input-year" [ngClass]="{ 'free-period-input-highlighted': (navigationType$ | async) === 'year' }">
      <w-free-dropdown
        [disableChevron]="true"
        fieldType="select"
        [formControl]="yearCtrl"
        htmlId="{{ htmlId }}Year"
        invalidText=""
        [isInvalid]="isInvalid"
        [items]="years$ | async"
        label=""
        placeholder="Année"
        [size]="size"
        unselect="off"
        (touched)="onInputTouched()"
      ></w-free-dropdown>
      <div class="free-period-input-shortcut">
        <w-free-button
          buttonType="tertiary"
          label="Cette année"
          [sideArea]="false"
          size="small"
          (clickButton)="onClickThisYear()"
        ></w-free-button>
      </div>
    </div>
    <div class="free-period-input-next" *ngIf="isWide">
      <w-free-button
        [buttonIcon]="iconNext"
        buttonType="tertiary"
        [isDisabled]="!(hasNext$ | async)"
        (clickButton)="onClickNext()"
      ></w-free-button>
    </div>
  </div>

  <div class="free-form-helper-text" *ngIf="helperText !== ''">{{ helperText }}</div>

  <div class="free-form-invalid-text" *ngIf="isInvalid">{{ invalidText }}</div>
</div>
