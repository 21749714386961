export enum DrivingLessonTypeEnum {

  RDV_ADMINISTRATIVE = 1,
  EVALUATION = 2,
  TRAINING_LESSON = 3,
  EDUCATIONAL = 4,
  DRIVING_TEST = 5,
  PRIOR_MEETING = 6,
  ASSESSMENT = 7,
  CODE = 8,
  SHARED = 9,
  TRACK = 10,
  SIMULATOR = 11,
  TRAINING_COURSE = 12,
  MOCK_EXAM = 13,
  CHECKS = 14,
  LISTENING = 15,
  THEORY = 16,
  WITHOUT_TRAFFIC = 17,
  WITH_TRAFFIC = 18,
  TRAINING = 19,

}
