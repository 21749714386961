import {Component, OnInit, OnDestroy} from '@angular/core';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { AuthService } from '../../auth/auth.service';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import {ApiUserService} from '../../http/api-services/api-user.service';

@Component({
  selector: 'w-navigation-bar',
  templateUrl: './navigation-bar.component.html',
})
export class NavigationBarComponent implements OnInit, OnDestroy  {

  iconClose = faTimes;
  iconMenu = faBars;

  isLoggedIn = false;
  isDisplayedMenu = false;

  // TODO TMP: CUSTOM COLORS
  styles: string = null;
  private userSub: Subscription;

  private authSub: Subscription;
  private navSub: Subscription;

  constructor(
    private auth: AuthService,
    private router: Router,

    // TODO TMP: CUSTOM COLORS
    private sanitizer: DomSanitizer,
    private userRepo: ApiUserService
  ) { }

  ngOnInit(): void {
    this.observeUser();

    // Responsive menu
    this.navSub = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => this.closeMenu());
  }

  ngOnDestroy(): void {
    this.userSub?.unsubscribe();
    this.authSub?.unsubscribe();
    this.navSub?.unsubscribe();
  }

  closeMenu(): void {
    this.isDisplayedMenu = false;
  }

  toggleMenu(event: any): void {
    event.stopPropagation();
    this.isDisplayedMenu = !this.isDisplayedMenu;
  }

  onClickCloseMenu(): void {
    this.closeMenu();
  }

  // TODO TMP: CUSTOM COLORS
  getStyles() {
    return this.sanitizer.bypassSecurityTrustHtml(this.styles);
  }

  private observeUser(): void {
    this.authSub = this.auth.isLoggedIn$.subscribe(isLoggedIn => this.isLoggedIn = isLoggedIn);

    // TODO TMP: CUSTOM COLORS
    this.userSub = this.userRepo.current$.subscribe(user => {
      this.styles = null;
      if (!!user?.customColorPrimary) {
        this.styles = `
            <style>
              #navigationBar { background-color: ${user.customColorPrimary} }
              #navigationBar .nav-link.active { background-color: ${user.customColorSecondary} }
            </style>
          `;
      } else {
        this.styles = null;
      }
    });
  }
}
