export class DropdownOption {

    label: string;
    value: any;


    constructor(value: any, label: string) {
        this.value = value;
        this.label = label;
    }
}
