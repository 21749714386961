import {Injectable, InjectionToken, OnDestroy} from '@angular/core';
import { BehaviorSubject, Observable, fromEvent, Subscription } from 'rxjs';

export const WINDOW = new InjectionToken<Window>('window');

@Injectable({
  providedIn: 'root'
})
export class WindowService implements OnDestroy {

  private resizeSrc: BehaviorSubject<[number, number]>;
  private scrollYSrc: BehaviorSubject<number>;

  private resizeSub: Subscription;
  private scrollSub: Subscription;

  constructor() {
    this.resizeSrc  = new BehaviorSubject<[number, number]>(this.windowSize);
    this.scrollYSrc = new BehaviorSubject<number>(this.windowScrollY);

    this.resizeSub = fromEvent(window, 'resize').subscribe(
      () => this.resizeSrc.next(this.windowSize)
    );

    this.scrollSub = fromEvent(window, 'scroll').subscribe(
      () => this.scrollYSrc.next(this.windowScrollY)
    );
  }

  ngOnDestroy(): void {
    this.resizeSub.unsubscribe();
    this.scrollSub.unsubscribe();
  }

  get resize$(): Observable<[number, number]> {
    return this.resizeSrc.asObservable();
  }

  get scrollY$(): Observable<number> {
    return this.scrollYSrc.asObservable();
  }

  get windowHeight(): number {
    return window.innerHeight;
  }

  get windowScrollY(): number {
    return window.scrollY;
  }

  get windowSize(): [number, number] {
    const height: number = Math.max(
      document.body.scrollHeight, document.documentElement.scrollHeight,
      document.body.offsetHeight, document.documentElement.offsetHeight,
      document.body.clientHeight, document.documentElement.clientHeight
    );
    return [document.documentElement.clientWidth, height];
  }
}
