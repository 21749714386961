import {Component, Input, OnInit} from '@angular/core';
import { DrivingLicenceType } from '../../../../core/models/driving-licence-type.model';

@Component({
  selector: 'w-train-driving-licence-type-badge',
  templateUrl: './train-driving-licence-type-badge.component.html'
})
export class TrainDrivingLicenceTypeBadgeComponent implements OnInit {

  @Input() drivingLicenceType: DrivingLicenceType;

  constructor() { }

  ngOnInit(): void {
  }
}
