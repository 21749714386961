<div class="train-driving-licence-type-select">
  <label class="free-form-label" for="{{ htmlId }}" *ngIf="label !== ''">{{ label }}</label>

  <div class="train-driving-licence-type-select-box" wClickOutside (clickOutside)="onClickOutsideBox()">
    <button [ngClass]="[
        'train-driving-licence-type-select-field',
        'train-driving-licence-type-select-field-' + altitude,
        'train-driving-licence-type-select-field-' + fieldType
      ]"
      [disabled]="isDisabled$ | async"
      id="{{ htmlId }}"
      type="button"
      (click)="onClickField()"
    >
      <span class="train-driving-licence-type-select-box-label">
        {{ numItemsSelected$ | async | i18nPlural: boxLabelPlural }}
      </span>
      <span class="train-driving-licence-type-select-menu-icon free-icon">
        <fa-icon [icon]="(isCollapsed$ | async) ? iconFlatten : iconCollapse"></fa-icon>
      </span>
    </button>

    <div
      class="train-driving-licence-type-select-list train-driving-licence-type-select-list-{{ directionX }}"
      *ngIf="isCollapsed$ | async"
    >
      <div class="train-driving-licence-type-select-group" *ngFor="let group of itemsGroups$ | async | keyvalue">
        <span
          *ngFor="let item of group.value"
          class="train-driving-licence-type-select-item {{ item.value | drivingLicenceTypeClass }}"
          [ngClass]="{ 'train-driving-licence-type-select-item-selected': item.isSelected }"
          (click)="onClickItem(item); $event.stopPropagation()"
        >
          {{ item.value.name }}
        </span>
      </div>
    </div>
  </div>

  <div class="free-form-helper-text" *ngIf="helperText !== ''">{{ helperText }}</div>

  <div class="free-form-invalid-text" *ngIf="isInvalid">{{ invalidText }}</div>
</div>
