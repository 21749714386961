import {ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormBuilder,
  NG_VALUE_ACCESSOR,
  UntypedFormControl
} from '@angular/forms';
import {BehaviorSubject, merge, Observable, of, Subscription} from 'rxjs';
import {FreePeriod} from "./free-period.model";
import {DatePipe, TitleCasePipe} from "@angular/common";
import {debounceTime, filter, map, pairwise, tap} from "rxjs/operators";
import {faChevronLeft, faChevronRight} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'w-free-period-input',
  templateUrl: './free-period-input.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: FreePeriodInputComponent
    }
  ]
})
export class FreePeriodInputComponent implements OnInit, OnDestroy, ControlValueAccessor {

  static periodInputCount = 0;

  @Input() helperText = '';
  @Input() htmlId = `freePeriodInput${FreePeriodInputComponent.periodInputCount++}`;
  @Input() invalidText = 'Invalid field value';
  @Input() isInvalid = false;
  @Input() isWide = true;
  @Input() label = '';
  @Input() numNextYears = 1;
  @Input() numPreviousYears = 10;
  @Input() size: 'small' | 'medium' | 'large' = 'medium';

  iconPrevious = faChevronLeft;
  iconNext = faChevronRight;

  dayCtrl: UntypedFormControl;
  monthCtrl: UntypedFormControl;
  yearCtrl: UntypedFormControl;

  private daysSrc = new BehaviorSubject<{ value: number, label: string }[]>([]);
  readonly days$ = this.daysSrc.asObservable();
  months$: Observable<{ value: number, label: string }[]>;
  years$: Observable<{ value: number, label: string }[]>;

  private hasPreviousSrc = new BehaviorSubject<boolean>(false);
  private hasNextSrc = new BehaviorSubject<boolean>(false);
  readonly hasPrevious$ = this.hasPreviousSrc.asObservable();
  readonly hasNext$ = this.hasNextSrc.asObservable();

  private navigationTypeSrc = new BehaviorSubject<'day'|'month'|'year'|null>(null);
  readonly navigationType$ = this.navigationTypeSrc.asObservable();

  private daysSub: Subscription;
  private navigationSub: Subscription;
  private valueChangesSub: Subscription;

  constructor(
    private fb: UntypedFormBuilder,
    private datePipe: DatePipe,
    private titleCasePipe: TitleCasePipe
  ) {}

  ngOnInit(): void {
    this.initControls();
  }

  ngOnDestroy(): void {
    this.daysSub.unsubscribe();
    this.navigationSub.unsubscribe();
    this.valueChangesSub.unsubscribe();
  }

  writeValue(value: FreePeriod|null): void {
    if (value !== null) {
      this.setValue(value);
    } else {
      this.dayCtrl.setValue(null);
      this.monthCtrl.setValue(null);
      this.yearCtrl.setValue(null);
    }
  }

  onChange: (value: FreePeriod|null) => void = () => {};
  registerOnChange(onChange: any) {
    this.onChange = onChange;

    this.observeInputs();
  }

  onTouched = () => {};
  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  setDisabledState(disabled: boolean) {
    if (disabled) {
      this.dayCtrl.disable({ emitEvent: false });
      this.monthCtrl.disable({ emitEvent: false });
      this.yearCtrl.disable({ emitEvent: false });
    } else {
      this.dayCtrl.enable({ emitEvent: false });
      this.monthCtrl.enable({ emitEvent: false });
      this.yearCtrl.enable({ emitEvent: false });
    }
  }

  onInputTouched(): void {
    this.onTouched();
  }

  onClickPrevious(): void {
    this.setValue(this.buildValue().previous());
  }

  onClickNext(): void {
    this.setValue(this.buildValue().next());
  }

  onClickToday(): void {
    this.setValue(FreePeriod.createToday());
  }

  onClickThisMonth(): void {
    this.setValue(FreePeriod.createThisMonth());
  }

  onClickThisYear(): void {
    this.setValue(FreePeriod.createThisYear());
  }

  private initControls(): void {
    this.dayCtrl = this.fb.control(null);
    this.monthCtrl = this.fb.control(null);
    this.yearCtrl = this.fb.control(null);

    this.initDays();
    this.initMonths();
    this.initYears();

    this.initNavigation();
  }

  private observeInputs(): void {
    this.valueChangesSub = merge(of(null), this.dayCtrl.valueChanges, this.monthCtrl.valueChanges, this.yearCtrl.valueChanges).pipe(
      debounceTime(50),
      map(_ => this.buildValue()),
      pairwise(),
      filter(values => values[0] !== values[1]),
      map(values => values[1]),
    ).subscribe(value => this.onChange(value));
  }

  private buildValue(): FreePeriod|null {
    let value: FreePeriod|null = null;
    if (this.dayCtrl.value !== null) {
      value = this.buildValueDay();
    } else if (this.monthCtrl.value !== null && this.yearCtrl.value !== null) {
      value = this.buildValueMonth();
    } else if (this.yearCtrl.value !== null) {
      value = this.buildValueYear();
    }
    return value;
  }

  private buildValueDay(): FreePeriod {
    return FreePeriod.createDay(Number(this.dayCtrl.value), Number(this.monthCtrl.value), Number(this.yearCtrl.value));
  }

  private buildValueMonth(): FreePeriod {
    return FreePeriod.createMonth(Number(this.monthCtrl.value), Number(this.yearCtrl.value));
  }

  private buildValueYear(): FreePeriod {
    return FreePeriod.createYear(Number(this.yearCtrl.value));
  }

  private initDays(): void {
    this.daysSub = merge(this.monthCtrl.valueChanges, this.yearCtrl.valueChanges).pipe(
      map(_ => {
        const month = this.monthCtrl.value;
        const year = this.yearCtrl.value;
        const days: { value: number, label: string }[] = [];
        if (month !== null && year !== null) {
          days.push({ value: null, label: '' });
          const numDays = new Date(year, month, 0).getDate();
          for (let iDay = 1; iDay <= numDays; iDay++) {
            days.push({ value: iDay, label: '' + iDay })
          }
        }
        return days;
      }),
      tap(days => {
        if (!days.some(day => day.value === this.dayCtrl.value)) {
          this.dayCtrl.setValue(null, { emitEvent: false });
        }
      })
    ).subscribe(days => this.daysSrc.next(days));
  }

  private initMonths(): void {
    const months: { value: number, label: string }[] = [{ value: null, label: '' }];
    const monthDay = new Date();
    monthDay.setHours(0, 0, 0, 0);
    for (let iMonth = 1; iMonth <= 12; iMonth++) {
      monthDay.setFullYear(monthDay.getFullYear(), iMonth - 1, 1);
      months.push({ value: iMonth, label: this.titleCasePipe.transform(this.datePipe.transform(monthDay, 'MMM')) })
    }
    this.months$ = of(months);
  }

  private initYears(): void {
    const years: { value: number, label: string }[] = [{ value: null, label: '' }];
    const today = new Date();
    const thisYear = today.getFullYear();
    for (let iYear = -this.numPreviousYears; iYear <= this.numNextYears; iYear++) {
      years.push({ value: thisYear + iYear, label: '' + (thisYear + iYear) })
    }
    this.years$ = of(years);
  }

  private setValue(value: FreePeriod): void {
    if (value.isDay) {
      this.setValueDay(value.valueDate);
    } else if (value.isMonth) {
      this.setValueMonth(value.valueDate);
    } else {
      this.setValueYear(value.valueDate);
    }
  }

  private setValueDay(day: Date): void {
    this.monthCtrl.setValue(day.getMonth() + 1);
    this.yearCtrl.setValue(day.getFullYear());
    this.dayCtrl.setValue(day.getDate());
  }

  private setValueMonth(monthFirstDay: Date): void {
    this.dayCtrl.setValue(null);
    this.monthCtrl.setValue(monthFirstDay.getMonth() + 1);
    this.yearCtrl.setValue(monthFirstDay.getFullYear());
  }

  private setValueYear(yearFirstDay: Date): void {
    this.dayCtrl.setValue(null);
    this.monthCtrl.setValue(null);
    this.yearCtrl.setValue(yearFirstDay.getFullYear());
  }

  private initNavigation(): void {
    this.navigationSub = merge(this.dayCtrl.valueChanges, this.monthCtrl.valueChanges, this.yearCtrl.valueChanges)
      .pipe(debounceTime(50))
      .subscribe(_ => {
        const value = this.buildValue();
        if (value === null) {
          this.navigationTypeSrc.next(null);
          this.hasPreviousSrc.next(false);
          this.hasNextSrc.next(false);
        } else {
          if (value.isDay) {
            this.navigationTypeSrc.next('day');
          } else if (value.isMonth) {
            this.navigationTypeSrc.next('month');
          } else {
            this.navigationTypeSrc.next('year');
          }
          const thisYear = (new Date()).getFullYear();
          const minYear = thisYear - this.numPreviousYears;
          const maxYear = thisYear + this.numNextYears;
          this.hasPreviousSrc.next(value.previous().valueDate.getFullYear() >= minYear);
          this.hasNextSrc.next(value.next().valueDate.getFullYear() <= maxYear);
        }
      });
  }
}
