<header>
  <w-navigation-bar></w-navigation-bar>
</header>

<main>
  <router-outlet></router-outlet>

  <w-snackbar></w-snackbar>
</main>

<footer>
  <div class="footer-content">
    <div class="footer-copyright">Copyright © 2023 Klaxo, tous droits réservés.</div>
  </div>
</footer>
