import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from "@angular/core";
import {FreeTypeaheadSelectInputItem} from "./free-typeahead-select-input-item.model";
import {ControlValueAccessor, UntypedFormBuilder, UntypedFormControl, NG_VALUE_ACCESSOR} from "@angular/forms";
import {IconDefinition} from "@fortawesome/fontawesome-common-types";
import {BehaviorSubject, Subscription} from "rxjs";
import {distinctUntilChanged, filter, map, tap} from "rxjs/operators";

@Component({
  selector: 'w-free-typeahead-select-input',
  templateUrl: './free-typeahead-select-input.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: FreeTypeaheadSelectInputComponent
    }
  ]
})
export class FreeTypeaheadSelectInputComponent<T extends FreeTypeaheadSelectInputItem> implements OnInit, OnDestroy, ControlValueAccessor {

  static typeaheadSelectInputInputCount = 0;

  @Input() helperText = '';
  @Input() htmlId = `freeTypeaheadSelectInput${FreeTypeaheadSelectInputComponent.typeaheadSelectInputInputCount++}`;
  @Input() inputName = 'freeTypeaheadSelectInputName';
  @Input() invalidText = 'Invalid field value';
  @Input() isInvalid = false;
  @Input() items: T[] = [];
  @Input() label = '';
  @Input() leadingIcon: IconDefinition = null;
  @Input() maxLength: number = null;
  @Input() placeholder = '';
  @Input() size: 'small' | 'medium' | 'large' = 'medium';
  @Input() trailingIcon: IconDefinition = null;
  @Input() trailingText: string = null;

  @Output() focusInput = new EventEmitter<void>();
  @Output() suggestionSelected = new EventEmitter<T>();
  @Output() touched = new EventEmitter<void>();

  @ViewChild('textInput') textInput: ElementRef;

  inputCtrl: UntypedFormControl;

  private suggestionsSrc = new BehaviorSubject<T[]>([]);
  readonly suggestions$ = this.suggestionsSrc.asObservable();
  readonly hasSuggestions$ = this.suggestions$.pipe(map(suggestions => suggestions.length > 0));

  private valueChangesSub: Subscription;

  constructor(
    private fb: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.initControl();
  }

  ngOnDestroy(): void {
    this.valueChangesSub.unsubscribe();
  }

  writeValue(value: string) {
    this.inputCtrl.setValue(value ?? '', { emitEvent: false });
  }

  onChange: (value: string) => void = () => {};
  registerOnChange(onChange: any) {
    this.onChange = onChange;
    this.observeInput();
  }

  onTouched = () => {};
  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  setDisabledState(disabled: boolean) {
    if (disabled) {
      this.inputCtrl.disable({ emitEvent: false });
    } else {
      this.inputCtrl.enable({ emitEvent: false });
    }
  }

  onInputTouched(): void {
    this.onTouched();
  }

  onClickOutsideSuggestions(): void {
    this.clearSuggestions();
  }

  onClickSuggestion(suggestion: T): void {
    this.selectSuggestion(suggestion);
    this.clearSuggestions();
  }

  onInputFocus(): void {
    this.focusInput.emit();
    this.suggest();
  }

  private initControl(): void {
    this.inputCtrl = this.fb.control('');
  }

  private observeInput(): void {
    this.valueChangesSub = this.inputCtrl.valueChanges.pipe(
      map(value => value.trim()),
      distinctUntilChanged(),
      tap(value => this.onChange(value)),
    ).subscribe(_ => this.suggest());
  }

  private suggest(): void {
    const filterValue = FreeTypeaheadSelectInputComponent.normalizeValue(this.inputCtrl.value);
    this.suggestionsSrc.next(this.items.filter(item => {
      const itemValue = FreeTypeaheadSelectInputComponent.normalizeValue(item.typeaheadLabel);
      return itemValue.startsWith(filterValue);
    }));
  }

  private clearSuggestions(): void {
    this.suggestionsSrc.next([]);
  }

  private static normalizeValue(value: string): string {
    return value.trim().toLowerCase();
  }

  private selectSuggestion(suggestion: T): void {
    this.clearSuggestions();
    this.inputCtrl.setValue(suggestion.typeaheadLabel);
    this.onChange(suggestion.typeaheadLabel);
    this.suggestionSelected.emit(suggestion);
  }
}
