import {Injectable} from '@angular/core';
import {ApiDrivingSkill} from '../api-responses/api-driving-skill.model';
import {DrivingSkill} from '../../models/driving-skill.model';

@Injectable({
  providedIn: 'root'
})
export class DrivingSkillAssemblerService {

  toModel(apiDrivingSkill: ApiDrivingSkill): DrivingSkill {
    const drivingSkill = new DrivingSkill();
    drivingSkill.id = apiDrivingSkill.id;
    drivingSkill.skillNumber = apiDrivingSkill.skillNumber;
    drivingSkill.name = apiDrivingSkill.name;
    drivingSkill.subSkills = apiDrivingSkill.subSkills.map(apiSubSkill => this.toModel(apiSubSkill));

    return drivingSkill;
  }
}
