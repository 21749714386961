<div class="free-search-input">
  <w-free-text-input
    #textInput
    [altitude]="altitude"
    [formControl]="inputCtrl"
    [helperText]="helperText"
    [htmlId]="htmlId"
    [inputName]="inputName"
    [invalidText]="invalidText"
    [isAutocomplete]="isAutocomplete"
    [isInvalid]="isInvalid"
    [label]="label"
    [placeholder]="placeholder"
    [radius]="radius"
    [size]="size"
    [trailingIcon]="iconSearch"
    (touched)="onInputTouched()"
    (focusInput)="onInputFocus()"
  ></w-free-text-input>
</div>
