import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutComponent } from './core/layout/layout.component';
import {AuthGuard} from './core/auth/auth.guard';
import {NotAuthedGuard} from './core/auth/not-authed-guard.service';
import { AuthConfirmationComponent } from './auth/pages/auth-confirmation/auth-confirmation.component';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: 'eleves',
        canLoad: [AuthGuard],
        loadChildren: () => import('./student/students.module').then(m => m.StudentsModule),
      },
      {
        path: 'planning',
        canLoad: [AuthGuard],
        loadChildren: () => import('./planning/planning.module').then(m => m.PlanningModule),
      },
      {
        path: 'mon-compte/confirmation',
        loadComponent: () => import('./auth/pages/auth-confirmation/auth-confirmation.component').then(m => m.AuthConfirmationComponent),
      },
      {
        path: 'mon-compte',
        canActivate: [NotAuthedGuard],
        loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
      },
      { path: '', redirectTo: '/planning', pathMatch: 'full' },
      { path: '**', redirectTo: '/planning' },
    ],
  },
  { path: '', redirectTo: '/planning', pathMatch: 'full' },
  { path: '**', redirectTo: '/planning' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
