import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from "@angular/core";
import {faChevronLeft, faChevronRight} from "@fortawesome/free-solid-svg-icons";
import {BehaviorSubject} from "rxjs";
import {map} from "rxjs/operators";

@Component({
  selector: 'w-free-pagination-numbered',
  templateUrl: './free-pagination-numbered.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FreePaginationNumberedComponent implements OnInit, OnChanges {

  @Input() page = 1;
  @Input() pageSize = 10;
  @Input() total = 0;

  @Output() pageChange = new EventEmitter<number>();

  iconNext = faChevronRight;
  iconPrevious = faChevronLeft;

  private paginationChangeSrc = new BehaviorSubject<null>(null);
  readonly paginationChange$ = this.paginationChangeSrc.asObservable();
  readonly hasPrevious$ = this.paginationChange$.pipe(map(() => this.hasPreviousPage));
  readonly hasNext$ = this.paginationChange$.pipe(map(() => this.hasNextPage));
  readonly pageNumbers$ = this.paginationChange$.pipe(map(() => this.navPageNumbers));

  constructor() {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.paginationChangeSrc.next(null);
  }

  onClickPrevious(): void {
    this.page--;
    this.pageChange.emit(this.page);
  }

  onClickNext(): void {
    this.page++;
    this.pageChange.emit(this.page);
  }

  onClickPage(page: number): void {
    this.page = page;
    this.pageChange.emit(page);
  }

  private get navPageNumbers(): (number|null)[] {
    const offset = 3;
    const numPages = 5;
    const maxPage = this.totalNumberOfPages;
    let pages: (number|null)[];
    if (maxPage > numPages) {
      if (this.page <= offset) {
        pages = [...[...Array(offset).keys()].map(page => page + 1), null, maxPage];
      } else if (this.page <= (maxPage - offset)) {
        pages = [1, null, this.page, null, maxPage];
      } else {
        pages = [1, null, ...[...Array(offset).keys()].map(page => page + maxPage - offset + 1)];
      }
    } else {
      pages = [...Array(maxPage).keys()].map(page => page + 1);
    }
    return pages;
  }

  private get totalNumberOfPages(): number {
    return Math.ceil(this.total / this.pageSize);
  }

  private get hasPreviousPage(): boolean {
    return this.page > 1;
  }

  private get hasNextPage(): boolean {
    return this.totalNumberOfPages > this.page;
  }
}
