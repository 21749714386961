import { Component, ElementRef, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import {ControlValueAccessor, UntypedFormBuilder, UntypedFormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Subscription} from 'rxjs';
import {IconDefinition} from '@fortawesome/fontawesome-common-types';
import { MASKED_INPUT_TOKEN } from '../../../directives/input-mask.directive';
import { MaskedInput } from '../../../models/masked-input.interface';

@Component({
  selector: 'w-free-text-input',
  templateUrl: './free-text-input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: FreeTextInputComponent
    },
    {
      provide: MASKED_INPUT_TOKEN,
      useExisting: forwardRef(() => FreeTextInputComponent)
    }
  ]
})
export class FreeTextInputComponent implements OnInit, OnDestroy, ControlValueAccessor, MaskedInput {

  static textInputCount = 0;

  @Input() altitude: 'flat' | 'high' = 'flat';
  @Input() helperText = '';
  @Input() htmlId = `freeTextInput${FreeTextInputComponent.textInputCount++}`;
  @Input() inputName = 'freeTextInputName';
  @Input() inputType: 'text' | 'email' | 'phone' | 'password' | 'tel' = 'text';
  @Input() invalidText = 'Invalid field value';
  @Input() isInvalid = false;
  @Input() isMultiline = false;
  @Input() isAutocomplete = true;
  @Input() label = '';
  @Input() leadingIcon: IconDefinition|null = null;
  @Input() maxLength: number|null = null;
  @Input() placeholder = '';
  @Input() radius: 'straight' | 'rounded' = 'straight';
  @Input() size: 'small' | 'medium' | 'large' = 'medium';
  @Input() trailingIcon: IconDefinition|null = null;
  @Input() trailingText: string|null = null;

  @Output() focusInput = new EventEmitter<void>();
  @Output() touched = new EventEmitter<void>();

  @ViewChild('textInputSingle') textInputSingle: ElementRef;
  @ViewChild('textInputMulti') textInputMulti: ElementRef;

  inputCtrl: UntypedFormControl;

  private valueChangesSub: Subscription;

  constructor(
    private fb: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.initControl();
  }

  ngOnDestroy(): void {
    this.valueChangesSub.unsubscribe();
  }

  writeValue(value: string) {
    this.inputCtrl.setValue(value ?? '', { emitEvent: false });
  }

  onChange: (value: string) => void = () => {};
  registerOnChange(onChange: any) {
    this.onChange = onChange;

    this.observeInput();
  }

  onTouched = () => {};
  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  setDisabledState(disabled: boolean) {
    if (disabled) {
      this.inputCtrl.disable({ emitEvent: false });
    } else {
      this.inputCtrl.enable({ emitEvent: false });
    }
  }

  onInputBlur(): void {
    this.onTouched();
    this.touched.emit();
  }

  onInputFocus(): void {
    this.focusInput.emit();
  }

  get inputElt(): any {
    return this.isMultiline ? this.textInputMulti : this.textInputSingle.nativeElement;
  }

  get hasLabel(): boolean {
    return this.label !== '';
  }

  get hasHelperText(): boolean {
    return this.helperText !== '';
  }

  get hasLeadingIcon(): boolean {
    return this.leadingIcon !== null;
  }

  get hasTrailingIcon(): boolean {
    return this.trailingIcon !== null;
  }

  get hasTrailingText(): boolean {
    return this.trailingText !== null;
  }

  public get value(): string {
    return this.inputCtrl.value;
  }

  public set value(value: string) {
    this.inputCtrl.setValue(value, { emitEvent: true });
  }

  private initControl(): void {
    this.inputCtrl = this.fb.control('');
  }

  private observeInput(): void {
    this.valueChangesSub = this.inputCtrl.valueChanges.subscribe(value => this.onChange(value));
  }
}
