import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'w-confirm-modal',
  templateUrl: './confirm-modal.component.html'
})
export class ConfirmModalComponent implements OnInit {

  @Input() cancelButtonText = 'Annuler';
  @Input() confirmButtonText = 'Confirmer';
  @Input() isDisabeld = false;

  @Output() cancel = new EventEmitter<void>();
  @Output() confirm = new EventEmitter<void>();

  constructor() { }

  ngOnInit(): void {
  }

  onClickCancel(): void {
    this.cancel.emit();
  }

  onClickConfirm(): void {
    this.confirm.emit();
  }
}
