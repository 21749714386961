<div class="free-confirm-action" wClickOutside (clickOutside)="onClickOutside()">
  <ng-content></ng-content>

  <div
    class="free-confirm-action-box free-confirm-action-box-{{ directionX }} free-confirm-action-box-{{ directionY }}"
    *ngIf="needConfirmAction"
  >
    <div class="free-confirm-action-text">
      {{ actionText }}
    </div>

    <div class="free-confirm-action-actions">
      <div class="free-confirm-action-cancel">
        <w-free-button
          [label]="cancelLabel"
          buttonType="secondary"
          size="small"
          [isDisabled]="isDisabled"
          (clickButton)="onClickCancel()"
        ></w-free-button>
      </div>
      <div class="free-confirm-action-confirm">
        <w-free-button
          [label]="confirmLabel"
          size="small"
          [isDisabled]="isDisabled"
          (clickButton)="onClickConfirm()"
        ></w-free-button>
      </div>
    </div>
  </div>
</div>
