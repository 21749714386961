import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {faCheck, faChevronDown, faTimesCircle} from '@fortawesome/free-solid-svg-icons';
import {Subscription} from 'rxjs';
import {ApiRefService} from '../../../core/http/api-services/api-ref.service';
import {DrivingLicenceTypeGroup} from '../../../core/models/driving-licence-type-group.model';

@Component({
  selector: 'w-select-driving-licence-type-groups-dropdown',
  templateUrl: './select-driving-licence-type-groups-dropdown.component.html',
})
export class SelectDrivingLicenceTypeGroupsDropdownComponent implements OnInit, OnDestroy {

  @Input()
  set values(groups: DrivingLicenceTypeGroup[]) {
    this.groupsSelected = [...groups];
  }
  @Input() isDisabled = false;

  @Output() selectGroups: EventEmitter<DrivingLicenceTypeGroup[]> = new EventEmitter<DrivingLicenceTypeGroup[]>();

  iconArrow = faChevronDown;
  iconSelected = faCheck;
  iconUnselect = faTimesCircle;

  isVisible = false;

  hoveredOption: DrivingLicenceTypeGroup = null;
  groups: DrivingLicenceTypeGroup[] = [];

  groupsSelected: DrivingLicenceTypeGroup[] = [];

  private groupsSub: Subscription;

  constructor(
    private refRepo: ApiRefService,
  ) {}

  ngOnInit(): void {
    this.loadRef();
  }

  ngOnDestroy(): void {
    this.groupsSub.unsubscribe();
  }

  toggle(): void {
    this.isVisible = !this.isVisible;
  }

  close(): void {
    this.isVisible = false;
  }

  isHovered(group: DrivingLicenceTypeGroup): boolean {
    if (this.hoveredOption !== null) {
      return group.id === this.hoveredOption.id;
    }
  }

  onHoverGroup(group: DrivingLicenceTypeGroup): void {
    this.hoveredOption = group;
  }

  onUnhoverGroup(): void {
    this.hoveredOption = null;
  }

  isSelected(group: DrivingLicenceTypeGroup): boolean {
    return this.groupsSelected.some(groupSelected => groupSelected.id === group.id);
  }

  selectItem(group: DrivingLicenceTypeGroup): void {
    this.groupsSelected.push(group);
    this.selectGroups.emit(this.groupsSelected);
  }

  unselectItem(group: DrivingLicenceTypeGroup): void {
    const index = this.groupsSelected.findIndex(groupSelected => groupSelected.id === group.id);

    this.groupsSelected.splice(index, 1);
    this.selectGroups.emit(this.groupsSelected);
  }

  onClickAll(): void {
    this.groupsSelected = [];
    this.selectGroups.emit(this.groupsSelected);
  }

  get displayGroups(): string {
    if (this.groupsSelected.length > 0) {
      return this.groupsSelected.map(group => group.name).join(', ');
    } else {
      return 'Tous les permis';
    }
  }

  private loadRef(): void {
    this.groupsSub = this.refRepo.drivingLicenceTypeGroups$.subscribe(groups => this.groups = groups);
  }
}
