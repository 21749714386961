import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpContextToken } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TokenStateService } from './token-state.service';

export const NO_TOKEN_INTERCEPTOR = new HttpContextToken(() => false);

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(
    private tokenState: TokenStateService,
  ) {}

  /**
   * Add the api token to all api requests.
   * @see HttpInterceptor
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.context.get(NO_TOKEN_INTERCEPTOR)) {
      return next.handle(request);
    }

    const token = this.tokenState.getToken();
    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    }
    return next.handle(request);
  }

}
