<div class="free-typeahead-select-input">
  <label class="free-form-label" for="{{ htmlId }}" *ngIf="label !== ''">{{ label }}</label>

  <div wClickOutside (clickOutside)="onClickOutsideSuggestions()">
    <w-free-text-input
      #textInput
      [formControl]="inputCtrl"
      [htmlId]="htmlId"
      [inputName]="inputName"
      invalidText=""
      [isAutocomplete]="false"
      [isInvalid]="isInvalid"
      [maxLength]="maxLength"
      [placeholder]="placeholder"
      [size]="size"
      [leadingIcon]="leadingIcon"
      [trailingIcon]="trailingIcon"
      (touched)="onInputTouched()"
      (focusInput)="onInputFocus()"
    ></w-free-text-input>
    <div *ngIf="hasSuggestions$ | async" class="free-typeahead-select-input-suggestions">
      <div
        *ngFor="let suggestion of suggestions$ | async"
        class="free-typeahead-select-input-suggestion-wrapper"
        [title]="suggestion.typeaheadLabel"
        (click)="onClickSuggestion(suggestion)"
      >
        <div class="free-typeahead-select-input-suggestion">
          <span class="free-typeahead-select-input-suggestion-label">{{ suggestion.typeaheadLabel }}</span>
        </div>
      </div>
    </div>
  </div>

  <div class="free-form-helper-text" *ngIf="helperText !== ''">{{ helperText }}</div>

  <div class="free-form-invalid-text" *ngIf="isInvalid && invalidText !== ''">{{ invalidText }}</div>
</div>
