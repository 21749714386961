export class PaginationRange {

  endIndex = 24;
  startIndex = 0;

  constructor(startIndex: number = 0, limit: number = 25) {
    this.startIndex = startIndex;
    this.endIndex = startIndex + limit - 1;
  }

  static createFromPageAndSize(page: number, pageSize: number): PaginationRange {
    const startIndex = (page - 1) * pageSize;
    return new PaginationRange(startIndex, pageSize);
  }
}
