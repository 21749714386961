<div
  class="free-radio-group free-radio-group-{{ direction }}"
  [ngClass]="{
    'free-radio-group-invalid': isInvalid
  }"
>
  <label class="free-form-label" *ngIf="hasLabel">
    {{ label }}
  </label>

  <div class="free-radio-group-list">
    <div class="free-radio" *ngFor="let item of items; let i = index">
      <label class="free-radio-label">
        <input
          type="radio"
          class="free-radio-input"
          [name]="inputName"
          [value]="item[itemValueKey]"
          [formControl]="inputCtrl"
          (blur)="onInputBlur()"
        >
        <span class="free-radio-appearance"></span>
        <span class="free-radio-label-text">{{ item[itemLabelKey] }}</span>
      </label>
    </div>
  </div>

  <div class="free-form-helper-text" *ngIf="hasHelperText">
    {{ helperText }}
  </div>

  <div class="free-form-invalid-text" *ngIf="isInvalid && invalidText !== ''">
    {{ invalidText }}
  </div>
</div>
