import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { DropdownOption } from './dropdown-option.model';
import { faCheck, faChevronDown, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'w-dropdown',
  templateUrl: './dropdown.component.html'
})
export class DropdownComponent implements OnInit {

  iconArrow = faChevronDown;
  iconSelected = faCheck;
  iconUnselect = faTimesCircle;

  _options: DropdownOption[] = [];


  hoveredOption: DropdownOption = null;
  selectedOption: DropdownOption = null;

  visible = false;


  @Input() menuSide = 'right';

  @Input() isDisplayedArrow = true;

  @Input() type = 'link';

  @Input()
  set options(values: [any, string][]) {
    this._options = [];
    values.forEach(option => {
      this._options.push(new DropdownOption(option[0], option[1]));
    });
  }

  @Input()
  set value(value: any) {
    const selection = this._options.find(option => option.value === value);
    if (!!selection) {
      this.selectedOption = selection;
    }
  }


  @Output() select = new EventEmitter<any>();

  @Output() unselect = new EventEmitter<any>();


  constructor() { }


  ngOnInit() { }


  close(): void {
    this.visible = false;
    this.unhoverItem();
  }

  hoverItem(option: DropdownOption): void {
    this.hoveredOption = option;
  }

  isHovered(option: DropdownOption): boolean {
    return this.hoveredOption && option.value === this.hoveredOption.value;
  }

  isSelected(option: DropdownOption): boolean {
    return this.type === 'select' && this.selectedOption && option.value === this.selectedOption.value;
  }

  selectItem(option: DropdownOption): void {
    this.selectedOption = option;
    this.select.emit(option.value);
    this.close();
  }

  toggle(): void {
    this.visible = !this.visible;
  }

  unhoverItem(option?: DropdownOption): void {
    this.hoveredOption = null;
  }

  unselectItem(option: DropdownOption): void {
    this.selectedOption = null;
    this.unselect.emit(option.value);
    this.close();
  }


  get selectedOptionLabel(): string {
    return this.selectedOption ? this.selectedOption.label : '';
  }

}
