<div class="free-pagination-numbered">
  <div class="free-pagination-numbered-previous">
    <w-free-button
      [buttonIcon]="iconPrevious"
      buttonType="tertiary"
      [isDisabled]="!(hasPrevious$ | async)"
      (clickButton)="onClickPrevious()"
    ></w-free-button>
  </div>
  <div class="free-pagination-numbered-pages">
    <div
      *ngFor="let pageNumber of pageNumbers$ | async"
      class="free-pagination-numbered-page"
      [ngClass]="{ 'free-pagination-numbered-page-active': pageNumber === page }"
    >
      <w-free-button
        *ngIf="pageNumber !== null"
        buttonType="secondary"
        [isDisabled]="pageNumber === page"
        label="{{ pageNumber }}"
        (clickButton)="onClickPage(pageNumber)"
      ></w-free-button>
      <span *ngIf="pageNumber === null" class="free-pagination-numbered-separator">...</span>
    </div>
  </div>
  <div class="free-pagination-numbered-next">
    <w-free-button
      [buttonIcon]="iconNext"
      buttonType="tertiary"
      [isDisabled]="!(hasNext$ | async)"
      (clickButton)="onClickNext()"
    ></w-free-button>
  </div>
</div>
