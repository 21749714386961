import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {ControlValueAccessor, UntypedFormBuilder, UntypedFormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Subscription} from 'rxjs';
import {IconDefinition} from '@fortawesome/fontawesome-common-types';

@Component({
  selector: 'w-free-number-input',
  templateUrl: './free-number-input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: FreeNumberInputComponent
    }
  ]
})
export class FreeNumberInputComponent implements OnInit, OnDestroy, ControlValueAccessor {

  static numberInputCount = 0;

  @Input() helperText = '';
  @Input() htmlId = `freeNumberInput${FreeNumberInputComponent.numberInputCount++}`;
  @Input() inputName = 'freeNumberInputName';
  @Input() invalidText = 'Invalid field value';
  @Input() isAutocomplete = true;
  @Input() isInvalid = false;
  @Input() label = '';
  @Input() leadingIcon: IconDefinition|null = null;
  @Input() max: number|null = null;
  @Input() min: number|null = null;
  @Input() placeholder = '';
  @Input() size: 'small' | 'medium' | 'large' = 'medium';
  @Input() step = 1;
  @Input() trailingIcon: IconDefinition|null = null;
  @Input() trailingText: string|null = null;

  @Output() focusInput = new EventEmitter<void>();
  @Output() touched = new EventEmitter<void>();

  @ViewChild('numberInput') numberInput: ElementRef;

  inputCtrl: UntypedFormControl;

  private valueChangesSub: Subscription;

  constructor(
    private fb: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.initControl();
  }

  ngOnDestroy(): void {
    this.valueChangesSub.unsubscribe();
  }

  writeValue(value: number) {
    this.inputCtrl.setValue(value, { emitEvent: false });
  }

  onChange: (value: number) => void = () => {};
  registerOnChange(onChange: any) {
    this.onChange = onChange;

    this.observeInput();
  }

  onTouched = () => {};
  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  setDisabledState(disabled: boolean) {
    if (disabled) {
      this.inputCtrl.disable();
    } else {
      this.inputCtrl.enable();
    }
  }

  onInputBlur(): void {
    this.onTouched();
    this.touched.emit();
  }

  onInputFocus(): void {
    this.focusInput.emit();
  }

  get inputElt(): any {
    return this.numberInput.nativeElement;
  }

  get hasLabel(): boolean {
    return this.label !== '';
  }

  get hasHelperText(): boolean {
    return this.helperText !== '';
  }

  get hasLeadingIcon(): boolean {
    return this.leadingIcon !== null;
  }

  get hasTrailingIcon(): boolean {
    return this.trailingIcon !== null;
  }

  get hasTrailingText(): boolean {
    return this.trailingText !== null;
  }

  private initControl(): void {
    this.inputCtrl = this.fb.control(null);
  }

  private observeInput(): void {
    this.valueChangesSub = this.inputCtrl.valueChanges.subscribe(value => this.onChange(value));
  }
}
